import compact from 'lodash/compact'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import reject from 'lodash/reject'

export const getAttributeGroups = (groups) =>
  compact(
    map(groups, (group) => {
      const attributes = filter(group, (f) => isObject(f._attribute))
      if (isEmpty(attributes)) {
        return null
      }
      const isAttributes = map(
        reject(attributes, { isNot: true }),
        '_attribute.id'
      )
      const isNotAttributes = map(
        filter(attributes, { isNot: true }),
        '_attribute.id'
      )

      return {
        isAttributes: isEmpty(isAttributes) ? undefined : isAttributes,
        isNotAttributes: isEmpty(isNotAttributes) ? undefined : isNotAttributes,
      }
    })
  )
