/* eslint-disable no-param-reassign */
import throttle from 'lodash/throttle'
import { v4 as uuid } from 'uuid'
import { fokoud } from '../../../../api'
import {
  POST_CREATED,
  POST_DELETED,
  POST_UPDATED,
  USER_TYPING,
  dispatchEvent,
  INBOX_ITEM_UPDATED,
  BROADCAST_UPDATED,
  BROADCAST_CREATED,
  TASK_DATA_EXPORT_JOB,
  TASK_DATA_EXPORT_JOB_COMPLETED,
  TASK_DATA_EXPORT_JOB_FAILED,
} from '../../../../events'
import { IS_WEBSOCKET_UPDATE, Post } from '../../models/post'
import { getCommunityId, Session } from '../../models/session'
import processStartWS from './start'
import { Channels } from '../../models/channel'
import { ChannelsService } from '../channel'
import { Messages, Message } from '../../models/message'
import refresh from './refresh'
import { PushNotification } from '../../../../utils/push-notification'
import { Inbox } from '../../../inbox/models/inbox'
import { Task } from '../../../task/models/task'
import { Broadcast } from '../../../broadcasts/models/broadcast'
import { BroadcastService } from '../../../broadcasts/api/broadcast'
import { CommunitiesService } from '../community'
import { INSTALLATION_ID } from '../../../../utils/local-storage'

const PING_INTERVAL = 3 * 1000 // 3 seconds
const MAX_RETRY_WAIT_SECONDS = 10 * 60 // 10 minutes
const THROTTLE_TIMEOUT = 60000
let retry = 1

const fokoudWebSocket = {
  webSocket: undefined,
  isReconnect: false,
  connectTimeout: undefined,
  pingInterval: undefined,
}

const throttleTaskBadges = throttle(
  () => Task.updateActivityCounts(),
  THROTTLE_TIMEOUT
)

const throttleTasksBadges = throttle(
  () => CommunitiesService.badges(false, false, false, true),
  THROTTLE_TIMEOUT
)

const connect = (websocketUrl, ws) => {
  ws.webSocket = new WebSocket(websocketUrl)

  ws.webSocket.onopen = () => {
    retry = 1
    if (ws.isReconnect) {
      refresh()
    }
    ws.isReconnect = true
    // commence the game of ping pong
    ws.pingInterval = setInterval(() => {
      ws.webSocket.send(
        JSON.stringify({
          type: 'ping',
          id: 1,
        })
      )
    }, PING_INTERVAL)
  }

  ws.webSocket.onmessage = (e) => {
    const data = JSON.parse(e.data)
    const wsCommunityId = data?.data?.communityId
    if (wsCommunityId && wsCommunityId !== getCommunityId()) {
      return
    }
    if (data.type === 'post') {
      const post = data.data
      if (data.action === 'create') {
        dispatchEvent(POST_CREATED, post)
        if (Post.isMessagePost(post)) {
          Messages.handlePostCreated(post)
        } else {
          Channels.handlePostCreated(post)
        }
      } else if (data.action === 'update') {
        dispatchEvent(POST_UPDATED, data.data, { [IS_WEBSOCKET_UPDATE]: true })
      } else if (data.action === 'delete') {
        dispatchEvent(POST_DELETED, data.data)
      }
    } else if (data.type === 'channel') {
      const channel = data.data
      if (data.action === 'update') {
        if (channel.type === 'dm') {
          Message.onUpdated(channel)
        }
      }
    } else if (data.type === 'typing') {
      dispatchEvent(USER_TYPING, data.data)
    } else if (data.type === 'start') {
      processStartWS(data.data)
    } else if (data.type === 'push') {
      PushNotification.process(data.data)
    } else if (data.type === 'inbox') {
      const inboxItem = data.data
      dispatchEvent(INBOX_ITEM_UPDATED, inboxItem)
      if (inboxItem.hasBeenRead === false) {
        Inbox.setHasUnreadInboxItems(true)
      }
      if (inboxItem?.deeplink?.includes('/channels/')) {
        ChannelsService.get(true)
      }
    } else if (data.type === 'pull') {
      if (data.action === 'taskBadgesUpdate') {
        throttleTaskBadges()
      }
    } else if (data.type === 'broadcast') {
      const { broadcastId } = data.data
      BroadcastService.getById(broadcastId).then(({ data: broadcast }) => {
        if (data.action === 'updated' || data.action === 'audience_updated') {
          dispatchEvent(BROADCAST_UPDATED, broadcast)
        } else if (data.action === 'published') {
          Broadcast.handleBroadcastCreated(broadcast)
          dispatchEvent(BROADCAST_CREATED, broadcast)
        }
      })
    } else if (data.type === 'tasks') {
      if (data.action === 'badge_update') {
        throttleTasksBadges()
      } else if (
        data.action === TASK_DATA_EXPORT_JOB_COMPLETED ||
        data.action === TASK_DATA_EXPORT_JOB_FAILED
      ) {
        dispatchEvent(TASK_DATA_EXPORT_JOB, data.data, data.action)
      }
    } else if (data.type === 'logout') {
      if (data.action === 'user_all' || window.parent !== window) {
        // if all user sessions are logged out or if vanguard is running in Suite iframe then clear out tokens
        Session.onLogout(true)
      } else if (
        data.action === 'user_session' &&
        data.data?.installationId === localStorage.getItem(INSTALLATION_ID)
      ) {
        // if only one user session is logged out (and not running in Suite iframe), only clear out tokens if installations match
        Session.onLogout(true)
      }
    }
  }

  ws.webSocket.onclose = () => {
    clearInterval(ws.pingInterval)
    // try reconnect in a second
    ws.connectTimeout = setTimeout(() => {
      connect(websocketUrl, ws)
    }, retry * 1000)
    retry = Math.min(retry * 2, MAX_RETRY_WAIT_SECONDS)
  }

  ws.webSocket.onerror = () => {
    if (!ws.webSocket) {
      return
    }
    ws.webSocket.close()
  }
}

window.addEventListener('online', () => {
  if (!fokoudWebSocket.webSocket) {
    return
  }
  if (fokoudWebSocket.webSocket.readyState === WebSocket.CLOSED) {
    clearTimeout(fokoudWebSocket.connectTimeout)
    connect(fokoudWebSocket.webSocket.url, fokoudWebSocket)
  }
})

export const Socket = {
  /**
   * Sends a notification that the user is currently typing
   * @param {*} post The post that is currenly being typed in
   */
  get: () => fokoud.get('/users/websocket'),
  connect: (websocketUrl) => connect(websocketUrl, fokoudWebSocket),
  sendTyping: (channelId, postId) => {
    if (!fokoudWebSocket.webSocket) {
      return
    }
    fokoudWebSocket.webSocket.send(
      JSON.stringify({
        id: uuid(),
        type: 'typing',
        data: {
          communityId: getCommunityId(),
          channelId,
          postId,
        },
      })
    )
  },
}
