import _ from 'lodash'
import { dispatchEvent, HAS_UNREAD_INBOX_ITEMS_CHANGED } from '../../../events'
import Colors from '../../../styles/colors'

let hasUnreadInboxItems = false

const setHasUnreadInboxItems = (_hasUnreadInboxItems) => {
  const hadUnreadInboxItems = hasUnreadInboxItems
  hasUnreadInboxItems = _hasUnreadInboxItems
  if (hadUnreadInboxItems !== hasUnreadInboxItems) {
    dispatchEvent(HAS_UNREAD_INBOX_ITEMS_CHANGED, hasUnreadInboxItems)
  }
}

const onMarkAllAsRead = () => {
  setHasUnreadInboxItems(false)
}

const icon = (inboxItem) => {
  const { iconType } = inboxItem
  if (iconType === 'mention') {
    return ['at-sign', Colors.blue]
  }
  if (iconType === 'comment') {
    return ['chat-bubble', Colors.blue]
  }
  if (iconType === 'like') {
    return ['heart', Colors.red]
  }
  if (_.includes(['projectSuccess', 'projectDefault'], iconType)) {
    return ['checkbox', Colors.blue]
  }
  if (iconType === 'projectAlert') {
    return ['exclamation-circle', Colors.blue]
  }
  if (iconType === 'channel') {
    return ['circle-awake', Colors.blue]
  }
  return ['', '']
}

export const Inbox = {
  getHasUnreadInboxItems: () => hasUnreadInboxItems,
  setHasUnreadInboxItems,
  onMarkAllAsRead,
  icon,
}
