import { fokoud } from '.'

const getFiles = (fileIds) => fokoud.post('/files/get', { fileIds })
const getDocuments = (documentIds) =>
  fokoud.post('/documents/get', { documentIds })

export const SharedService = {
  getFiles,
  getDocuments,
}
