import Colors, { darkTheme, defaultTheme } from './colors'

export const sizing = {
  xs: '0.125em',
  small: '0.25em', // 4px default border-radius
  medium: '0.5em',
  xmedium: '0.75em',
  normal: '1em',
  large: '1.25em',
  xl: '1.5em',
  xxl: '2em',
  xxxl: '3em',
  //
  postContentPadding: '3.5em',
  icon: '1.18em',
  commentContentPadding: '3.7em',
}

export const fontWeights = {
  semiBold: '600',
  regular: '400',
  normal: 'normal',
  bold: '500',
  boldest: '700',
}

export const zIndices = {
  UNREAD_POST_INDICATOR: 1,
  HOVER_ACTION: 1,
  APP_HEADER: 2,
  NAVIGATION_MENU: 2,
  REVEAL_PANE: 1000,
  // SCROLL_PIECE: 1001, // > REVEAL_PANE (web-1450)
  DROPDOWN: 2,
  CALENDAR_EVENT: 2,
  WYSIWYG_IMAGE: 1000, // needs to be >= 1000 for sortable
  TOAST: 1003, // needs to be >= 1001 so overtop of semantic modals
  ABOVE_MODAL_DIMMER: 1001,
  HELP_MODAL: 1002,
}

export const REVEAL_PANE_MARGIN_OFFSET = sizing.normal

// sizings are translated into em based off pixel sizes in specs
// i.e. divied by 14px which is our base font size
const BASE_FONT_SIZE = 14
const calcEm = (fontSize) => `${fontSize / BASE_FONT_SIZE}em !important`
const calcRem = (fontSize) => `${fontSize / BASE_FONT_SIZE}rem`
// todo .... letter spacing?
export const typeset = {
  custom: (fontSize, lineHeight) => ({
    fontSize: calcEm(fontSize),
    lineHeight: calcRem(lineHeight),
  }),
  heading1: {
    fontSize: calcEm(25.6),
    lineHeight: calcRem(31),
  },
  heading2: {
    fontSize: calcEm(22.8),
    lineHeight: calcRem(27),
  },
  heading3: {
    fontSize: calcEm(20.3),
    lineHeight: calcRem(24.17),
  },
  big: {
    fontSize: calcEm(34),
    lineHeight: calcRem(41),
    fontWeight: fontWeights.bold,
  },
  large: {
    fontSize: calcEm(34),
    lineHeight: calcRem(41),
  },
  title1: {
    fontSize: calcEm(28),
    lineHeight: calcRem(34),
  },
  title2: {
    fontSize: calcEm(22),
    lineHeight: calcRem(28),
    fontWeight: fontWeights.bold,
  },
  title3: {
    fontSize: calcEm(20),
    lineHeight: calcRem(25),
  },
  headline: {
    fontSize: calcEm(17),
    lineHeight: calcRem(22),
  },
  body: {
    fontSize: calcEm(14),
    lineHeight: calcRem(18),
  },
  callout: {
    fontSize: calcEm(16),
    lineHeight: calcRem(21),
  },
  subhead: {
    fontSize: calcEm(15),
    lineHeight: calcRem(20),
  },
  footnote: {
    fontSize: calcEm(13),
    lineHeight: calcRem(18),
  },
  caption1: {
    /* SF Pro/Caption/Regular */
    fontSize: calcEm(12.64),
    lineHeight: calcRem(16),
  },
  caption2: {
    /* Caption Small/Regular */
    fontSize: calcEm(11.2),
    lineHeight: calcRem(13),
  },
}

export const buttons = {
  greyCircular: {
    borderRadius: '50%',
    background: Colors.offWhite,
    margin: sizing.normal,
    '&:hover': {
      background: Colors.lightGrey,
    },
  },
  blackCircular: {
    borderRadius: '50%',
    background: Colors.nearBlack,
    '&:hover': {
      background: Colors.greyBlack,
    },
  },
  navigatorLeft: {
    marginLeft: `${sizing.normal} !important`,
  },
  navigatorRight: {
    marginLeft: `${sizing.normal} !important`,
  },
  blackBoxy: {
    borderRadius: '10%',
    border: `1px solid ${Colors.black}`,
    margin: sizing.normal,
    '&:hover': {
      background: Colors.nearWhite,
    },
  },
}

export const ellipsis = {
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}
export const viewAll = {
  display: 'flex',
  alignItems: 'center',
  color: Colors.nearBlack,
  '&:hover': {
    color: Colors.nearBlack,
  },
  '& i': {
    marginLeft: sizing.medium,
  },
}

export const calendar = {
  // TBD
  border: `1px solid ${Colors.lightGrey} !important`,
  '& .react-datepicker__header': {
    background: Colors.offWhite,
    borderBottom: `1px solid ${Colors.lightGrey} !important`,
  },
  '& .react-datepicker__current-month': {
    color: Colors.nearBlack,
    fontWeight: fontWeights.bold,
  },
  '& .react-datepicker__day-name': {
    color: Colors.nearBlack,
  },
  '& .react-datepicker__day--selected ': {
    background: Colors.nearBlack,
  },
  '& .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today':
    {
      background: Colors.nearBlack,
    },
  '& .react-datepicker__day--today ': {
    border: `1px solid ${Colors.nearBlack}`,
  },
  '& .react-datepicker__day': {
    borderRadius: sizing.xl,
  },
  '& .react-datepicker__triangle::before': {
    borderBottomColor: ` ${Colors.lightGrey} !important`,
  },
  '& .react-datepicker-time__header': {
    color: Colors.nearBlack,
    fontWeight: fontWeights.bold,
  },
  '& .react-datepicker__time-list-item': {
    borderRadius: sizing.small,
  },
  '& .react-datepicker__time-list-item--selected': {
    background: `${Colors.nearBlack} !important`,
  },
  '& .react-datepicker__day.react-datepicker__day--in-selecting-range': {
    background: `${Colors.nearBlack} !important`,
  },
  // eslint-disable-next-line max-len
  '& .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end':
    {
      background: `${Colors.nearBlack} !important`,
    },
  '& .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range':
    {
      background: `${Colors.nearBlack} !important`,
    },
  '& .react-datepicker__day.react-datepicker__day--in-range': {
    background: `${Colors.nearBlack} !important`,
    color: `${Colors.white} !important`,
  },
  '& .react-datepicker__day.react-datepicker__day--keyboard-selected': {
    background: `${Colors.nearBlack} !important`,
  },
}

export const list = {
  '& > div': {
    borderBottom: `1px solid ${Colors.lightGrey}`,
    '&:first-child': {
      borderTop: `1px solid ${Colors.lightGrey}`,
    },
  },
}
export const dividerBorder = `solid 1px ${Colors.grey}`

export const MAX_WIDTH = '100%'

export const MAX_CONTENT_WIDTH = '1080px'

export const MAX_MODAL_HEIGHT = '40vh'

export const MOBILE_WIDTH = 767

export const SCREEN_WIDTH = 1320

export const MAX_IMAGE_HEIGHT = '240px'

export const mediaMobile = `@media only screen and (max-width: ${MOBILE_WIDTH}px)`

export const mediaScreen = `@media only screen and (min-width: ${MOBILE_WIDTH}px)`

export const boxShadow = '0 1px 2px 0 rgba(34, 36, 38, 0.15)'

export const navigator = {
  width: '100%',
  borderBottom: `1px solid ${Colors.lightGrey}`,
  flex: '0 0 auto', // safari
}

export const content = {
  width: '100%',
  maxWidth: MAX_CONTENT_WIDTH,
  padding: sizing.normal,
  paddingTop: sizing.xxl,
}

export const draggingItem = {
  border: `1px solid ${Colors.lightGrey}`,
  background: Colors.white,
  borderRadius: sizing.medium,
  boxShadow:
    '0px 8px 8px rgba(29, 29, 29, 0.15), 0px 16px 16px rgba(29, 29, 29, 0.1)',
}

export const image = {
  height: '135px',
  width: '135px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: sizing.medium,
  margin: sizing.small,
  zIndex: zIndices.WYSIWYG_IMAGE,
}

export const themeStyle = (theme, themeFunction) =>
  themeFunction(theme.isDark ? darkTheme : defaultTheme)

export const documentStyle = (theme) =>
  // eslint-disable-next-line no-shadow
  themeStyle(theme, (Colors) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        background: Colors.offWhite,
      },
      paddingRight: sizing.normal,
      marginTop: '-1px',
      border: `1px solid ${Colors.lightGrey}`,
      color: `${Colors.black} !important`,
    },
    message: {
      borderLeft: 'none',
      borderRight: 'none',
    },
    dashboard: {
      marginTop: sizing.medium,
      position: 'relative',
      '&:first-child': {
        marginTop: 0,
      },
      background: Colors.white,
      borderRadius: sizing.medium,
    },
    link: {
      flex: 1,
      maxWidth: '100%',
      color: `${Colors.nearBlack} !important`,
      cursor: 'pointer',
      ...typeset.body,
    },

    document: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      margin: `0 ${sizing.xmedium} !important`,
    },
    name: {
      flex: 1,
      padding: `${sizing.xmedium} `,
      borderLeft: `1px solid ${Colors.lightGrey}`,
      overflow: 'hidden',
      '& > div': ellipsis,
    },
    folderPath: {
      display: 'flex',
      color: Colors.midDarkGrey,
      '& div:nth-child(2)::before': {
        content: "'-'",
        margin: `0 ${sizing.small}`,
      },
      ...typeset.footnote,
    },
    indicator: {
      height: '80%',
      width: '1px',
      left: '-4px',
      border: 'none',
      borderRadius: sizing.small,
      padding: sizing.small,
      top: 'auto',
    },
  }))
