import merge from 'lodash/merge'
import get from 'lodash/get'
import map from 'lodash/map'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import isNil from 'lodash/isNil'
import { fokoudAdmin } from '../../../api'
import { Session } from '../../core/models/session'
import { User } from '../../core/models/user'
import { fokoServices } from '../../../api/services'
import { DEFAULT_LIMIT } from '../../core/api/constants'
import { sortBy } from '../../../api/services/sort-by'

export const UserService = {
  USER_LIMIT: DEFAULT_LIMIT,
  MAX_NAME_LENGTH: 100,
  MAX_BIO_LENGTH: 2000,
  search: (offset, searchValue, includeTotal, sort, filters, limit) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/search`,
      merge(
        {
          offset,
          limit: limit || UserService.USER_LIMIT,
          searchValue: searchValue || '',
          includeTotal,
          sortBy: sortBy(sort),
        },
        filters
      )
    ),
  searchUserListAndUser: (
    offset,
    searchValue,
    includeTotal,
    sort,
    privileges
  ) =>
    fokoServices
      .post(
        `/organizations/${Session.getOrganizationId()}/user-list/users/search`,
        {
          offset,
          limit: UserService.USER_LIMIT,
          searchValue: searchValue || '',
          includeTotal,
          sortUserBy: sortBy(sort),
          sortUserListBy: sortBy(sort),
          privileges,
        }
      )
      .then((res) => {
        const userlists = map(get(res.data, 'userLists'), (uselist) => {
          const uselistClone = uselist
          uselistClone.type = 'userList'
          return uselistClone
        })
        return concat(userlists, get(res.data, 'users'))
      }),

  delete: (userIds) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/delete`,
      {
        userIds,
      }
    ),
  getChannels: (user, partialName, offset) =>
    fokoudAdmin.get(
      `/services/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/channels`,
      {
        params: {
          nameFilter: partialName,
          skip: offset,
          limit: DEFAULT_LIMIT,
        },
      }
    ),
  removeChannels: (user, channels) =>
    fokoudAdmin.put(
      `/services/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/updateChannels`,
      {
        remove: channels,
      }
    ),
  addChannels: (user, channels) =>
    fokoudAdmin.put(
      `/services/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/updateChannels`,
      {
        add: channels,
      }
    ),
  getUserlists: (user, searchValue, offset) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/user-list/search`,
      {
        searchValue,
        offset,
        limit: DEFAULT_LIMIT,
      }
    ),
  removeUserlists: (user, userlistsIds) =>
    fokoServices.put(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/user-list`,
      {
        removedUserListIds: userlistsIds,
      }
    ),
  addUserlists: (user, userlistsIds) =>
    fokoServices.put(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/user-list`,
      {
        addedUserListIds: userlistsIds,
      }
    ),
  getAttributes: (user, searchValue, offset) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/attributes/search`,
      {
        searchValue,
        offset,
        limit: DEFAULT_LIMIT,
      }
    ),
  addAttributes: (user, attributesIds) =>
    fokoServices.put(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/attributes`,
      {
        addedAttributeIds: attributesIds,
      }
    ),
  removeAttributes: (user, attributesIds) =>
    fokoServices.put(
      `/organizations/${Session.getOrganizationId()}/users/${
        user.id
      }/attributes`,
      {
        removedAttributeIds: attributesIds,
      }
    ),
  create: (user, organizationId = Session.getOrganizationId()) =>
    fokoServices.post(`/organizations/${organizationId}/users`, user),
  forceLogout: (userIds) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/force-logout`,
      { userIds }
    ),
  getStats: (userId) =>
    fokoudAdmin.get(
      `/services/organizations/${Session.getOrganizationId()}/users/${userId}/stats`
    ),
  changeUserPassword: (userId, newPassword, currentPassword) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/${userId}/change-password`,
      {
        newPassword,
        currentPassword,
      }
    ),
  updateAdmin: (user, isAdmin) =>
    fokoServices
      .put(
        `/organizations/${Session.getOrganizationId()}/users/${user.id}/admin`,
        { isAdmin }
      )
      .then(() => User.afterUpdateAdmin(user, isAdmin)),

  update: (user, userUpdate, isAdmin = undefined) => {
    const updatedUser = merge({}, user, userUpdate)
    return Promise.resolve()
      .then(() => {
        if (!isEqual(userUpdate, pick(user, keys(userUpdate)))) {
          return fokoServices
            .put(
              `/organizations/${Session.getOrganizationId()}/users/${user.id}`,
              userUpdate
            )
            .then((res) => {
              User.afterUpdateUser(user, merge(res.data, userUpdate))
              return res
            })
        }
      })
      .then(() => {
        if (User.isAdmin(user) !== isAdmin && !isNil(isAdmin)) {
          return UserService.updateAdmin(updatedUser, isAdmin)
        }
      })
  },

  getResetPasswordLink: (
    userId,
    organizationId = Session.getOrganizationId(),
    sendEmail = false
  ) =>
    fokoServices
      .get(
        `/organizations/${organizationId}/users/${userId}/reset-password/link`,
        {
          params: {
            sendEmail,
          },
        }
      )
      .then((response) => response.data.resetPasswordLink),

  ERRORS: {
    USERNAME_ALREADY_EXISTS: 'USERNAME_ALREADY_EXISTS',
    GLOBAL_USERNAME_ALREADY_EXISTS: 'GLOBAL_USERNAME_ALREADY_EXISTS',
    USER_ROLE_PLACE_ALREADY_EXISTS: 'USER_ROLE_PLACE_ALREADY_EXISTS',
    WEAK_PASSWORD: 'WEAK_PASSWORD',
    PASSWORD_PREVIOUSLY_USED: 'PASSWORD_PREVIOUSLY_USED',
    EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
    INVALID_REQUEST_DATA: 'INVALID_REQUEST_DATA',
    WRONG_CURRENT_PASSWORD: 'WRONG_CURRENT_PASSWORD',
  },
  archive: (users, status = 'DISABLED') =>
    Promise.mapSeries(users, (user) =>
      fokoServices
        .put(
          `/organizations/${Session.getOrganizationId()}/users/${
            user.id
          }/status`,
          { status }
        )
        .then((response) => {
          User.afterUpdateStatus(user, status)
          return response
        })
    ),
  restore: (users, status = 'ACTIVE') =>
    Promise.mapSeries(users, (user) =>
      fokoServices
        .put(
          `/organizations/${Session.getOrganizationId()}/users/${
            user.id
          }/status`,
          { status }
        )
        .then((response) => {
          User.afterUpdateStatus(user, status)
          return response
        })
    ),
  createRolePlacePair: (user, data) =>
    fokoServices
      .post(`/organizations/${Session.getOrganizationId()}/user-roles`, {
        placeId: data.place.id,
        roleId: data.role.id,
        userId: user.id,
      })
      .then((response) => {
        User.afterCreateRolePlace(user, data, response.id)
        return response
      }),
  updateRolePlacePair: (user, data) =>
    fokoServices
      .put(
        `/organizations/${Session.getOrganizationId()}/user-roles/${
          data.userRoleId
        }`,
        {
          placeId: data.place.id,
          roleId: data.role.id,
          userId: user.id,
        }
      )
      .then((response) => {
        User.afterUpdateRolePlace(user, data)
        return response
      }),
  deleteRolePlacePair: (user, userRoleId) =>
    fokoServices
      .delete(
        `organizations/${Session.getOrganizationId()}/user-roles/${userRoleId}`
      )
      .then((res) => {
        User.afterDeleteRolePlace(user, userRoleId)
        return res
      }),
  getAuthMethods: () =>
    fokoServices.get(`organizations/${Session.getOrganizationId()}/authmethod`),
  import: (file, isSync = false) => {
    const form = new FormData()
    form.append('users', file)
    return fokoServices.post(
      `/import/organizations/${Session.getOrganizationId()}/users${
        isSync ? '/sync' : ''
      }`,
      form,
      {
        // onUploadProgress: (e) => {
        //   if (promise.isCancelled()) {
        //     return
        //   }
        //   onUploadProgress(e)
        // },
      }
    )
  },
  searchByAssociations: (associations) =>
    fokoServices.post(
      `organizations/${Session.getOrganizationId()}/users/associations/search`,
      associations
    ),
}
