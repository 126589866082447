import { fokoud } from '../../../api'
import {
  dispatchEvent,
  DOCUMENT_DELETED,
  DOCUMENT_CREATED,
  DOCUMENT_EDITED,
} from '../../../events'
import { DEFAULT_LIMIT } from './constants'

export const DocumentService = {
  create: (document) =>
    fokoud.post('/documents', document).then((res) => {
      dispatchEvent(DOCUMENT_CREATED, res)
      return res
    }),
  edit: (documentId, documentData) =>
    fokoud.put(`/documents/${documentId}`, documentData).then((document) => {
      dispatchEvent(DOCUMENT_EDITED, document)
      return document
    }),
  delete: (documentId) =>
    fokoud.delete(`/documents/${documentId}`).then((response) => {
      dispatchEvent(DOCUMENT_DELETED, documentId)
      return response
    }),
  viewers: (documentId, skip) => {
    const params = {
      'filter[limit]': DEFAULT_LIMIT,
      'filter[skip]': skip,
      'filter[include][owner]': 'avatar',
      'filter[order]': 'fullName',
    }
    return fokoud.get(`/documents/${documentId}/viewers`, {
      params,
    })
  },
  versions: (documentId) => fokoud.get(`/documents/${documentId}/revisions`),
  markAsViewed: (documentId) => fokoud.post(`/documents/${documentId}/mark`),
}
