let history
let lastLocation
let currentLocation

const set = (_history) => {
  history = _history
}

const getLastLocation = () => lastLocation?.pathname || '/'

const getCurrentLocation = () => currentLocation

const setCurrentLocation = (location) => {
  if (location?.state?.asRedirect !== true) {
    lastLocation = currentLocation
  }
  const pathname = location?.pathname
  // check if this is a post solo view deeplink modal!
  if (
    pathname?.includes('/posts/') ||
    (pathname?.includes('/organizations/') &&
      pathname?.includes('/broadcasts/'))
  ) {
    return
  }
  currentLocation = location
  return currentLocation?.pathname !== lastLocation?.pathname
}

const routeTo = (path, asRedirect = false, state) => {
  if (path === window.location.pathname) {
    return
  }
  if (asRedirect) {
    return state
      ? history.replace(path, { asRedirect: true, ...state })
      : history.replace(path, { asRedirect: true })
  }
  return state ? history.push(path, state) : history.push(path)
}

const goToLast = () => {
  routeTo(getLastLocation())
}

const getPathValue = (key) => {
  const index = window.location.pathname.split('/').indexOf(key)
  const parts = window.location.pathname.split('/')
  return parts?.[`${index + 1}`]
}

/**
 * Reloads the current location and sets the location state info
 * @param {string} path
 * @param {object} state
 */
const reloadState = (path, state) => {
  if (state?.asRedirect) {
    history.replace(path, state)
  } else {
    history.push(path, state)
  }
  window.location.reload()
}

export const Router = {
  set,
  getLastLocation,
  getCurrentLocation,
  setCurrentLocation,
  routeTo,
  goToLast,
  getPathValue,
  reloadState,
}
