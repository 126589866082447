import _ from 'lodash'
import moment from 'moment'
import { Mention } from '../mention'
import { userId } from '../session'
import createUpdate from './create-update'
import { LOCAL_URL, META_DATA } from '../file'
import {
  replaceWithAnyErrorPosts,
  discard,
  retry,
} from './create-update/errors'
import { store as uploadingStore } from './create-update/store'
import { Channel } from '../channel'
import { Image } from '../image'
import { Messages } from '../message'
import { POST_UPDATED, dispatchEvent } from '../../../../events'
import { Community } from '../community'

const isLiked = (post) => _.includes(post.latestLikes, userId)

const isFeedPost = (post) => _.get(post, 'postType') === 'feedItem'
const isContainerItem = (post) => _.get(post, 'postType') === 'containerItem'
const isComment = (post) => _.get(post, 'postType') === 'comment'

const isMessagePost = (post) => _.get(post, 'channelType') === 'dm'

const afterCreate = (post) => {
  if (isMessagePost(post)) {
    Messages.afterPostCreated(post)
  }
}
// meta for if we want to alert there is new post in the feed if its not visible
export const IS_WEBSOCKET_UPDATE = '_isWebSocketUpdate'
const IS_FLAGGED_FOR_ALERT = '_isFlaggedForAlert'
const isFlaggedForAlert = (post) => _.get(post, IS_FLAGGED_FOR_ALERT)
const setIsFlaggedForAlert = (post, isFlaggedForAlert) => {
  post[IS_FLAGGED_FOR_ALERT] =
    isFlaggedForAlert === false ? undefined : isFlaggedForAlert
}
// meta for uploading and error states
export const LOCAL_ID = '_id'
const id = (post, isBroadcast) => {
  if (isBroadcast) {
    return post.broadcastId
  }
  return _.get(post, 'id') || _.get(post, LOCAL_ID)
}
export const IS_ERROR = '_isError'
export const IS_UPLOADING = '_isUploading'
export const ROLLBACK_POST = '_rollbackPost' // is an instance of the "previous" post to revert to in case we want to "discard" an error
const isLocal = (post) => !_.isNil(_.get(post, LOCAL_ID))
const isUploading = (post) => _.get(post, IS_UPLOADING)
const isError = (post) => _.get(post, IS_ERROR)

const isImagePost = (post, isBroadcast) => {
  if (isBroadcast) {
    return _.size(_.get(post, 'images')) === 1
  }
  return _.get(post, 'type') === 'image'
}

const isAlbumPost = (post, isBroadcast) => {
  if (isBroadcast) {
    return _.size(_.get(post, 'images')) > 1
  }
  return _.get(post, 'type') === 'container'
}

const isTaskResultPost = (post) => !_.isNil(_.get(post, 'taskId'))

const isOwner = (post) => _.get(post, 'owner.id') === userId || isLocal(post)

const isMentioned = (post) => {
  const mentions = post.mentions || []
  for (let i = 0; i < mentions.length; i++) {
    const mention = mentions[i]
    if (Mention.includesMe(mention)) {
      return true
    }
  }
  return false
}

const isRead = (post, afterDate) =>
  post.pokedAt && !moment(post.pokedAt).isAfter(afterDate)

const canComment = (post) => {
  // don't do below...canComment is used for image markup logic as well!
  // if (isMessagePost(post)) {
  //   return false
  // }
  const channel = Channel.getById(_.get(post, 'channelId'))
  return isOwner(post) || _.get(channel, 'settings.commenting') !== false
}

const canShare = (post) => {
  const channel = Channel.getById(_.get(post, 'channelId'))
  return _.get(channel, 'settings.sharing') !== false
}

const image = (post, desiredSize) =>
  Image.getSize(_.get(post, 'media[0]'), desiredSize)
const imageUrl = (post, desiredSize) => {
  const localUrl = _.get(post, `media[0].${[LOCAL_URL]}`)
  if (localUrl) {
    return localUrl
  }
  return _.get(image(post, desiredSize), 'url')
}

const images = (post) => {
  if (isImagePost(post)) {
    return _.get(post, 'media')
  }
  if (isAlbumPost(post)) {
    return _.map(_.get(post, 'containerItems'), (containerItem) => {
      _.merge(containerItem.place, { id: containerItem.placeId }) // maintain place id (e.g. for edits)
      return _.merge(
        {
          [META_DATA]: containerItem,
        },
        _.get(containerItem, 'media[0]')
      )
    })
  }
}

const video = (post, isBroadcast) => {
  if (!Community.isInlineVideoEnabled()) {
    return
  }
  if (isBroadcast) {
    if (_.size(_.get(post, 'images')) > 0) {
      return
    }
  } else if (_.get(post, 'type') !== 'text') {
    return
  }
  return _.find(
    isBroadcast ? _.get(post, 'attachments') : _.get(post, 'documents'),
    { type: 'video' }
  )
}

const getContainerItemById = (post, id) => _.find(post.containerItems, { id })

// considers both server id and local id
const isIdMatch = (post1, post2) => {
  if (!post1 || !post2) {
    return false
  }
  return (
    (post1.id && post2.id && post1.id === post2.id) ||
    (post1[LOCAL_ID] && post2[LOCAL_ID] && post1[LOCAL_ID] === post2[LOCAL_ID])
  )
}

const insert = (posts, postToInsert, sort = 'pokedAt desc') => {
  const [sortField, sortOrder] = _.split(sort, ' ')
  const index = _.findIndex(posts, (post) => isIdMatch(post, postToInsert))
  if (index >= 0) {
    // nothing to do
    return posts
  }
  const newPosts = posts.slice()
  newPosts.unshift(_.cloneDeep(postToInsert))
  return _.orderBy(newPosts, (post) => post[sortField], sortOrder)
}

const maintainPollVoteCounts = (oldPost, newPost) => {
  const newChoices = _.get(newPost, 'poll.choices')
  _.forEach(newChoices, (newChoice) => {
    const oldChoice = _.find(_.get(oldPost, 'poll.choices'), {
      id: newChoice.id,
    })
    newChoice.voteCount = _.get(oldChoice, 'voteCount', 0)
  })
}

const update = (posts, _postToUpdate, sort = 'pokedAt desc', meta) => {
  const postToUpdate = _.cloneDeep(_postToUpdate)
  const fieldsToMaintain = [IS_FLAGGED_FOR_ALERT, 'isViewed', 'pollChoices']
  const [sortField, sortOrder] = _.split(sort, ' ')
  const index = _.findIndex(posts, (post) => isIdMatch(post, postToUpdate))
  if (index >= 0) {
    // cleanup posts and push back the updatedPost later on
    // using reject b/c there could be multiple id matches
    // (e.g.) we get a websocket post create event before the post update sent from our custom create-udpate logic! (WEB-324)
    const newPosts = _.reject(posts, (post) => isIdMatch(post, postToUpdate))
    if (!isError(postToUpdate) && !isUploading(postToUpdate)) {
      delete postToUpdate[LOCAL_ID] // remove
    }
    const oldPost = posts[index]
    // prevent websocket updates while uploading (to prevent album flashing)
    if (
      (isError(oldPost) || isUploading(oldPost)) &&
      _.get(meta, IS_WEBSOCKET_UPDATE)
    ) {
      return [false, posts]
    }
    // if this is an error, we are getting sent the valid original post
    // let's maintain the previous post for display purposes
    if (isError(postToUpdate)) {
      newPosts.push(
        _.merge({}, oldPost, { _isError: true, _isUploading: false })
      )
    } else {
      const newPost = _.merge(
        {},
        postToUpdate,
        _.pick(oldPost, fieldsToMaintain)
      )
      if (_.get(meta, IS_WEBSOCKET_UPDATE) && newPost.poll) {
        maintainPollVoteCounts(oldPost, newPost)
      }
      newPosts.push(newPost)
    }
    return [true, _.orderBy(newPosts, (post) => post[sortField], sortOrder)]
  }
  return [false, posts]
}

const remove = (posts, postToRemove) => {
  const newPosts = _.reject(posts, (post) => isIdMatch(post, postToRemove))
  return [posts.length !== newPosts.length, newPosts]
}

const needsRerender = (prevPost, nextPost) => {
  const updateFields = [
    'id',
    'updatedAt',
    IS_UPLOADING,
    IS_ERROR,
    'containerItems.length',
    'pollChoices',
    'attachments.length',
    'images.length',
    // 'viewerCount',
  ]
  if (
    _.some(
      updateFields,
      (field) => _.get(prevPost, field) !== _.get(nextPost, field)
    )
  ) {
    return true
  }

  // check actual attachments (length may be the same but with different attachments (e.g. edit))
  const prevAttachments = _.get(prevPost, 'attachments')
  if (
    _.some(
      prevAttachments,
      (prevAttachment, i) =>
        !_.isEqual(prevAttachment, _.get(nextPost, `attachments[${i}]`))
    )
  ) {
    return true
  }

  // check actual images
  const prevImages = _.get(prevPost, 'images')
  if (
    _.some(
      prevImages,
      (prevImage, i) => !_.isEqual(prevImage, _.get(nextPost, `images[${i}]`))
    )
  ) {
    return true
  }

  // now check container item posts
  const indices = _.range(_.get(prevPost, 'containerItems.length'))
  return _.some(indices, (i) =>
    needsRerender(
      _.get(prevPost, `containerItems[${i}]`),
      _.get(prevPost, `containerItems[${i}]`)
    )
  )
}

const afterPollRefresh = (_post, poll) => {
  const post = _.cloneDeep(_post)
  post.poll = poll
  // hack the updatedAt so post renders (see needsRerender)
  _.set(post, 'updatedAt', moment().utc().format())
  dispatchEvent(POST_UPDATED, post)
}

const hasDocuments = (post) =>
  !_.isEmpty(post.attachments) || !_.isEmpty(post.documents)

const getAnnouncementLines = (post, isBroadcast) => {
  let maxNumOfLines = 4
  if (hasDocuments(post)) {
    maxNumOfLines = 3
  } else if (isAlbumPost(post, isBroadcast) || isImagePost(post, isBroadcast)) {
    maxNumOfLines = 1
  }
  return maxNumOfLines
}

export const Post = {
  id,
  images,
  getContainerItemById,
  //
  createUpdate,
  //
  isIdMatch,
  insert,
  update,
  remove,
  //
  IS_WEBSOCKET_UPDATE,
  isFlaggedForAlert,
  setIsFlaggedForAlert,
  isLocal,
  isUploading,
  isError,
  isLiked,
  isFeedPost,
  isContainerItem,
  isComment,
  isMessagePost,
  isImagePost,
  isAlbumPost,
  isOwner,
  isMentioned,
  isRead,
  image,
  imageUrl,
  isTaskResultPost,
  video,
  hasDocuments,
  //
  canComment,
  canShare,
  //
  needsRerender,
  //
  uploadingStore,
  replaceWithAnyErrorPosts,
  discard,
  retry,
  afterCreate,
  afterPollRefresh,
  getAnnouncementLines,
}
