import _ from 'lodash'
import { tr } from '../../../i18n'

const ACTION = 'action'
const PHOTO = 'image'
const ATTACHMENT = 'attachment'
const SURVEY = 'select'
const TEXT = 'text'
const NUMERIC = 'numeric'

const types = {
  ACTION,
  PHOTO,
  ATTACHMENT,
  SURVEY,
  TEXT,
  NUMERIC,
}

const icon = (type) => {
  if (type === ACTION) {
    return 'check-circle'
  }
  if (type === PHOTO) {
    return 'image'
  }
  if (type === ATTACHMENT) {
    return 'paperclip'
  }
  if (type === SURVEY) {
    return 'clipboard-list'
  }
  if (type === TEXT) {
    return 'text'
  }
  if (type === NUMERIC) {
    return 'numbers'
  }
  return ''
}

const sanitize = (step) => {
  const type = _.get(step, 'type')
  if (type === TEXT && _.get(step, 'subType.type') === NUMERIC) {
    return NUMERIC
  }
  return type
}

const label = (type) => {
  let key = type
  if (type === PHOTO) {
    key = 'photo'
  }
  if (type === SURVEY) {
    key = 'poll'
  }
  return tr(`tasks.${key}`)
}

const descriptionPlaceholder = (type) => {
  let key = type
  if (type === PHOTO) {
    key = 'photo'
  } else if (type === SURVEY) {
    key = 'poll'
  } else if (!_.includes(_.values(types), type)) {
    key = 'default'
  }
  return tr(`tasks.creation.items.descriptionPlaceholder.${key}`)
}

export const StepType = {
  ACTION,
  PHOTO,
  ATTACHMENT,
  SURVEY,
  TEXT,
  NUMERIC,
  types,
  icon,
  label,
  sanitize,
  descriptionPlaceholder,
}
