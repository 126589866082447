/* eslint-disable no-param-reassign */
import _ from 'lodash'
import { getLocalStorageItem, I18N } from './utils/local-storage'
import { ENGLISH_LANG_CODE } from './modules/core/i18n/constants'

export const strings = {}

const generateId = () => window.crypto.randomUUID()

export const getLanguage = (returnDefault = true) => {
  const i18n = getLocalStorageItem(I18N)
  if (i18n) {
    return i18n
  }
  if (returnDefault) {
    return ENGLISH_LANG_CODE
  }
}

// export const setLanguage = (lang) => {
//   localStorage.setItem('i18n', lang)
// }

export const isRtl = () => getLanguage() === 'rtl'

const replaceStrings = (string, replacements) => {
  if (!_.isArray(replacements)) {
    replacements = [replacements]
  }
  _.forEach(replacements, (replacement, i) => {
    string = _.replace(string, `%${i + 1}$s`, replacement)
  })
  return string
}

export const tr = (key, value = 0, stringReplacements) => {
  const string = _.get(strings, key)
  if (getLanguage() === 'test') {
    if (_.isObject(string)) {
      return `${value} ${key}_${generateId()}`
    }
    return `${key}_${generateId()}`
  }
  if (!string) {
    return key
  }
  if (_.isArray(string)) {
    return string
  }
  if (_.isObject(string)) {
    let plural = string[value] || string.other
    if (!plural) {
      return key
    }
    plural = _.replace(plural, '%d', value)
    return replaceStrings(plural, stringReplacements)
  }
  return replaceStrings(string, value)
}
