import findIndex from 'lodash/findIndex'
import concat from 'lodash/concat'

export const replaceById = (array, updatedObject) => {
  const index = findIndex(array, { id: updatedObject.id })

  if (index >= 0) {
    const newArray = concat(array, [])
    newArray[index] = updatedObject
    return newArray
  }
  return array
}
