/**
 * getSize
 *
 * @description Returns the image object with the correct sizes to display
 *
 * @param {object} file - image file
 * @param {string} desiredSize - image size i.e. "original"
 * @returns {object} - File with correct image sizes
 */
const getSize = (file, desiredSize) => {
  const split = desiredSize?.split('-square')
  const isSquare = split.length > 1
  const sizes = [
    'original',
    'extra-large',
    'large',
    'medium',
    'small',
    'original',
  ] // revert to original in worst-case scenario
  const index = sizes?.indexOf(split[0])
  for (let i = index; i < sizes.length; i++) {
    const nextImage = file?.[`${sizes[i]}${isSquare ? '-square' : ''}`]
    if (nextImage) {
      return nextImage
    }
  }
  return file
}

/**
 * imageUrl
 *
 * @description Returns the url for an image
 *
 * @param {object} file - image file
 * @param {string} desiredSize - image size i.e. "original"
 * @returns {string} - Dominant color in rgb format => 'rgb(color1, color2, color3)'
 */
const imageUrl = (file, desiredSize) => getSize(file, desiredSize)?.url

/**
 * getDominantColor
 *
 * @description Gets a dominant color in an image
 *
 * @param {object} file - image file
 * @returns {string} - Dominant color in rgb format => 'rgb(color1, color2, color3)'
 */
const getDominantColor = (file) => {
  if (!file.dominantColor) {
    return
  }
  const [c1, c2, c3] = file?.dominantColor
  return `rgb(${c1}, ${c2}, ${c3})`
}

export const Image = {
  getSize,
  imageUrl,
  getDominantColor,
}
