import { fokoud } from '../../../api'
import { fokoServices } from '../../../api/services'
import { Communities } from '../models/community'
import { getCommunityId, Session } from '../models/session'
import { Channels } from '../models/channel'
import { Messages } from '../models/message'
import { Inbox } from '../../inbox/models/inbox'
import { Task } from '../../task/models/task'
import { Broadcast } from '../../broadcasts/models/broadcast'
import { TaskBadges } from '../../tasks/models/task-badges'
import { WFX_RELOAD } from '../../../utils/local-storage'

let communityPromise

const getServicesBadges = (
  loadChannelBadge,
  loadMessageBadge,
  loadTaskBadge,
  loadTasksBadge
) =>
  fokoServices.get(
    `/organizations/${Session.getOrganizationId()}/users/${Session.getServicesUserId()}/badges`,
    {
      params: {
        dms: loadMessageBadge,
        channels: loadChannelBadge,
        tasks: loadTaskBadge,
        newTasks: loadTasksBadge,
        communityId: getCommunityId(),
      },
    }
  )

export const CommunitiesService = {
  get: () => {
    const communities = Communities.getRaw()
    if (communities) {
      return Promise.resolve(communities)
    }
    if (!communityPromise) {
      communityPromise = fokoud
        .get('/users/communities')
        .then((response) => {
          Communities.set(response.data)
          localStorage.setItem(WFX_RELOAD, null)
          return response
        })
        .finally(() => {
          communityPromise = undefined
        })
    }
    return communityPromise
  },
  badges: (
    loadChannelBadge,
    loadMessageBadge,
    loadTaskBadge,
    loadTasksBadge
  ) => {
    const badgesRequest = getServicesBadges(
      loadChannelBadge,
      loadMessageBadge,
      loadTaskBadge,
      loadTasksBadge
    )
    return Promise.resolve(badgesRequest).then((badges) => {
      if (badges?.data.channels) {
        const { hasNewActivity, hasPriorityActivity, unreadBroadcastCount } =
          badges.data.channels
        Channels.setHasNewActivity(hasNewActivity, hasPriorityActivity)
        if (unreadBroadcastCount) {
          Broadcast.setUnreadCount(unreadBroadcastCount)
        }
      }
      if (badges?.data.dms) {
        const { hasNewActivity } = badges.data.dms
        Messages.setHasNewActivity(hasNewActivity)
      }
      if (badges?.data.tasks) {
        Task.setActivityCounts(badges.data.tasks)
      }
      if (badges?.data.newTasks) {
        TaskBadges.setActivityBadge(badges.data.newTasks)
      }

      Inbox.setHasUnreadInboxItems(badges.data.hasUnreadInboxItems)
      return badges
    })
  },
}
