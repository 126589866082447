// https://xd.adobe.com/spec/9d17d6c1-4130-4c49-74a8-870c8b160751-eb26/screen/f0b1a7d4-a96c-46f8-889d-0cc8010cf7a2/Colours/
import _ from 'lodash'

export const defaultTheme = {
  // base colors / denotive
  blue: '#3E86DB',
  persianBlue: '#005e8f',
  lightBlue: '#E0F7FF',
  brightBlue: '#22ece7',
  darkBlue: '#144A9A',
  yellow: '#FFE486',
  lightYellow: '#FFF2C5',
  darkYellow: '#F6D171',
  lightGreen: '#BEEFD6',
  darkGreen: '#3D7961',
  green: '#74D6A5',
  biscayGreen: '#45A18A',
  successGreen: '#2E7D32',
  pink: '#FDE6E3',
  magenta: '#EF318C',
  purple: '#5F3AAD',
  red: '#F68171',
  redError: '#D32F2F',
  darkRed: '#C03636',
  orange: '#F99A42',
  warning: '#E65621',
  warning50: '#FBEAE8',
  brown: '#7D6045',
  information: '#6360A8',
  information50: '#E8E8F3',
  // primary
  primaryMain: '#067EB0',
  primary50: '#E2F6FB',
  primaryMain900: '#005E8F',
  primary: '#343537' /* Grey/grey--800 */,
  // shades of grey
  white: '#fff',
  greyMain: '#656668',
  grey200: '#E3E5E7' /* Grey/grey--200 */,
  grey300: '#D1D3D5' /* Grey/grey--300 */,
  grey400: '#ADAFB1',
  greyLight: '#EEF0F2' /* Grey--100 */,
  grey50: '#F6F8FA' /* Grey/grey--50 */,
  nearWhite: '#F7F7FA' /* Gray / 0% */,
  offWhite: '#F0F0F3' /* Gray / 5% */,
  lightGrey: '#E6E4EA' /* Gray / 10% */,
  grey: '#D1CFD7' /* Gray / 20% */,
  midGrey: '#B8B6BF' /* Gray / 30% */,
  midDarkGrey: '#9A99A2' /* Gray / 40% */,
  darkGrey: '#77767E' /* Gray / 50% */,
  greyBlack: '#4E4E53' /* Gray / 60% */,
  nearBlack: '#1D1D1D' /* Gray / 70% */,
  black: '#000',
  // misc (let's get rid of these!)
  cta: '#F68171',
}

// todo
export const darkTheme = _.merge({}, defaultTheme, {
  // base colors / denotive
  blue: defaultTheme.lightBlue,
  lightBlue: defaultTheme.blue,
  lightYellow: defaultTheme.darkYellow,
  darkYellow: defaultTheme.lightYellow,
  lightGreen: defaultTheme.darkGreen,
  darkGreen: defaultTheme.lightGreen,
  pink: defaultTheme.darkRed,
  darkRed: defaultTheme.pink,
  // shades of grey
  white: '#222',
  nearWhite: defaultTheme.nearBlack,
  offWhite: defaultTheme.greyBlack,
  lightGrey: defaultTheme.darkGrey,
  grey: defaultTheme.midDarkGrey,
  midDarkGrey: defaultTheme.grey,
  darkGrey: defaultTheme.lightGrey,
  greyBlack: defaultTheme.offWhite,
  nearBlack: defaultTheme.nearWhite,
  black: defaultTheme.white,
})

const Colors = Object.assign(defaultTheme)

export default Colors
