const get = (key) => JSON.parse(localStorage.getItem(key))

const set = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const add = (key, itemToStore, id) => {
  let items = get(key)
  if (!items) {
    items = {}
  }
  items[id] = itemToStore
  set(key, items)
}

export const Store = {
  get,
  set,
  add,
}
