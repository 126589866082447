import './polyfills'
import './init'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from './root'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ErrorHandler } from './utils/error-handler'

ErrorHandler.init()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers:
// https://create-react-app.dev/docs/making-a-progressive-web-app/
// serviceWorkerRegistration.unregister()
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // todo
    // alert('New version available!  Ready to update?')
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
})
