import _ from 'lodash'
import { v4 as uuid } from 'uuid'

// connectivity
export const APP_RECONNECT = 'appReconnect'

// localization
export const I18N_CHANGE_BEGIN = 'i18nChangeBegin'
export const I18N_CHANGE_END = 'i18nChangeEnd'

// keyboard actions
export const USER_TYPING = 'userTyping'
export const ARROW_UP = 'arrowUp'

// broadcast
export const BROADCAST_CREATED = 'broadcastCreated'
export const BROADCAST_UPDATED = 'broadcastUpdated'
export const BROADCAST_DELETED = 'broadcastDeleted'

// channel
export const CHANNELS_UPDATED = 'channelsUpdated'

// posts & messages
export const MESSAGE_UPDATED = 'messageUpdated'
export const MESSAGES_UPDATED = 'messagesUpdated'
export const POST_CREATED = 'postCreated'
export const POST_UPDATED = 'postUpdated'
export const POST_DELETED = 'postDeleted'

// badges
export const HAS_NEW_CHANNEL_ACTIVITY_CHANGED = 'hasNewChannelActivityChanged'
export const HAS_NEW_MESSAGES_CHANGED = 'hasNewMessagesChanged'
export const HAS_UNREAD_INBOX_ITEMS_CHANGED = 'hasUnreadInboxItemsChanged'
export const INBOX_ITEM_UPDATED = 'inboxItemUpdated'
export const HAS_NEW_TASK_ACTIVITY_CHANGED = 'hasNewTaskActivityChanged' // Old tasks
export const TASKS_ACTIVITY_CHANGED = 'tasksActivityChanged' // New tasks
export const BROADCAST_ACTIVITY_CHANGED = 'broadcastActivityChanged'

// user
export const CURRENT_USER_UPDATED = 'currentUserUpdated'
export const USER_USERLISTS_CHANGED = 'userUserListsChanged'

// toast
export const SHOW_TOAST = 'showToast'
export const READ_LINE_VISIBILITY_CHANGED = 'readLineVisibilityChanged'

// image
export const NEW_EDITED_IMAGE = 'newEditedImage'
export const EDITED_IMAGE_PRIORITY = {
  OPEN_COMMENTS: 0,
  OPEN_SOLO_VIEW: 1,
  POST_CREATION: 2,
}

// documents
export const DOCUMENT_CREATED = 'documentCreated'
export const DOCUMENT_DELETED = 'documentDeleted'
export const DOCUMENT_EDITED = 'documentEdited'

// tasks
export const TASK_UPDATED = 'taskUpdated'
export const TASK_ASSIGNMENT_UPDATED = 'taskAssignmentUpdated'
export const TASK_ERRORS_UPDATED = 'taskErrorsUpdated'
export const TASK_TEMPLATE_ERRORS = 'taskTemplateErrors'
export const TASK_DATA_EXPORT_JOB = 'taskDataExportJob'
export const TASK_DATA_EXPORT_JOB_COMPLETED = 'data_export_job_completed'
export const TASK_DATA_EXPORT_JOB_FAILED = 'data_export_job_failed'

// admin/sadmin
export const ADMIN_OBJECT_UPDATED = 'adminUpdated'

// workflow creation
export const WORKFLOW_ASSIGNEE_PLACES_UPDATED = 'workflowAssigneePlacesUpdated'
export const WORKFLOW_UPDATED = 'workflowUpdated'

// community updated
export const COMMUNITY_ID_UPDATED = 'communityIdUpdated'

export const dispatchEvent = (name, data, meta = {}) => {
  window.dispatchEvent(new CustomEvent(name, { detail: { data, meta } }))
}

const listenerMap = {}

export const addEventListener = (name, cb, priority = 0) => {
  const listener = (e) => {
    const { data, meta } = e.detail
    cb(data, meta)
  }
  listener.priority = priority
  listener.id = uuid()
  if (!listenerMap[name]) {
    listenerMap[name] = []
  }
  listenerMap[name].push(listener)
  if (priority > 0) {
    _.forEach(listenerMap[name], (eListener) => {
      window.removeEventListener(name, eListener)
    })
    listenerMap[name] = _.orderBy(listenerMap[name], ['priority'], ['desc'])
    _.forEach(listenerMap[name], (eListener) => {
      window.addEventListener(name, eListener)
    })
  } else {
    window.addEventListener(name, listener)
  }
  // console.log(listenerMap)
  return () => {
    listenerMap[name] = _.reject(listenerMap[name], { id: listener.id })
    window.removeEventListener(name, listener)
  }
}
