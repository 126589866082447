import { fokoud } from '../../../api'

export const FileService = {
  upload: (file, usage, onUploadProgress) => {
    const form = new FormData()
    form.append('file', file)
    const promise = Promise.resolve().then(() =>
      fokoud.post(`/files?usage=${usage}&uploadType=multipart`, form, {
        onUploadProgress: (e) => {
          if (promise.isCancelled()) {
            return
          }
          onUploadProgress(e)
        },
      })
    )
    return promise
  },
}
