import _ from 'lodash'
import moment from 'moment'
import { getCommunityId } from '../../core/models/session'
import {
  rawContenStateToMarkdown,
  markdownToRawContenState,
} from '../../../components/wysiwyg/utils/content-state'
import { StepType } from './step-type'
import { Community } from '../../core/models/community'
import { TasksService } from '../api/tasks'
import { DocumentService } from '../../core/api/document'
import {
  dispatchEvent,
  TASK_UPDATED,
  TASK_ASSIGNMENT_UPDATED,
  HAS_NEW_TASK_ACTIVITY_CHANGED,
} from '../../../events'
import { User } from '../../core/models/user'
import { Completion } from './completion'
import { CommunitiesService } from '../../core/api/community'
import Colors from '../../../styles/colors'

const MAX_TITLE_LENGTH = 255
const MAX_DESCRIPTION_LENGTH = 10000
const MAX_STEP_DESCRIPTION_LENGTH = 1000
const MAX_CHOICE_DESCRIPTION_LENGTH = 1000

let activityCounts

const isPrivate = (task) => _.get(task, 'visibility') === 'private'

const setIsPrivate = (task, isPrivate) => {
  task.visibility = isPrivate ? 'private' : null
}

const requiresApproval = (task) =>
  _.get(task, 'workflow.requireApproval', false)

const setRequiresApproval = (task, requiresApproval) => {
  _.set(task, 'workflow.requireApproval', requiresApproval)
}

const isActive = (task) => task.status === 'active'

const isCreator = (task) =>
  _.includes(task.roles, 'creator') || _.includes(task.userRoles, 'creator')
const isApprover = (task) =>
  _.includes(task.roles, 'approver') || _.includes(task.userRoles, 'approver')
const isManager = (task) => _.includes(task.userRoles, 'manager')
const isObserver = (task) => _.includes(task.userRoles, 'observer')
const isAssignee = (task, isStrict = false) => {
  const roleCheck =
    _.includes(task.userRoles, 'assignee') || _.includes(task.roles, 'assignee')
  if (!isStrict) {
    return roleCheck
  }
  return roleCheck && !isCreator(task) && !isManager(task) && !isObserver(task)
}

const getAssignment = (task, assignmentId) => {
  if (!isActive(task)) {
    return
  }
  if (!isAssignee(task, _.isNil(assignmentId))) {
    return
  }
  let completion
  if (assignmentId) {
    completion = _.find(task.tasks, { id: assignmentId })
  } else {
    if (_.size(task.tasks) > 1) {
      return
    }
    completion = _.first(task.tasks)
  }
  return completion
}

const redirectToCompletion = (task, assignmentId) => {
  const assignment = getAssignment(task, assignmentId)
  if (_.isNil(assignment)) {
    return false
  }
  return !_.includes(['inReview', 'completed'], assignment.status)
}

const isAssignmentNotStarted = (task, assignmentId) => {
  const assignment = getAssignment(task, assignmentId)
  return _.get(assignment, 'status') === 'active'
}

const canCompleteAgain = (task) =>
  task.multiComplete === true && isActive(task) && isAssignee(task)

const newTask = () => {
  const task = {}
  const settings = _.get(Community.getCurrent(), 'settings')
  if (_.get(settings, 'defaultPublicTask') === false) {
    setIsPrivate(task, true)
  }
  // listCreatorPrivateTaskPosts
  if (
    Community.isTaskApprovalEnabled() &&
    _.get(settings, 'defaultTaskRequireApproval') !== false
  ) {
    setRequiresApproval(task, true)
  }
  if (!Community.isTaskStructuresEnabled()) {
    task._isAssigningAdHoc = true
  }
  return task
}

const sanitize = (_task) => {
  const task = _.cloneDeep(_task)

  // todo santize choice _id

  task.description = rawContenStateToMarkdown(task._description)
  delete task._description

  _.forEach(task.steps, (step) => {
    if (step.type === StepType.NUMERIC) {
      step.type = StepType.TEXT
      _.set(step, 'subType.type', StepType.NUMERIC)
    }
    delete step._id
  })

  if (task._isAssigningAdHoc === true) {
    task.invitees = _.map(task._assignees, (assignee) => ({
      cause: 'projectAssignment',
      id: assignee.id,
      type: assignee.type === 'normal' ? 'user' : 'userList',
    }))
    // clean up workflow
    // e.g. loading template from task that used structures
    if (task.workflow) {
      delete task.workflow.assignmentMode
      delete task.workflow.assignmentModeMetadata
      delete task.workflow.assigneeSchemas
    }
  } else {
    delete task.invitees
    delete task.channelId
    const isCsvSubset = !_.isNil(task._csvData)
    _.set(
      task,
      'workflow.assignmentMode',
      isCsvSubset ? 'AssigneeSchemaSubset' : 'AssigneeSchema'
    )
    if (isCsvSubset) {
      const { data } = task._csvData
      _.set(task, 'workflow.assignmentModeMetadata', { data, type: 'csv' })
    } else {
      _.set(task, 'workflow.assignmentModeMetadata', null) // edit task with csv removal
    }
    _.set(
      task,
      'workflow.assigneeSchemas',
      _.map(task._structures, (structure) => ({
        id: structure.id,
      }))
    )
  }
  delete task._isAssigningAdHoc
  delete task._structures
  delete task._csvData
  delete task._assignees
  delete task._channel

  let observerString = ''
  task.mentions = _.map(task._observers, (observer) => {
    const fullName = User.fullName(observer)
    const startIndex = _.size(task.description) + _.size(observerString) + 1
    observerString += ` @${fullName}`
    return {
      id: observer.id,
      idType: observer.type === 'normal' ? 'User' : 'UserList',
      indices: [startIndex, startIndex + _.size(fullName) + 1],
    }
  })
  task._observerString = observerString
  delete task._observers

  //
  task.communityId = getCommunityId()
  task.type = 'imageSelection' // needed for some reason !?!?!

  delete task._documents
  delete task._images

  return task
}

const desanitize = (_task) => {
  const task = _.cloneDeep(_task)

  task._observers = _.map(task.mentions, (mention) => ({
    id: mention.id,
    type: mention.idType === 'User' ? 'normal' : 'userList',
    name: task.description.substring(
      mention.indices[0] + 1,
      mention.indices[1] + 1
    ),
  }))

  const mentionStartIndices = _.map(task.mentions, 'indices[0]')

  // strip out observers
  task.description = task.description.substring(
    0,
    Math.min(...mentionStartIndices)
  )

  task._description = markdownToRawContenState(task.description)

  _.forEach(task.steps, (step) => {
    step._description = markdownToRawContenState(step.description)
  })

  task._documents = task.documents

  if (_.get(task, 'workflow.assignmentMode') === 'AssigneeSchema') {
    task._structures = _.get(task, 'workflow.assigneeSchemas')
  } else if (
    _.get(task, 'workflow.assignmentMode') === 'AssigneeSchemaSubset'
  ) {
    task._structures = _.get(task, 'workflow.assigneeSchemas')
    task._csvData = _.get(task, 'workflow.assignmentModeMetadata')
  } else {
    task._isAssigningAdHoc = true
    task._assignees = _.compact(
      _.concat(
        _.map(task.inviteeUsers, (user) => _.merge(user, { type: 'normal' })),
        task.inviteeUserLists
      )
    )
  }

  return task
}

// check out the logic to remove dates lol!
const loadFromTemplate = (template) => {
  const task = _.omitBy(
    _.omit(template, [
      'id',
      'postId',
      'status',
      'statusDetails',
      'userId',
      'version',
    ]),
    (value, key) => _.endsWith(key, 'At')
  )
  task.isTemplate = false
  return task
}

const Errors = {
  NO_TITLE: 0,
  NO_DESCRIPTION: 1,
  DESCRIPTION_TOO_LONG: 2,
  NO_STEPS: 3,
  STEP_MISSING_DESCRIPTION: 4,
  STEP_DESCRIPTION_TOO_LONG: 5,
  CHOICE_MISSING_DESCRIPTION: 6,
  DUE_BEFORE_START: 7,
  END_BEFORE_START: 8,
  END_BEFORE_DUE: 9,
  NO_ASSIGNEES: 10,
  NO_ARCHIVE_DATE: 11,
  NO_DUE_DATE: 12,
}

const isMissingChoiceDescription = (step) =>
  _.some(step.choices, (choice) => _.isEmpty(_.trim(choice.description)))

const getStartDate = (task) => task.startAt || new Date()

const checkForErrors = (task) => {
  const errors = []
  if (_.isEmpty(_.trim(task.name))) {
    errors.push(Errors.NO_TITLE)
  }
  if (_.isNil(task.description)) {
    errors.push(Errors.NO_DESCRIPTION)
  }
  if (_.get(task, 'description.length') > MAX_DESCRIPTION_LENGTH) {
    errors.push(Errors.DESCRIPTION_TOO_LONG)
  }
  if (_.isEmpty(task.steps)) {
    errors.push(Errors.NO_STEPS)
  }
  _.forEach(task.steps, (step) => {
    if (_.isEmpty(_.trim(step.description))) {
      errors.push(Errors.STEP_MISSING_DESCRIPTION)
    }
  })
  _.forEach(task.steps, (step) => {
    if (_.size(step.description) > MAX_STEP_DESCRIPTION_LENGTH) {
      errors.push(Errors.STEP_DESCRIPTION_TOO_LONG)
    }
  })
  _.forEach(_.filter(task.steps, { type: StepType.SURVEY }), (step) => {
    if (isMissingChoiceDescription(step)) {
      errors.push(Errors.CHOICE_MISSING_DESCRIPTION)
    }
  })
  if (_.isEmpty(_.trim(task.endAt))) {
    // errors.push(Errors.NO_ARCHIVE_DATE)
  }
  if (
    task.dueAt &&
    getStartDate(task) &&
    !moment(task.dueAt).isAfter(getStartDate(task))
  ) {
    errors.push(Errors.DUE_BEFORE_START)
  }
  if (
    task.endAt &&
    getStartDate(task) &&
    !moment(task.endAt).isAfter(getStartDate(task))
  ) {
    errors.push(Errors.END_BEFORE_START)
  }
  if (task.endAt && task.dueAt && !moment(task.endAt).isAfter(task.dueAt)) {
    errors.push(Errors.END_BEFORE_DUE)
  }
  if (
    _.isEmpty(task.invitees) &&
    _.isEmpty(_.get(task, 'workflow.assigneeSchemas'))
  ) {
    errors.push(Errors.NO_ASSIGNEES)
  }
  if (!task.dueAt) {
    errors.push(Errors.NO_DUE_DATE)
  }
  return errors
}

const createUpdate = (sanitizedTask, images = [], documents = []) => {
  const [documentsToUpload, uploadedDocuments] = _.partition(
    _.concat(images, documents),
    (document) => _.isNil(document.fileId)
  )
  const newDocumentFileIds = _.map(documentsToUpload, 'id')
  const documentIds = _.map(uploadedDocuments, 'id')

  const addDocuments = () =>
    Promise.mapSeries(newDocumentFileIds, (fileId) =>
      DocumentService.create({
        fileId,
        communityId: getCommunityId(),
      }).then((document) => documentIds.push(document.id))
    )

  const createUpdate = sanitizedTask.id
    ? TasksService.update
    : TasksService.create

  const updatedTask = _.clone(sanitizedTask)
  updatedTask.documentIds = documentIds
  delete updatedTask.documents // let the documentIds control this

  updatedTask.description += updatedTask._observerString
  delete updatedTask._observerString

  return addDocuments().then(() => createUpdate(updatedTask))
}

const canApprove = (task = {}) => task.status === 'active' && isApprover(task)

const isUsingStructures = (task) => {
  const assignmentMode = _.get(task, 'workflow.assignmentMode')
  return (
    assignmentMode === 'AssigneeSchema' ||
    assignmentMode === 'AssigneeSchemaSubset'
  )
}

const isRecurring = (task) => !_.isNil(_.get(task, 'recurrence'))
const isRecurrenceActive = (task) => _.get(task, 'recurrence.isActive') === true

const isPending = (task) => moment(task.startAt).isAfter(new Date())
const isPastDue = (task) => task.dueAt && moment(new Date()).isAfter(task.dueAt)
const isDueToday = (task) =>
  task.dueAt &&
  moment(new Date()).startOf('day').isSame(moment(task.dueAt).startOf('day'))

const isThisWeek = (task) =>
  task.dueAt &&
  moment(task.dueAt).isBetween(
    moment().startOf('isoWeek'),
    moment().endOf('isoWeek')
  )

const statusCount = (task, status) => {
  if (isPending(task)) {
    return '-'
  }

  if (status === 'needsChanges') {
    return _.get(task, 'statusDetails.totalNeedsChangesTasks')
  }
  if (status === 'inReview') {
    return _.get(task, 'statusDetails.totalInReviewTasks')
  }
  if (status === 'completed') {
    return _.get(task, 'statusDetails.totalCompletedTasks')
  }
  if (status === 'inProgress') {
    return _.get(task, 'statusDetails.totalInProgressTasks')
  }
  // not started
  return _.get(task, 'statusDetails.totalNotStartedAssignees')
}

const canEdit = (task) => isCreator(task) && task.status !== 'closed'

const afterClose = (task) => {
  dispatchEvent(TASK_UPDATED, _.cloneDeep(task))
}

const afterDraftCreated = (task, draft) => {
  const updatedTask = _.cloneDeep(task)
  updatedTask.tasks.push(draft)
  dispatchEvent(TASK_UPDATED, updatedTask)
}

const afterDraftSaveOrSubmit = (task, completion) => {
  const updatedTask = _.cloneDeep(task)
  const index = _.findIndex(updatedTask.tasks, { id: completion.id })
  if (index >= 0) {
    updatedTask.tasks[index] = completion
    dispatchEvent(TASK_UPDATED, updatedTask)
  }
}

const afterCompletionDeleted = (task, completion) => {
  const updatedTask = _.cloneDeep(task)
  updatedTask.tasks = _.reject(updatedTask.tasks, { id: completion.id })
  dispatchEvent(TASK_UPDATED, updatedTask)
}

const afterReview = (task, assignment, type) => {
  const updatedTask = _.cloneDeep(task)
  const { statusDetails } = updatedTask
  const { totalCompletedTasks, totalNeedsChangesTasks, totalInReviewTasks } =
    statusDetails
  if (type === Completion.COMPLETED) {
    _.set(statusDetails, 'totalCompletedTasks', totalCompletedTasks + 1)
    if (assignment.status === 'inReview') {
      _.set(statusDetails, 'totalInReviewTasks', totalInReviewTasks - 1)
    } else {
      _.set(statusDetails, 'totalNeedsChangesTasks', totalNeedsChangesTasks - 1)
    }
  } else {
    if (assignment.status === 'inReview') {
      _.set(statusDetails, 'totalInReviewTasks', totalInReviewTasks - 1)
    } else {
      _.set(statusDetails, 'totalCompletedTasks', totalCompletedTasks - 1)
    }
    _.set(statusDetails, 'totalNeedsChangesTasks', totalNeedsChangesTasks + 1)
  }
  dispatchEvent(TASK_UPDATED, updatedTask)
  // now handle the assignment
  const updatedAssignment = _.cloneDeep(assignment)
  _.set(
    updatedAssignment,
    'status',
    type === Completion.COMPLETED ? 'completed' : 'needsChanges'
  )
  dispatchEvent(TASK_ASSIGNMENT_UPDATED, updatedAssignment)
}

const setActivityCounts = (activity) => {
  activityCounts = activity
  dispatchEvent(HAS_NEW_TASK_ACTIVITY_CHANGED)
}

const updateActivityCounts = () => {
  CommunitiesService.badges(false, false, true).then((res) => {
    setActivityCounts(res.tasks)
  })
}

const dashboardReviewData = (task) => {
  const approvedOrCompleted = statusCount(task, 'completed')
  const needsChanges = requiresApproval(task)
    ? statusCount(task, 'needsChanges')
    : 0
  const inReview = requiresApproval(task) ? statusCount(task, 'inReview') : 0
  const inProgress = statusCount(task, 'inProgress')
  const notStarted = statusCount(task, 'notStarted')

  const complete = _.sum([approvedOrCompleted, needsChanges, inReview])
  const notComplete = _.sum([inProgress, notStarted])
  const total = complete + notComplete

  let items = [
    {
      color: Colors.darkGreen,
      iconName: 'check-circle',
      label: 'complete',
      total,
      value: complete,
      isSubGroup: false,
    },
  ]
  if (requiresApproval(task)) {
    items.push({
      color: Colors.darkGreen,
      iconName: 'check-starcircle',
      label: 'approved',
      total,
      value: approvedOrCompleted,
      isSubGroup: true,
    })
    items.push({
      color: Colors.darkYellow,
      iconName: 'exclamation-circle',
      label: 'needsChanges',
      total,
      value: needsChanges,
      isSubGroup: true,
    })
    items.push({
      color: Colors.red,
      iconName: 'binocular',
      label: 'inReview',
      total,
      value: inReview,
      isSubGroup: true,
    })
  } else {
    items.push({
      color: Colors.darkGreen,
      iconName: 'check-circle',
      label: 'complete',
      total,
      value: complete,
      isSubGroup: true,
    })
  }
  items = _.concat(items, [
    {
      color: Colors.darkGrey,
      iconName: 'no-entry',
      label: 'notComplete',
      total,
      value: notComplete,
      isSubGroup: false,
    },
    {
      color: Colors.lightGrey,
      iconName: 'tab-horizontal',
      label: 'inProgress',
      total,
      value: inProgress,
      isSubGroup: true,
    },
    {
      color: Colors.darkGrey,
      iconName: 'yield-symbol',
      label: 'notStarted',
      total,
      value: notStarted,
      isSubGroup: true,
    },
  ])
  return items
}

export const Task = {
  newTask,
  requiresApproval,
  canEdit,
  isActive,
  redirectToCompletion,
  isAssignmentNotStarted,
  canCompleteAgain,
  isCreator,
  isManager,
  isObserver,
  isAssignee,
  setRequiresApproval,
  isPrivate,
  setIsPrivate,
  sanitize,
  desanitize,
  loadFromTemplate,
  checkForErrors,
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_STEP_DESCRIPTION_LENGTH,
  MAX_CHOICE_DESCRIPTION_LENGTH,
  Errors,
  createUpdate,
  canApprove,
  isUsingStructures,
  isRecurring,
  isRecurrenceActive,
  isPending,
  isPastDue,
  isDueToday,
  isThisWeek,
  statusCount,
  afterClose,
  afterDraftCreated,
  afterDraftSaveOrSubmit,
  afterCompletionDeleted,
  afterReview,
  setActivityCounts,
  getActivityCount: () => activityCounts,
  updateActivityCounts,
  dashboardReviewData,
}
