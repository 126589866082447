import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { Branding } from './branding'

/**
 * Consolidates base styles with any
 * existing branding styles
 * @param {object|undefined} defaultStyles - an object representing
 *  the default, unbranded styles (JSS) of a component
 * @param {object|undefined} branding - an object representing
 *  custom branding data
 * @param  {string} styleKey - JSS object key (i.e., 'auth', 'rail', 'content')
 *
 * @return {object} a consolidated (deeply merged) JSS style object
 */
export const getConsolidatedStyles = (defaultStyles, branding, styleKey) => {
  if (!branding) {
    return defaultStyles
  }

  // clone so as not to mutate defaultStyles during merge
  const clonedStyles = cloneDeep(defaultStyles)
  const theme = Branding.theme(branding, 'web')
  // custom JSS styles provided by branded theme configuration
  const brandedStyles = get(theme, `styles.${styleKey}`, {})
  // deeply merge default and branded styles
  return merge(clonedStyles, brandedStyles)
}
