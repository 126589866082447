/**
 * The debounce time (in milliseconds) between when a user starts typing and an action
 */
export const TYPING_TIMEOUT = 200
export const TERMS_AND_CONDITIONS_URL =
  'https://content.fokoretail.com/legal/terms.html'
export const PRIVACY_POLICY_URL = 'https://fokoretail.com/privacy-policy/ '
export const FAQ_URL = 'https://foko.zendesk.com/hc/en-us/ '
export const DEFAULT_BRANDING_NAME = 'Workforce Experience'
export const LEGACY_API_URL = 'https://api.foko.io'
export const WFS_PARENT_ORIGIN_PATTERN =
  /(localhost(:\d*)?)$|(.wfs.cloud|.foko-services.com|.vanguard-review-apps.ue.r.appspot.com)$|^(file:\/\/)|(^https:\/\/lth-pr-\d{4}-dot-foko-dev.uc.r.appspot.com)/
