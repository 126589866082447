import {
  htmlToContentState,
  rawContentStateToHtml,
} from '../components/wysiwyg/utils/content-state'

/**
 * Removes &nbsp; characters in a string
 * @param {string} string The string with &nbsp; characters
 * @return {string} The string with &nbsp; tags removed
 */
export const removeNbsp = (string) => {
  return string?.replace(/&nbsp;/g, '')
}

/**
 * Replaces HTML tags in a string by an empty string
 * @param {string} string The string with HTML characters
 * @return {string} The string with HTML tags removed
 */
export const removeHtmlTags = (string) => {
  if (string?.length === 0 || !string) {
    return ''
  }
  const str = removeNbsp(string.toString())
  const div = document.createElement('div')
  div.innerHTML = str
  return div.textContent || div.innerText || ''
}

/**
 * Converts and returns a clean HTML by removing extra and not needed tags
 * @param {string} _description The raw element to be converted to HTML
 * @return {*} The HTML conversion or null if the content is empty
 */
export const getCleanHTMLConversion = (_description) => {
  // convert raw description to HTML and generates the first HTML
  const htmlContent = rawContentStateToHtml(_description)

  // htmlContent might have extra and not needed HTML elements like <p>, <br>
  // Convert HTML back to raw in order to clean the extra elements generated by the first conversion
  const rawContent = htmlToContentState(htmlContent)

  // rawContent returns a clean raw content
  // Convert rawContent to the final and clean HTML
  const rawToHtml = rawContentStateToHtml(rawContent)
  // Verify if the content is empty by removing all HTML tags
  const htmlRemoved = removeHtmlTags(rawToHtml)?.trim()

  // Return null if content is empty otherwhise return the clean HTML
  // This allows to stop sending empty <p> tags generated when there is no content.
  return htmlRemoved?.length === 0 ? null : removeNbsp(rawToHtml)?.trim()
}
