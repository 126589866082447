import { Session } from '../modules/core/models/session'
import { Routes } from '../routes'
import { UserService } from '../modules/core/api/user'
import { CommunitiesService } from '../modules/core/api/community'

/**
 * Sets the community Id for the user's session
 */
export const loadCommunities = async () => {
  if (!Session.getUser()) {
    return
  }
  try {
    /** Sync user only for bare routes
     * Right now, User data is required to enable the broadcast privileges for channels - Broadcast screen and tasks.
     * Moreover in the future, it will be required to be synced for admin, profile, dashboard etc etc screens.
     * So it is good to sync User data initially and use it whenever it is required. */
    if (
      window.location.pathname.includes(Routes.BARE.ROOT) &&
      (!Session.getUser()?.wfsId || !Session.getUser()?.privileges)
    ) {
      const user = await UserService.getUser(Session.getUser())
      Session.updateUser(user)
    }
    // Communities is a property of user so fill in after user
    const communities = await CommunitiesService.get()
    Session.setCommunityId(communities[0]?.id)
    Session.setCommunities(communities)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
