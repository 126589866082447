import axios from 'axios'
import constant from 'lodash/constant'
import has from 'lodash/has'
import includes from 'lodash/includes'
import { Session, getAuthToken } from '../../modules/core/models/session'
import { Config } from '../../config'

export const RESOLVER_API_ROUTE = '/resolver/organizations/'
export const USER_LANGUAGES_ROUTE = '/users/languages'

/**
 * @description Returns the correct request baseURL
 * USER_LANGUAGES_ROUTE always use the default api host stored in the env file in use
 * RESOLVER_API_ROUTE always use the default api host stored in the env file in use but without the /v1
 * Other requests use the baseURL stored in the local storage if there is any, otherwhise use the default api host configuration
 * @param {object} request Request intercepted on fokoServices
 * @return {string} Returns the baseURL to use in the services API requests
 */
const getBaseUrl = (request) => {
  const defaultApiHost = `${Config.apiHost()}/v1`
  const sessionApiHost = `${Session.getBaseApiUrl()}/v1`

  if (request?.url === USER_LANGUAGES_ROUTE) {
    return defaultApiHost
  }
  if (request?.url?.includes(RESOLVER_API_ROUTE)) {
    return Config.apiHost()
  }
  return Session.getBaseApiUrl() ? sessionApiHost : defaultApiHost
}

export const fokoServices = axios.create()

export const resetFokoServicesHeaders = (
  authToken = getAuthToken(),
  accessToken = Session.getServicesSessionData()?.accessToken
) => {
  const { userId } = Session.getServicesSessionData()
  fokoServices.defaults.headers.common.Authorization = authToken
  fokoServices.defaults.headers.Pragma = 'no-cache'
  fokoServices.defaults.headers.common['access-token'] = accessToken
  fokoServices.defaults.headers.common['principal-user'] = userId
}

resetFokoServicesHeaders()

fokoServices.defaults.validateStatus = constant(true)

const handleError = (response) => {
  const err = response?.data?.errors
  if (has(err, 'AUTHENTICATION_REQUIRED')) {
    Session.onLogout(true)
  }
  return Promise.reject(response)
}

const handleResponse = (response) => {
  if (response?.data?.error) {
    return Promise.reject(response)
  }
  if (response?.data?.errors) {
    return handleError(response)
  }
  if (includes(response?.config?.url, '/login/')) {
    return response
  }
  if (response?.status === 404 || response.status === 500) {
    return Promise.reject(response)
  }
  return response
}

/**
 * @description Intercept request to ensure correct baseURL is being used (multi-region support)
 * @return {object} Returns the merged request object using the corresponding baseURL
 */
fokoServices.interceptors.request.use((request) => ({
  ...request,
  ...{
    baseURL: getBaseUrl(request),
  },
}))

fokoServices.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
)
