import _ from 'lodash'
import { useState, useEffect, useContext } from 'react'
import { RouteContext } from './contexts'
import { addEventListener, dispatchEvent } from './events'
import { Routes } from './routes'
import {
  SELECTED_MESSAGE,
  IS_NAVIGATION_MENU_MINIMIZED,
  PREPOPULATE_MESSAGE_ID,
  getLocalStorageItem,
} from './utils/local-storage'
import { Router } from './utils/router'
import { loadCommunities } from './utils/community'

export const APPLICATION_STATE = {
  IS_DARK: 'isDark',
  TITLE: 'title',
  NAVIGATION_STATE: 'navigationState',
  IS_NAVIGATION_MENU_MINIMIZED: 'isNavigationMenuMinimized',
  IS_TASK_MENU_MINIMIZED: 'isTaskMenuMinimized',
  REVEAL_STATE: 'revealState',
  IS_REVEAL_MINIMIZED: 'isRevealMinimized',
  CURRENT_CHANNEL: 'currentChannel',
  CURRENT_TASK: 'currentTask',
  NOTIFICATION_STATE: 'notificationState',
}

export const NAVIGATION_STATE = {
  ROOT: 'root',
  CHANNELS: 'channels',
  ADMIN: 'admin',
  SADMIN: 'sadmin',
  TASK: 'task',
  CREATION: 'creation',
}

export const REVEAL_STATE = {
  MESSAGES: 'messages',
}

export const useStoredState = (key, defaultValue) => {
  const [state, setState] = useState(getLocalStorageItem(key, defaultValue))
  return [
    state,
    (newState) => {
      localStorage.setItem(key, JSON.stringify({ value: newState }))
      setState(newState)
    },
  ]
}

const applicationState = {
  // theme
  isDark: false,
  // navigation
  title: '',
  navigationState: NAVIGATION_STATE.ROOT,
  isNavigationMenuMinimized: getLocalStorageItem(
    APPLICATION_STATE.IS_NAVIGATION_MENU_MINIMIZED,
    false
  ),
  revealState: getLocalStorageItem(
    APPLICATION_STATE.REVEAL_STATE,
    REVEAL_STATE.MESSAGES
  ),
  isRevealMinimized: getLocalStorageItem(
    APPLICATION_STATE.IS_REVEAL_MINIMIZED,
    true
  ),
  notificationState: getLocalStorageItem(APPLICATION_STATE.NOTIFICATION_STATE),
  isTaskMenuMinimized: true,
}

const dispatchStateUpdate = (key, newState) => {
  const eventName = `${key}Updated`
  applicationState[key] = newState
  return dispatchEvent(eventName, newState)
}

const useApplicationState = (key, isStoredState = false) => {
  const eventName = `${key}Updated`
  const [state, setState] = isStoredState
    ? useStoredState(key) // eslint-disable-line
    : useState(applicationState[key]) // eslint-disable-line
  useEffect(() => {
    if (state !== applicationState[key]) {
      setState(applicationState[key])
    }
    return addEventListener(eventName, (newState) => {
      setState(newState)
    })
  }, [eventName, key, setState, state])
  return [state, (newState) => dispatchStateUpdate(key, newState)]
}

export const ApplicationState = {
  useIsDark: () => useApplicationState(APPLICATION_STATE.IS_DARK, true),
  useTitle: () => useApplicationState(APPLICATION_STATE.TITLE),
  setTitle: (title) => dispatchStateUpdate(APPLICATION_STATE.TITLE, title),
  useTaskMenuMinimized: () =>
    useApplicationState(APPLICATION_STATE.IS_TASK_MENU_MINIMIZED),
  setTaskMenuMinimized: (isTaskMenuMinimized) =>
    dispatchStateUpdate(
      APPLICATION_STATE.IS_TASK_MENU_MINIMIZED,
      isTaskMenuMinimized
    ),
  useNavigationState: () =>
    useApplicationState(APPLICATION_STATE.NAVIGATION_STATE),
  getNavigationState: () =>
    applicationState[APPLICATION_STATE.NAVIGATION_STATE],
  setNavigationState: (navigationState) =>
    dispatchStateUpdate(APPLICATION_STATE.NAVIGATION_STATE, navigationState),
  useIsNavigationMenuMinimized: () =>
    useApplicationState(IS_NAVIGATION_MENU_MINIMIZED, true),
  setIsNavigationMenuMinimized: (isNavigationMenuMinimized) =>
    dispatchStateUpdate(
      APPLICATION_STATE.IS_NAVIGATION_MENU_MINIMIZED,
      isNavigationMenuMinimized
    ),
  useRevealState: () =>
    useApplicationState(APPLICATION_STATE.REVEAL_STATE, true),
  useIsRevealMinimized: () =>
    useApplicationState(APPLICATION_STATE.IS_REVEAL_MINIMIZED, true),
  useSelectedMessage: () => useApplicationState(SELECTED_MESSAGE),
  setSelectedMessage: (message) =>
    dispatchStateUpdate(SELECTED_MESSAGE, message),
  usePrepopulateMessageId: () => useApplicationState(PREPOPULATE_MESSAGE_ID),
  setPrepopulateMessageId: (id) =>
    dispatchStateUpdate(PREPOPULATE_MESSAGE_ID, id),
  useSendNewMessage: () => {
    const routeCtx = useContext(RouteContext)
    const [, setIsNavigationMenuMinimized] =
      ApplicationState.useIsRevealMinimized()
    const [, setRevealState] = ApplicationState.useRevealState()
    const [, setSelectedMessage] = ApplicationState.useSelectedMessage()
    return (user) => {
      setIsNavigationMenuMinimized(false)
      setRevealState(REVEAL_STATE.MESSAGES)
      setSelectedMessage(user)
      if (routeCtx.isBare) {
        Router.routeTo(Routes.MESSAGES.ROOT)
      }
    }
  },
  useCurrentChannel: () =>
    useApplicationState(APPLICATION_STATE.CURRENT_CHANNEL),
  setCurrentChannel: (channel) =>
    dispatchStateUpdate(
      APPLICATION_STATE.CURRENT_CHANNEL,
      _.cloneDeep(channel)
    ),
  useCurrentTask: () => useApplicationState(APPLICATION_STATE.CURRENT_TASK),
  setCurrentTask: (task) =>
    dispatchStateUpdate(APPLICATION_STATE.CURRENT_TASK, _.cloneDeep(task)),
  useLoadCommunities: () => {
    const [isCommunityLoaded, setIsCommunityLoaded] = useState(false)
    useEffect(() => {
      ;(async () => {
        try {
          await loadCommunities()
          setIsCommunityLoaded(true)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
      })()
    }, [])
    return isCommunityLoaded
  },
  useDisplayMessages: () => {
    const routeCtx = useContext(RouteContext)
    const [, setRevealState] = ApplicationState.useRevealState()
    const [, setIsRevealMinimized] = ApplicationState.useIsRevealMinimized()
    const [, setSelectedMessage] = ApplicationState.useSelectedMessage()
    return (shouldDisplay) => {
      if (shouldDisplay) {
        setSelectedMessage(undefined)
        if (routeCtx.isBare) {
          Router.routeTo(Routes.BARE.MESSAGES)
        } else {
          setRevealState(REVEAL_STATE.MESSAGES)
          setIsRevealMinimized(false)
        }
      } else {
        setRevealState(undefined)
        setIsRevealMinimized(true)
      }
    }
  },
  setNotificationState: (notificationState) =>
    dispatchStateUpdate(
      APPLICATION_STATE.NOTIFICATION_STATE,
      notificationState
    ),
  useNotificationState: () =>
    useApplicationState(APPLICATION_STATE.NOTIFICATION_STATE, true),
}
