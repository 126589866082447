import _ from 'lodash'
import moment from 'moment'
import {
  dispatchEvent,
  MESSAGE_UPDATED,
  MESSAGES_UPDATED,
  HAS_NEW_MESSAGES_CHANGED,
} from '../../../events'
import { Post } from './post'
import { MessageService } from '../api/message'

let messages
let hasNewActivity

const setHasNewActivity = (_hasNewActivity) => {
  const hadNewActivity = hasNewActivity
  hasNewActivity = _hasNewActivity
  if (_.size(hadNewActivity) !== _.size(hasNewActivity)) {
    dispatchEvent(HAS_NEW_MESSAGES_CHANGED)
  }
}

const get = () => messages

const set = (newMessages) => {
  messages = _.orderBy(
    newMessages,
    [
      (message) => message.hasNewActivity || false,
      (message) => message.postCreatedAt || '',
    ],
    ['desc', 'desc']
  )
  dispatchEvent(MESSAGES_UPDATED, messages)
  setHasNewActivity(
    _.compact(
      _.map(messages, (message) =>
        message.hasNewActivity ? message.id : undefined
      )
    )
  )
}

const getById = (id) => _.find(messages, { id })

const add = (newMessage) => {
  if (!messages) {
    // impossible!
    return
  }
  const message = getById(newMessage.id)
  if (!message) {
    messages.push(newMessage)
    set(messages)
  }
}

const onCheckpointed = (messageId) => {
  if (!messages) {
    // impossible!
    return
  }
  const message = getById(messageId)
  if (message) {
    message.hasNewActivity = false
    set(messages)
  }
}

const afterPostCreated = (post) => {
  const message = getById(post.channelId)
  _.set(message, 'postCreatedAt', moment().utc().format())
  set(messages)
}

const handlePostCreated = (post) => {
  if (Post.isOwner(post)) {
    return
  }
  if (!messages) {
    setHasNewActivity(_.union(hasNewActivity || [], [post.channelId]))
    return
  }
  const message = getById(post.channelId)
  if (message) {
    message.hasNewActivity = true
    afterPostCreated(post)
  } else {
    // let's add it!
    MessageService.get(post.channelId).then((message) => {
      message.hasNewActivity = true
      // unionBy to prevent duplicates
      // e.g. if many quick messages in a new thread
      // (ideally could track this and make sure ever only do 1 Message.get per thread, but a bit overkill)
      set(_.unionBy([message], messages, 'id'))
    })
  }
}

const onUpdated = (updatedMessage) => {
  if (!messages) {
    return
  }
  const index = _.findIndex(messages, { id: updatedMessage.id })
  if (index >= 0) {
    messages[index] = _.merge(messages[index], updatedMessage)
    dispatchEvent(MESSAGE_UPDATED, _.cloneDeep(messages[index]))
  }
}

export const Messages = {
  get,
  set,
  getNewMessageCount: () => _.size(hasNewActivity),
  setHasNewActivity,
  add,
  onCheckpointed,
  handlePostCreated,
  afterPostCreated,
}

export const Message = {
  onUpdated,
}
