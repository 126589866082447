import _ from 'lodash'
import { Config } from '../config'
import { Routes } from '../routes'
import { Session } from '../modules/core/models/session'
import { LEGACY_API_URL } from '../constants'

export const isInternalLink = (link) => {
  if (_.includes(link, window.location.origin)) {
    return true
  }
  if (
    _.includes(link, Config.apiHost()) ||
    _.includes(link, LEGACY_API_URL) ||
    (Session.getBaseApiUrl() && _.includes(link, Session.getBaseApiUrl()))
  ) {
    return _.includes(link, '/launch/') || _.includes(link, '/deeplink/')
  }
  return false
}

const stripOriginFromLink = (link) => {
  if (!URL.canParse(link)) {
    return link
  }
  const url = new URL(link)
  return _.replace(url.toString(), url.origin, '')
}

export const deeplinkToRoute = (link = '', origin = '') => {
  let route = stripOriginFromLink(link)
  route = _.replace(route, window.location.origin, '')
  route = _.replace(route, '/launch/', '/')
  route = _.replace(route, '/deeplink/', '/')
  if (_.includes(route, '/projects/')) {
    route = _.replace(route, '/task/', '/assignments/')
    route = _.replace(route, '/projects/', '/task/')
  }
  if (route.includes('/folders/')) {
    const folderId = route.split('/').at(-1)
    return `${
      route.includes(Routes.BARE.ROOT) ? Routes.BARE.ROOT : ''
    }/documents/folders/${folderId}`
  }
  return (
    _.trimEnd(origin, '/') + (_.startsWith(route, '/') ? route : `/${route}`)
  )
}
