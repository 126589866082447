import _ from 'lodash'
import { fokoud } from '../../../api'
import { getCommunityId } from '../models/session'
import { DEFAULT_LIMIT } from './constants'
import { Messages, Message } from '../models/message'

let messagesPromise
export const MessagesService = {
  get: (force = false) => {
    const messages = Messages.get()
    if (messages && !force) {
      return Promise.resolve(messages)
    }
    if (!messagesPromise) {
      messagesPromise = fokoud
        .get('/users/dms', {
          params: {
            filter: {
              where: {
                communityId: getCommunityId(),
              },
            },
          },
        })
        .then((response) => {
          Messages.set(response)
          return response
        })
        .finally(() => {
          messagesPromise = undefined
        })
    }
    return messagesPromise
  },
  isFetchingOrHasBeenFetched: () =>
    !_.isNil(Messages.get()) || !_.isNil(messagesPromise),
  search: (query, hideDisabled) =>
    fokoud.get(`/communities/${getCommunityId()}/searchDms`, {
      params: {
        q: query,
        hideDisabled,
      },
    }),
  new: (userIds) =>
    Promise.resolve().then(() =>
      fokoud // cancelable
        .post('/channels', {
          type: 'dm',
          communityId: getCommunityId(),
          userIds,
        })
    ),
  cloneDmChannel: async (channelId) => {
    const response = await fokoud.post('/channels/cloneDmChannel', {
      channelId,
    })
    return response
  },
}

export const MessageService = {
  FEED_LIMIT: DEFAULT_LIMIT,
  feed: (messageId, olderThan) =>
    fokoud.get(`/channels/${messageId}/feed`, {
      params: {
        'filter[limit]': MessageService.FEED_LIMIT,
        'filter[olderThan]': olderThan,
      },
    }),
  rename: (messageId, name) =>
    fokoud.put(`/channels/${messageId}`, { name }).then((message) => {
      Message.onUpdated(message)
      return message
    }),
  checkpoint: (messageId, timestamp) =>
    fokoud
      .post(`/channels/${messageId}/checkpoint`, { timestamp })
      .then((response) => {
        Messages.onCheckpointed(messageId)
        return response
      }),
  get: (id) => fokoud.get(`/channels/${id}`),
}
