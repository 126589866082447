import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { Session } from '../modules/core/models/session'
import { Community } from '../modules/core/models/community'
import { Config } from '../config'

export const ErrorHandler = {
  init: () => {
    const currentCommunityId = _.get(Community.getCurrent(), 'id')
    const dsn = Config.sentryDSN()

    const setSentryUser = (user, communityId) => {
      Sentry.setTag('communityId', communityId)
      if (user) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.id,
            email: _.head(user.emails),
          })
        })
      }
    }

    if (dsn && !Config.isDevelopment()) {
      const config = {
        dsn,
        environment: Config.buildType(),
        dist: Config.appId(),
        release: Config.appVersion(),
        beforeSend: (event, hint) => {
          const eventClone = event
          const error = hint.originalException
          if (error && error.message) {
            // ignore events
            if (error.message === 'NS_ERROR_UNEXPECTED') {
              // wtf is this?...affecting 1 user
              return null
            }
            if (error.message === 'Network Error') {
              return null
            }
            if (
              _.includes(error.message, 'Loading chunk') ||
              _.includes(error.message, 'Loading CSS chunk')
            ) {
              return null
            }
            if (error.message === 'ResizeObserver loop limit exceeded') {
              return null
            }
            if (
              error.message ===
              // eslint-disable-next-line max-len
              'Non-Error promise rejection captured with keys: config, data, headers, request, status'
            ) {
              return null
            }
            // aggregate events
            if (error.message === 'timeout of 0ms exceeded') {
              eventClone.fingerprint = ['timeout of 0ms exceeded']
            } else if (error.message === 'Request aborted') {
              eventClone.fingerprint = ['Request aborted']
            } else if (
              error.message === 'Request failed with status code 401'
            ) {
              eventClone.fingerprint = ['Request failed with status code 401']
            } else if (
              error.message === 'Request failed with status code 404'
            ) {
              eventClone.fingerprint = ['Request failed with status code 404']
            } else if (
              error.message === 'Request failed with status code 500'
            ) {
              eventClone.fingerprint = ['Request failed with status code 500']
            } else if (
              error.message === 'Request failed with status code 503'
            ) {
              eventClone.fingerprint = ['Request failed with status code 503']
            }
          }
          return eventClone
        },
      }

      Sentry.init(config)
      setSentryUser(Session.getUser(), currentCommunityId)
    }
  },
  // error: (error) => {
  //   Sentry.captureException(error)
  // },
  warning: (error) => {
    Sentry.captureMessage(error, 'warning')
  },
}
