import _ from 'lodash'
import { fokoud } from '../../../api'
import { getCommunityId, Session } from '../models/session'
import { Post } from '../models/post'
import { DEFAULT_LIMIT } from './constants'

const reactors = (mode, postId, skip) => {
  const params = {
    'filter[limit]': DEFAULT_LIMIT,
    'filter[skip]': skip,
    'filter[include][owner]': 'avatar',
    'filter[order]': 'fullName',
  }
  return fokoud.get(`/posts/${postId}/${mode}`, {
    params,
  })
}

export const PostService = {
  MAX_CONTENT_LENGTH: 10000,
  get: (postId) => fokoud.get(`/posts/${postId}`),
  like: (postId, isLike) =>
    fokoud.post(`/posts/${postId}/${isLike ? 'like' : 'unlike'}`),
  delete: (postId) => fokoud.delete(`/posts/${postId}`),
  viewers: (postId, skip) => reactors('viewers', postId, skip),
  likes: (postId, skip) => reactors('likes', postId, skip),
  markAsRead: (postId) =>
    fokoud.post(`/posts/${postId}/mark`, {
      type: 'read',
    }),
  subscribe: (postId, isSubscribe) =>
    fokoud.post(`/posts/${postId}/mark`, {
      type: isSubscribe ? 'subscribe' : 'unsubscribe',
    }),
  flag: (postId) => fokoud.post(`/posts/${postId}/flag`),
  archives: (postId) => fokoud.post(`/posts/${postId}/archives`),
  repost: (postId, channelId) =>
    fokoud.post(`/posts/${postId}/reshare`, {
      channelId,
      type: 'clone',
    }),
  move: (postId, channelId) =>
    fokoud.post(`/posts/${postId}/move`, {
      channelId,
    }),
  COMMENTS_LIMIT: DEFAULT_LIMIT,
  comments: (postId, readyAt) => {
    const filter = {
      limit: PostService.COMMENTS_LIMIT,
      order: 'readyAt DESC',
      where: {},
      include: [{ owner: 'avatar' }, 'media', 'documents'],
    }
    if (readyAt) {
      filter.where.readyAt = {
        lt: readyAt,
      }
    }
    return fokoud
      .get(`/posts/${postId}/comments`, {
        params: {
          filter: JSON.stringify(filter),
        },
      })
      .then((response) => _.reverse(response))
  },
  create: (post) => {
    post.communityId = getCommunityId()
    return fokoud.post('/posts', post).then((post) => {
      // hijack and insert the avatar (fokoud bug that it's not returned)
      const { avatar } = Session.getUser()
      _.merge(post.owner, { avatar })
      Post.afterCreate(post)
      return post
    })
  },
  update: (updatedPost) =>
    fokoud.put(`/posts/${updatedPost.id}`, updatedPost).then((post) => {
      const { avatar } =
        Session.getUser().id === _.get(post, 'owner.id')
          ? Session.getUser()
          : updatedPost.owner
      _.merge(post.owner, { avatar })
      return post
    }),
  share: (postId) => fokoud.get(`/shares/${postId}`),
  getShareLink: (post) => fokoud.post(`/posts/${post.id}/shares`),
  refreshPollResults: (post) =>
    PostService.get(post.id).then((response) => {
      const { poll } = response
      Post.afterPollRefresh(post, poll)
    }),
  submitPollChoice: (post, choiceIds) =>
    fokoud
      .post(`/posts/${post.id}/mark`, {
        type: 'pollChoice',
        choices: choiceIds,
      })
      .then(() => {
        post.pollChoices = choiceIds
        return PostService.refreshPollResults(post)
      }),
  POLL_VOTERS_LIMIT: DEFAULT_LIMIT,
  pollVoters: (postId, choiceId, limit, skip) =>
    fokoud.get(`/posts/${postId}/pollVoters`, {
      params: {
        choiceId,
        limit,
        skip,
      },
    }),
}
