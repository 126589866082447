const COMMUNITY_ID = 'communityId'
const POST_ID = 'postId'
const CONTAINER_POST_ID = 'containerPostId'
const SLUG = 'slug'
const TASK_ID = 'taskId'
const BROADCAST_ID = 'broadcastId'
const ASSIGNMENT_ID = 'assignmentId'
const HASHTAG = 'hashtag'
const FOLDER_ID = 'folderId'
const MAGICLINK = 'magiclink'
const CHANNEL_ID = 'channelId'
const ORGANIZATION_ID = 'organizationId'
const TASK_STEP = 'taskStep'
const ASSIGNMENTS_REVIEW = 'assignments-review'
const TEMPLATE_ID = 'templateId'

export const RouteParams = {
  COMMUNITY_ID,
  POST_ID,
  CONTAINER_POST_ID,
  SLUG,
  TASK_ID,
  TEMPLATE_ID,
  BROADCAST_ID,
  ASSIGNMENT_ID,
  HASHTAG,
  FOLDER_ID,
  MAGICLINK,
  CHANNEL_ID,
  ORGANIZATION_ID,
  ASSIGNMENTS_REVIEW,
}

export const Routes = {
  AUTH: '/auth',
  LOGOUT: '/logout',
  SSO_REDIRECT: '/download-app/link',
  SSO_SERVICES: '/auth/sso',
  SUPER_ADMIN: '/super',
  SHARE: '/start/share/:id',
  DEEPLINK: {
    LAUNCH: '/launch',
    COMMUNITY: `/launch/communities/:${COMMUNITY_ID}`,
    POST: `*/posts/:${POST_ID}`,
    CONTAINER_POST: `*/posts/:${POST_ID}/items/:${CONTAINER_POST_ID}`,
    // legacy
    PROJECTS: '/projects',
    PROJECT: `/projects/:${TASK_ID}`,
    TASK: `/projects/:${TASK_ID}/tasks/:${ASSIGNMENT_ID}`,
    RESET_PASSWORD_SERVICES: `/launch/reset-password-services/:${MAGICLINK}`,
    ACCOUNT_SETUP: `/launch/account-setup/:${MAGICLINK}`,
    RESET_PASSWORD: `/reset-password/:${MAGICLINK}`,
    MAGIC: `/magic/:${MAGICLINK}`,
    MAGIC_SERVICES: `/launch/magic-login/:${MAGICLINK}`,
    CHANNELS: `/communities/:${COMMUNITY_ID}/channels/:${CHANNEL_ID}`,
    BROADCAST: `*/organizations/:${ORGANIZATION_ID}/broadcasts/:${BROADCAST_ID}`,
    ASSIGNMENTS: '*/assignments-list',
    TASKS: `*/organizations/:${ORGANIZATION_ID}/tasks/:${TASK_ID}/*`,
    REVIEWING_TASK: `*/organizations/:${ORGANIZATION_ID}/tasks/:${TASK_ID}/assignments-review/:${ASSIGNMENT_ID}`,
    ASSIGNED_TASK: `*/organizations/:${ORGANIZATION_ID}/tasks/:${TASK_ID}/assignments-completion/:${ASSIGNMENT_ID}`,
    REVIEW: `*/organizations/:${ORGANIZATION_ID}/tasks/:${TASK_ID}/review`,
    /**
     * Get the "/bare" route for a given Deeplink route.
     * @param {*} route Non-bare Deeplink route. If it is not a deeplink route
     * (i.e DEEPLINK.LAUNCH isn't present), the route is returned as is.
     * Eg: '/launch/communities/:communityId'
     * @returns Bare deeplink route for the given route.
     * Eg: '/launch/bare/communities/:communityId'
     */
    getBareRoute: (route) => {
      if (
        !route ||
        !route.includes(
          Routes.DEEPLINK.LAUNCH || route.includes(Routes.BARE.ROOT)
        )
      ) {
        // Return route as is. No bare conversion needed.
        return route
      }

      const launchParts = route.split(Routes.DEEPLINK.LAUNCH)
      return `${Routes.DEEPLINK.LAUNCH}${Routes.BARE.ROOT}${launchParts[1]}`
    },
  },
  // CHANNELS: `/channels/:${SLUG}`,
  CHANNELS: {
    ROOT: '/channels',
    SLUG: `/channels/:${SLUG}`,
    slug: (slug) => `/channels/${slug}`,
  },
  DOCUMENTS: {
    ROOT: '/documents',
    FOLDER: `/documents/folders/:${FOLDER_ID}`,
  },
  SEARCH: {
    ROOT: '/search',
    OVERVIEW: '/search/overview',
    POSTS: '/search/posts',
    HASHTAG: `/search/hashtag/:${HASHTAG}`,
    hashtag: (value) => `/search/hashtag/${value}`,
  },
  TASK: {
    ROOT: '/task',
    ID: `/task/:${TASK_ID}`,
    id: (taskId) => `/task/${taskId}`,
    EDIT: `/task/:${TASK_ID}/edit`,
    edit: (taskId) => `/task/${taskId}/edit`,
    ASSIGNMENTS: `/task/:${TASK_ID}/assignments/:${ASSIGNMENT_ID}`,
    assignments: (taskId, assignmentId) =>
      `/task/${taskId}/assignments/${assignmentId}`,
    assignmentsItem: (taskId, assignmentId, resultId) =>
      `/task/${taskId}/assignments/${assignmentId}/results/${resultId}`,
    CALENDAR: '/task/calendar',
    ASSIGNED: '/task/assigned',
    REVIEWING: '/task/reviewing',
    SCHEDULED: '/task/scheduled',
    ARCHIVED: '/task/archived',
    CREATE: '/task/create',
    DASHBOARD: `/task/:${TASK_ID}/dashboard`,
  },
  TASKS: {
    ROOT: '/tasks',
    CREATE: '/tasks/create',
    TEMPLATES: '/tasks/templates',
    createStep: (step) => `/tasks/create/${step}`,
    CALENDAR: '/tasks/calendar',
    ASSIGNED: '/tasks/assigned',
    REVIEWING: '/tasks/reviewing',
    SCHEDULED: '/tasks/scheduled',
    ARCHIVED: '/tasks/archived',
    EDIT: `/tasks/edit/:${TASK_ID}`,
    edit: (taskId) => `/tasks/edit/${taskId}`,
    EDIT_STEP: `/tasks/edit/:${TASK_ID}/:${TASK_STEP}`,
    editStep: (taskId, step) => `/tasks/edit/${taskId}/${step}`,
    assignmentCompletion: (taskId, assignmentId, isBare) =>
      `${
        isBare ? '/bare' : ''
      }/tasks/${taskId}/assignments-completion/${assignmentId}`,
    ASSIGNMENT_COMPLETION: `/tasks/:${TASK_ID}/assignments-completion/:${ASSIGNMENT_ID}`,
    id: (taskId, isBare) => `${isBare ? '/bare' : ''}/tasks/${taskId}`,
    TASK_ID: `/tasks/:${TASK_ID}`,
    reviewAssignment: (taskId) => `/tasks/${taskId}/${ASSIGNMENTS_REVIEW}`,
    REVIEW_ASSIGNMENT: `/tasks/:${TASK_ID}/${ASSIGNMENTS_REVIEW}`,
    assignmentReview: (taskId, assignmentId, isBare) =>
      `${
        isBare ? '/bare' : ''
      }/tasks/${taskId}/${ASSIGNMENTS_REVIEW}/${assignmentId}`,
    ASSIGNMENT_REVIEW: `/tasks/:${TASK_ID}/${ASSIGNMENTS_REVIEW}/:${ASSIGNMENT_ID}`,
    reviewItems: (taskId) => `/tasks/${taskId}/items-review`,
    REVIEW_ITEM: `/tasks/:${TASK_ID}/items-review`,
    TEMPLATE_LOADER: `/tasks/template/:${TEMPLATE_ID}`,
    templateLoader: (templateId) => `/tasks/template/${templateId}`,
  },
  BROADCASTS: {
    ROOT: '/broadcasts',
    EDIT: `/broadcasts/:${BROADCAST_ID}/edit`,
    edit: (broadcastId) => `/broadcasts/${broadcastId}/edit`,
    COPY: `/broadcasts/:${BROADCAST_ID}/copy`,
    copy: (broadcastId) => `/broadcasts/${broadcastId}/copy`,
    CREATE: `/broadcasts/create`,
    LIVE: `/broadcasts/live`,
    DRAFTS: `/broadcasts/drafts`,
  },
  ADMIN: {
    ROOT: '/admin',
    ORGANIZATION: {
      ROOT: '/admin/organization',
      STATS: '/admin/organization/stats',
      SETTINGS: '/admin/organization/settings',
    },
    CHANNELS: {
      ROOT: '/admin/channels',
      SETTINGS: '/admin/channels/settings',
    },
    USERS: {
      ROOT: '/admin/users',
      SETTINGS: '/admin/users/settings',
    },
    USERLISTS: {
      ROOT: '/admin/userlists',
    },
    TASKS: {
      ROOT: '/admin/tasks',
      WORKFLOW: '/admin/tasks/workflow',
      SETTINGS: '/admin/tasks/settings',
      TASK_SETTINGS: '/admin/task/settings',
    },
    HIERARCHY: {
      ROLES: '/admin/hierarchy/roles',
      PLACES: '/admin/hierarchy/places',
      PLACE_TYPES: '/admin/hierarchy/place-types',
      ATTRIBUTES: '/admin/hierarchy/attributes',
    },
    BROADCAST: {
      ROOT: '/admin/broadcast',
    },
    HASHTAGS: {
      ROOT: '/admin/hashtags',
    },
    DOCUMENTS: {
      ROOT: '/admin/documents',
      SETTINGS: '/admin/documents/settings',
      FOLDER: `/admin/documents/:${FOLDER_ID}`,
      folder: (folderId) => `/admin/documents/${folderId}`,
    },
    LOCATIONS: {
      ROOT: '/admin/locations',
    },
    REPORTS: {
      ROOT: '/admin/reports',
    },
    SYNC: {
      ROOT: '/admin/sync',
    },
    ENGAGEMENT: {
      ROOT: '/admin/user-engagement',
    },
  },
  BARE: {
    ROOT: '/bare',
    MESSAGES: '/bare/messages',
    LAUNCH: '/bare/launch',
    TASKS: {
      ASSIGNED: '/bare/tasks/assigned',
    },
    BROADCASTS: '/bare/broadcasts',
  },
  MESSAGES: {
    ROOT: '/messages',
  },
  COMMUNITIES: {
    ROOT: '/communities',
  },
  APP: {
    ROOT: '/app',
  },
  parameter: (props, key) => props?.match?.params?.[key],
  params: (params, key) => params[key],
}
