import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  rawContenStateToMarkdown,
  markdownToRawContenState,
} from '../../../components/wysiwyg/utils/content-state'
import { StepType } from './step-type'
import { META_DATA } from '../../core/models/file'
import { Task } from './task'
import { userId } from '../../core/models/session'
import Colors from '../../../styles/colors'
import { Post } from '../../core/models/post'

const LOCAL_ID = '_id'
const RESULT_STEP_ID = '_resultStepId'

const resultStepsForStep = (completion, step) =>
  _.filter(_.get(completion, 'resultSteps'), { stepId: step.id })

const isResultStepIncomplete = (step, resultStep) => {
  if (_.isNil(resultStep)) {
    return true
  }
  const { type } = step
  if (type === StepType.PHOTO) {
    return _.isNil(resultStep.fileId)
  }
  if (type === StepType.ACTION) {
    return resultStep.skipped !== false
  }
  if (type === StepType.TEXT) {
    return _.isEmpty(_.toString(resultStep.value))
  }
  if (type === StepType.SURVEY) {
    return !_.includes(_.values(resultStep.choices), true)
  }
  if (type === StepType.ATTACHMENT) {
    return (
      _.isEmpty(resultStep.documentInfos) ||
      _.some(resultStep.documentInfos, (documentInfo) =>
        _.isNil(documentInfo.fileId)
      )
    )
  }
  return false
}

const canSubmit = (steps, completion) =>
  !_.some(steps, (step) => {
    if (step.required) {
      const resultSteps = resultStepsForStep(completion, step)
      return _.some(resultSteps, (resultStep) =>
        isResultStepIncomplete(step, resultStep)
      )
    }
    return false
  })

const newResultStep = (step) => ({ stepId: step.id, [LOCAL_ID]: uuid() })

const setResultStepFile = (resultStep, file) => {
  file[RESULT_STEP_ID] = resultStep[LOCAL_ID]
  resultStep.content = rawContenStateToMarkdown(resultStep._content)
  _.set(file, `${META_DATA}.content`, resultStep.content)
  _.set(file, `${META_DATA}.place`, resultStep.place)
  resultStep.file = file
  if (file.id) {
    resultStep.fileId = file.id
  }
}

const isResultStepForFile = (resultStep, file) =>
  resultStep[LOCAL_ID] === file[RESULT_STEP_ID]

const findAndDesanitize = (task, completionId) => {
  const completion = _.find(_.get(task, 'tasks'), { id: completionId })
  if (!completion) {
    return
  }
  if (!completion.resultSteps) {
    completion.resultSteps = []
  }
  _.forEach(task.steps, (step) => {
    // steps can have multiple result steps (.i.e. photos)
    let resultSteps = resultStepsForStep(completion, step)
    // if (step.type === StepType.PHOTO) {
    //   resultSteps = _.filter(resultSteps, (resultStep) => !_.isNil(resultStep.fileId))
    // }
    if (_.isEmpty(resultSteps)) {
      const resultStep = newResultStep(step)
      resultSteps = [resultStep]
      completion.resultSteps.push(resultStep)
    }
    _.forEach(resultSteps, (resultStep) => {
      resultStep[LOCAL_ID] = uuid()
      resultStep._content = markdownToRawContenState(resultStep.content)
      if (!resultStep.place && resultStep.placeId) {
        resultStep.place = { id: resultStep.placeId }
      }
      if (resultStep.file) {
        resultStep.file[RESULT_STEP_ID] = resultStep[LOCAL_ID]
        _.set(resultStep.file, `${META_DATA}.content`, resultStep.content)
        _.set(resultStep.file, `${META_DATA}.place`, resultStep.place)
      }
      if (_.isNil(resultStep.skipped)) {
        if (step.type === StepType.ACTION) {
          resultStep.skipped = true
        }
      }
    })
  })
  return completion
}

const sanitize = (steps, _completion) => {
  const completion = _.cloneDeep(_completion)
  _.forEach(completion.resultSteps, (resultStep) => {
    const step = _.find(steps, { id: resultStep.stepId })
    resultStep.skipped = isResultStepIncomplete(step, resultStep)
    resultStep.placeId = _.get(resultStep.place, 'id')
    delete resultStep.place
    resultStep.content = rawContenStateToMarkdown(resultStep._content)
    delete resultStep.file
    delete resultStep._content
  })
  return completion
}

const getFiles = (resultSteps) => {
  if (_.size(resultSteps) === 1 && !resultSteps[0].file) {
    return []
  }
  return _.compact(
    _.map(resultSteps, (resultStep) =>
      resultStep.file ? resultStep.file : { error: 'Missing file' }
    )
  )
}

const needsChanges = (completion) => completion.status === 'needsChanges'
const isSubmitted = (completion) =>
  _.includes(
    ['inReview', 'needsChanges', 'completed'],
    _.get(completion, 'status')
  )
const isCompleted = (completion) => completion.status === 'completed'
const canComment = (completion) => isSubmitted(completion)
const canApprove = (completion, task) =>
  _.includes(
    ['inReview', 'needsChanges', 'completed'],
    _.get(completion, 'status')
  ) && Task.canApprove(task)

const resultStepPost = (completion, resultStep) => {
  const post = _.get(completion, 'post')
  if (!Post.isAlbumPost(post)) {
    return post
  }
  return _.find(
    _.get(post, 'containerItems'),
    (containerItem) =>
      _.get(containerItem, 'meta.task.resultStep.id') === resultStep.id
  )
}

const status = (completion) =>
  completion.status === 'active' ? 'notStarted' : completion.status

const statusColors = (completion) => {
  // [textColor, bgColor]
  if (completion.status === 'needsChanges') {
    return [Colors.darkYellow, `${Colors.lightYellow}4D`]
  }
  if (completion.status === 'inReview') {
    return [Colors.red, Colors.pink]
  }
  if (completion.status === 'completed') {
    return [Colors.darkGreen, `${Colors.lightGreen}4D`]
  }
  if (completion.status === 'inProgress') {
    return [Colors.green, `${Colors.lightGreen}4D`]
  }
  // not started
  return [Colors.midDarkGrey, Colors.offWhite]
}
const statusIcon = (completion) => {
  if (completion.status === 'needsChanges') {
    return 'exclamation-circle'
  }
  if (completion.status === 'inReview') {
    return 'binocular'
  }
  if (completion.status === 'completed') {
    return 'check-starcircle'
  }
  if (completion.status === 'inProgress') {
    return 'tab-horizontal'
  }
  // not started
  return 'no-entry'
}

const isOwner = (completion) => completion.userId === userId

const canDelete = (completion) =>
  _.includes(['active', 'notStarted', 'inProgress'], completion.status)
const canSaveDraft = (completion) => canDelete(completion)

export const Completion = {
  resultStepsForStep,
  newResultStep,
  setResultStepFile,
  isResultStepForFile,
  findAndDesanitize,
  isOwner,
  sanitize,
  canSubmit,
  getFiles,
  needsChanges,
  isSubmitted,
  isCompleted,
  canComment,
  canApprove,
  status,
  statusColors,
  statusIcon,
  resultStepPost,
  canDelete,
  canSaveDraft,
  IN_PROGRESS: 'inProgress',
  NEEDS_CHANGES: 'needsChanges',
  COMPLETED: 'completed',
}
