import _ from 'lodash'
import { store } from './store'
import { Post, ROLLBACK_POST } from '..'
import {
  POST_DELETED,
  POST_UPDATED,
  dispatchEvent,
} from '../../../../../events'

export const replaceWithAnyErrorPosts = (posts) => {
  const errorPosts = _.filter(store.get(), (post) => Post.isError(post))
  if (!errorPosts) {
    return posts
  }
  const keyed = _.keyBy(errorPosts, (post) => post.id)
  return _.map(posts, (post) => keyed[post.id] || post)
}

export const discard = (post) => {
  store.remove(post)
  if (!post.id) {
    dispatchEvent(POST_DELETED, post)
  } else {
    dispatchEvent(POST_UPDATED, post[ROLLBACK_POST])
  }
}

export const retry = (post) => {
  if (!post.id) {
    discard(post)
    Post.createUpdate(undefined, post)
  } else {
    Post.createUpdate(post[ROLLBACK_POST], post)
  }
}
