import _ from 'lodash'
import React, { Suspense, lazy, useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Loader from './components/loader'
import { Router } from './utils/router'
import { Routes, RouteParams } from './routes'
import {
  authToken,
  Session,
  getCommunityId,
} from './modules/core/models/session'
import { Community } from './modules/core/models/community'
import { deeplinkUI } from './modules/core/api/deeplnk-ui'
import { deeplinkToRoute } from './utils/deeplink'
import { appendHttpIfRequired } from './utils/uri'
import { getLanguage } from './i18n'
import { ApplicationState } from './state'

const App = lazy(() => import(/* webpackChunkName: "app" */ './app'))

const Root = () => {
  useEffect(() => {
    document.documentElement.lang = getLanguage()
  }, [])

  if (_.includes(window.location.hash, '#/deeplink')) {
    window.location.href = _.replace(
      window.location.hash,
      '#/deeplink',
      '/launch'
    )
    return null
  }

  return (
    <Suspense fallback={<Loader fullScreen />}>
      <Switch>
        {/* these deeplinks are new ui only! */}
        <Route
          component={App}
          path={[
            Routes.DEEPLINK.RESET_PASSWORD_SERVICES,
            Routes.DEEPLINK.MAGIC_SERVICES,
            Routes.DEEPLINK.ACCOUNT_SETUP,
            Routes.DEEPLINK.BROADCAST,
            Routes.DEEPLINK.ASSIGNMENTS,
            Routes.DEEPLINK.TASKS,
          ]}
        />

        <Route
          path={[
            Routes.DEEPLINK.COMMUNITY,
            Routes.DEEPLINK.getBareRoute(Routes.DEEPLINK.COMMUNITY),
            Routes.DEEPLINK.LAUNCH,
          ]}
          render={(props) => {
            const communityId = Routes.parameter(
              props,
              RouteParams.COMMUNITY_ID
            )

            if (
              !authToken ||
              !communityId ||
              !Community.hasLoadedFeatureFlags()
            ) {
              let bits = window.location.pathname
              bits = _.replace(bits, '/launch/', '')
              bits = _.replace(bits, '/deeplink/', '')
              const split = _.split(bits, '/')
              const [key, value] = split
              deeplinkUI({ [key]: value })
                .then((data) => {
                  const { loginUrl } = data
                  if (!authToken && loginUrl) {
                    window.location.href = deeplinkToRoute(
                      window.location.href,
                      appendHttpIfRequired(loginUrl)
                    )
                  } else {
                    Session.setCommunityId(communityId)
                    window.location.href = deeplinkToRoute(window.location.href)
                  }
                })
                .catch((e) => {
                  // eslint-disable-next-line
                  console.error(e)
                })
              return <Loader fullScreen />
            }

            if (
              getCommunityId() !== communityId &&
              Community.getById(communityId)
            ) {
              Session.setCommunityId(communityId)
              window.location.reload()
              return null
            }

            return <Redirect to={deeplinkToRoute(window.location.href)} />
          }}
        />
        <Route component={App} />
      </Switch>
    </Suspense>
  )
}

const HistoryInit = (props) => {
  const history = _.get(props, 'history')
  const location = _.get(history, 'location')

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    Router.set(history)
    setIsInitialized(true)
  }, [history])

  useEffect(() => {
    if (Router.setCurrentLocation(location)) {
      ApplicationState.setNotificationState(false)
    }
    // refocus to header for a11y
    setTimeout(() => {
      const heading = document.querySelector("[role='heading']")
      if (heading) {
        heading.focus()
      }
    }, 0)
  }, [location])

  if (!isInitialized) {
    return null
  }

  return <Root />
}

export default () => (
  <BrowserRouter>
    <Route component={HistoryInit} />
  </BrowserRouter>
)
