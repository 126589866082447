import _ from 'lodash'
import { Post, LOCAL_ID } from '..'
import { LOCAL_URL, UPLOADING_PROMISE_METHOD } from '../../file'
import { Store } from '../../../../../utils/store'

const KEY = 'uploading-posts'

// remove LOCAL_URL so base-64 urls don't max out space!
// remove UPLOADING_PROMISE_METHOD to avoid TypeError: Converting circular structure to JSON on serialization
const removeUnserializableData = (postToClean) => {
  if (!postToClean) {
    return postToClean
  }

  const dataToRemove = [LOCAL_URL, UPLOADING_PROMISE_METHOD]
  const post = _.cloneDeep(postToClean)

  const cleanFilesIn = (propertyName) => {
    if (_.has(post, propertyName)) {
      _.update(post, propertyName, (files) =>
        _.map(files, (file) => _.omit(file, dataToRemove))
      )
    }
  }

  cleanFilesIn('images')
  cleanFilesIn('media')
  cleanFilesIn('documents')
  if (_.has(post, 'containerItems')) {
    post.containerItems = _.map(
      _.get(post, 'containerItems'),
      (containerItem) => removeUnserializableData(containerItem)
    )
  }

  return post
}

const get = () => Store.get(KEY)

const set = (posts) => Store.set(KEY, posts)

const add = (_postToStore) => {
  const postToStore = removeUnserializableData(_postToStore)
  let posts = get()
  if (!posts) {
    posts = []
  }
  const index = _.findIndex(posts, (post) => Post.isIdMatch(post, postToStore))
  if (index >= 0) {
    posts[index] = postToStore
  } else {
    posts.push(postToStore)
  }
  set(posts)
}

const remove = (postToRemove) => {
  const posts = get()
  const newPosts = _.reject(posts, (post) => Post.isIdMatch(post, postToRemove))
  set(newPosts)
}

const getById = (id, localId) => {
  const posts = get()
  const metaPost = { id, [LOCAL_ID]: localId }
  const index = _.findIndex(posts, (post) => Post.isIdMatch(post, metaPost))
  if (index >= 0) {
    return posts[index]
  }
}

const getChannelPosts = (channelId) => {
  const posts = get()
  return _.filter(
    posts,
    (post) =>
      post.channelId === channelId && _.isNil(post.id) && !Post.isComment(post)
  )
}

const clearAllUploading = () => {
  const posts = get()
  const newPosts = _.reject(posts, (post) => !Post.isError(post))
  set(newPosts)
}

export const store = {
  add,
  remove,
  get,
  getById,
  getChannelPosts,
  clearAllUploading,
}
