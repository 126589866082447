import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Community } from '../modules/core/models/community'
import Colors from '../styles/colors'
import { Branding } from '../utils/branding'
import { getConsolidatedStyles } from '../utils/styles'
import { sizing } from '../styles'

const defaultStyles = {
  root: {
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridColumnGap: '0px',
      gridRowGap: '0px',
      alignSelf: 'center',
      justifySelf: 'center',
    },
    '& > div > div': {
      alignSelf: 'center',
      justifySelf: 'center',
    },
    '& > div > div > div': {
      position: 'relative',
      width: '15px',
      height: '15px',
      borderRadius: '20px',
      backgroundColor: Colors.lightGrey,
      color: Colors.lightGrey,
      animation: '$indicatorFlashing 1s infinite linear alternate',
      animationDelay: '.5s',
      '&:before, &:after': {
        content: "''",
        display: 'inline-block',
        position: 'absolute',
        top: '0',
      },
      '&:before': {
        left: '-18px',
        width: '15px',
        height: '15px',
        borderRadius: '20px',
        backgroundColor: Colors.lightGrey,
        color: Colors.lightGrey,
        animation: '$indicatorFlashing 1s infinite alternate',
        animationDelay: '0s',
      },
      '&:after': {
        left: '18px',
        width: '15px',
        height: '15px',
        borderRadius: '20px',
        backgroundColor: Colors.lightGrey,
        color: Colors.lightGrey,
        animation: '$indicatorFlashing 1s infinite alternate',
        animationDelay: '1s',
      },
    },
  },
  '@keyframes indicatorFlashing': {
    '0%': {
      backgroundColor: Colors.darkGrey,
    },
    '50%': {},
    '100%': {
      backgroundColor: Colors.lightGrey,
    },
  },
}

const Loader = (props) => {
  const { classes, fullScreen, compact, isInline } = props
  const [canShow, setCanShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCanShow(true)
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (!canShow) {
    return null
  }

  let height
  if (fullScreen) {
    height = '100vh'
  } else if (!compact) {
    height = '100%'
  }

  return (
    <div
      className={classes.root}
      style={{
        height,
        padding: compact ? sizing.normal : null,
        display: 'flex',
        flex: isInline ? undefined : 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* more complex structure to increase flexibility of presentation  */}
      <div>
        <div>
          <div />
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isInline: PropTypes.bool,
}

Loader.defaultProps = {
  fullScreen: false,
  compact: false,
  isInline: false,
}

const BrandedLoader = (props) => {
  const { propsBranding } = props
  const [branding, setBranding] = useState()

  useEffect(() => {
    if (propsBranding) {
      setBranding(propsBranding)
    }
  }, [propsBranding])

  const styles = getConsolidatedStyles(defaultStyles, branding, 'loader')

  const LoaderComponent = injectSheet(styles)(Loader)
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LoaderComponent branding={branding} {...props} />
}

BrandedLoader.propTypes = {
  propsBranding: PropTypes.object,
}
BrandedLoader.defaultProps = {
  propsBranding: undefined,
}
export default (props) => {
  if (Branding.isBrandedApp() || Community.isBranded()) {
    const branding = Community.getBranding()
    // branded loader component; inject any branded styles from config
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <BrandedLoader propsBranding={branding} {...props} />
  }

  // default loader component; inject default styles
  const LoaderComponent = injectSheet(defaultStyles)(Loader)
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LoaderComponent {...props} />
}
