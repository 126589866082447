import _ from 'lodash'
import stringToColor from 'string-to-hex-color'
import { tr } from '../../../i18n'
import { userId, Session } from './session'
import Colors, { defaultTheme } from '../../../styles/colors'
import { dispatchEvent, ADMIN_OBJECT_UPDATED } from '../../../events'
import { Community } from './community'

// this gracefully handles userlist and dm objects as well

const isUserList = (user) => _.camelCase(user.type) === 'userList'

const isDm = (user) => _.get(user, 'type') === 'dm'
const dmUserCount = (user) => _.get(user, 'dmUserInfos.length')
export const isGroupDM = (user) => isDm(user) && dmUserCount(user) > 2
export const otherDmUser = (user) => {
  const otherUser = _.find(
    user.dmUserInfos,
    (userInfo) => userInfo.id !== userId
  )
  if (otherUser) {
    return otherUser
  }
  // here is some b.s. when no dmUserInfos exist, but dmUserIds do!
  // (i think this is for dm suggestions)
  const [firstName, lastName] = _.split(user.name, ' ')
  return {
    id: _.find(user.dmUserIds, (dmUserId) => dmUserId !== userId),
    firstName,
    lastName,
  }
}

const avatarColors = (user) => {
  if (isDm(user)) {
    if (isGroupDM(user)) {
      return [Colors.yellow, defaultTheme.nearBlack]
    }
    return avatarColors(otherDmUser(user))
  }
  return stringToColor(_.get(user, 'id') || '')
}

const avatarSize = (user) => {
  if (user.avatar) {
    if (user.avatar['small-square']) {
      return 'small-square'
    }
    return 'small'
  }
}

const avatarURL = (user, size = 'thumbnail') => {
  const sizeURL = size === 'small-square' ? avatarSize(user) : size
  if (isDm(user)) {
    if (isGroupDM(user)) {
      return
    }
    return avatarURL(otherDmUser(user))
  }
  return (
    _.get(user, 'avatarURL') ||
    _.get(user, `avatar.${sizeURL}.url`) ||
    _.get(user, 'avatar.url')
  )
}

const firstName = (user) =>
  _.get(user, 'firstName', _.get(user, 'firstname', ''))
const lastName = (user) => _.get(user, 'lastName', _.get(user, 'lastname', ''))

const initials = (user) => {
  if (isDm(user)) {
    if (isGroupDM(user)) {
      return dmUserCount(user)
    }
    return initials(otherDmUser(user))
  }
  if (isUserList(user)) {
    return '@'
  }
  const first = firstName(user).charAt(0)
  const last = lastName(user).charAt(0)
  return `${first}${last}`
}

const fullName = (user) =>
  _.get(user, 'name') || `${firstName(user)} ${lastName(user)}`

const firstRole = (user) => {
  const rolePlaces = _.head(_.get(user, 'rolePlaces'))
  return _.get(rolePlaces, 'role.name')
}

const firstPlace = (user) => {
  const rolePlaces = _.head(_.get(user, 'rolePlaces'))
  return _.get(rolePlaces, 'place.name')
}

const firstParentPlace = (user) => {
  const rolePlaces = _.head(_.get(user, 'rolePlaces'))
  return _.get(rolePlaces, 'place.parentPlace.name')
}

const description = (user, field) => {
  if (isDm(user)) {
    if (isGroupDM(user)) {
      return
    }
    return description(otherDmUser(user), field)
  }
  const value = _.get(user, field)
  if (value) {
    return value
  }
  const userCount = _.get(user, 'userCount')
  if (userCount) {
    return tr('members', userCount)
  }
  return null
}

const isDisabled = (user) => {
  const userOrganizations = _.get(user, 'userOrganizations')
  const statusDisabled = _.find(userOrganizations || [], {
    userStatus: 'DISABLED',
  })
  return !_.isNil(statusDisabled)
}

const isAdmin = (user) => {
  const userOrganization = _.find(user.userOrganizations, {
    organizationId: Session.getOrganizationId(),
  })
  return _.get(userOrganization, 'isAdmin')
}

const afterUpdateStatus = (_user, status) => {
  const user = _.cloneDeep(_user)
  const updatedUserOrganizations = _.map(
    user.userOrganizations,
    (organization) => {
      const org = organization
      org.userStatus = status
      return org
    }
  )
  user.userOrganizations = updatedUserOrganizations
  dispatchEvent(ADMIN_OBJECT_UPDATED, user)
}

const afterUpdateUser = (_user, userUpdate) => {
  const user = _.cloneDeep(_user)
  dispatchEvent(ADMIN_OBJECT_UPDATED, _.merge(user, userUpdate))
}

// eslint-disable-next-line no-shadow
const afterUpdateAdmin = (_user, isAdmin) => {
  const user = _.cloneDeep(_user)
  user.userOrganizations = _.map(user.userOrganizations, (userOrganization) => {
    const userOrganizationCopy = userOrganization
    if (userOrganizationCopy.organizationId === Session.getOrganizationId()) {
      userOrganizationCopy.isAdmin = isAdmin
    }
    return userOrganizationCopy
  })
  dispatchEvent(ADMIN_OBJECT_UPDATED, user)
}

const afterCreateRolePlace = (_user, newRolePlace, id) => {
  const user = _.cloneDeep(_user)
  // eslint-disable-next-line no-param-reassign
  newRolePlace.userRolePlaceId = id
  const userRolePlaces = _.concat(user.userRolePlaces, newRolePlace)
  dispatchEvent(ADMIN_OBJECT_UPDATED, _.merge(user, { userRolePlaces }))
}
const afterUpdateRolePlace = (_user, rolePlaceUpdate) => {
  const user = _.cloneDeep(_user)
  const rolePlaceUpdated = _.map(user.userRolePlaces, (rolePlace) =>
    rolePlace.userRolePlaceId === rolePlaceUpdate.userRoleId
      ? rolePlaceUpdate
      : rolePlace
  )
  dispatchEvent(
    ADMIN_OBJECT_UPDATED,
    _.merge(user, { userRolePlaces: rolePlaceUpdated })
  )
}

const afterDeleteRolePlace = (_user, userRoleId) => {
  const user = _.cloneDeep(_user)
  const rolePlaceUpdated = _.reject(user.userRolePlaces, {
    userRolePlaceId: userRoleId,
  })
  user.userRolePlaces = rolePlaceUpdated
  dispatchEvent(ADMIN_OBJECT_UPDATED, user)
}

const getLanguage = (user) => user?.preferences?.language

const isLocked = (user) => {
  const userOrganization = _.find(user.userOrganizations, {
    organizationId: Session.getOrganizationId(),
  })
  return _.get(userOrganization, 'userStatus') === 'LOCKED'
}

const isBroadcaster = (user = Session.getUser()) =>
  _.get(user, 'privileges.broadcaster') === true

const isGatekeeper = (user = Session.getUser()) =>
  _.get(user, 'privileges.broadcastGatekeeper') === true &&
  Community.isGatekeeperFullyEnabled()

const isBroadcasterOnly = (user = Session.getUser()) =>
  isBroadcaster(user) && !isGatekeeper(user)

/**
 * Verifies if a user is the same as the session user
 * @param {object} user to be verified
 * @return {boolean} True if it's the same user
 */
const isSessionUser = (user) => {
  const sessionUserServicesId = Session.getUser()?.servicesId
  return sessionUserServicesId && sessionUserServicesId === user?.servicesId
}

/**
 * jointUsers
 *
 * Groups user + userlists in one array
 * @param {object} userGroup - User/user list object group containing
 * @return {object} True if it's the same user
 */
const jointUsers = (userGroup) => {
  if (!userGroup) {
    return []
  }
  const usersGroup = userGroup?.users?.map((user) => ({
    id: user.id,
    type: 'user',
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
  }))

  const userlistsGroup = userGroup?.userLists?.map((userlist) => ({
    id: userlist.id,
    type: 'userList',
    name: userlist.name,
  }))

  return usersGroup.concat(userlistsGroup)
}

const isTaskCreator = (user = Session.getUser()) =>
  !!user?.privileges?.taskCreator

const TASK_NOTIFICATIONS = {
  ASSIGNED_TASKS: 'assigned_tasks',
  ASSIGNED_TASKS_EDIT: 'assigned_tasks_edit',
  ASSIGNED_TASKS_REMOVAL: 'assigned_tasks_removal',
  CREATED_TASKS_COMPLETION: 'created_tasks_completion',
  REVIEWING_TASK_CREATED: 'reviewing_task_created',
  REVIEWING_TASK_COMPLETION: 'reviewing_task_completion',
  OBSERVING_TASK_CREATED: 'observing_task_created',
  OBSERVING_TASK_COMPLETION: 'observing_task_completion',
}

export const User = {
  avatarColors,
  avatarURL,
  initials,
  fullName,
  firstRole,
  firstPlace,
  firstParentPlace,
  description,
  isUserList,
  isDisabled,
  isAdmin,
  isLocked,
  getLanguage,
  isBroadcaster,
  isGatekeeper,
  isBroadcasterOnly,
  isTaskCreator,
  //
  isGroupDM,
  otherDmUser,
  afterUpdateStatus,
  afterUpdateUser,
  afterUpdateAdmin,
  afterCreateRolePlace,
  afterUpdateRolePlace,
  afterDeleteRolePlace,
  isSessionUser,
  jointUsers,
  TASK_NOTIFICATIONS,
}
