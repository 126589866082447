import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { PostService } from '../../../api/post'
import { DocumentService } from '../../../api/document'
import {
  dispatchEvent,
  POST_CREATED,
  POST_UPDATED,
} from '../../../../../events'
import { Post, IS_ERROR, LOCAL_ID, IS_UPLOADING, ROLLBACK_POST } from '..'
import { store } from './store'
import { META_DATA } from '../../file'
import { Community } from '../../community'

const removePostAndDispatch = (post) => {
  store.remove(post)
  dispatchEvent(POST_UPDATED, post)
}

const storePostAndDispatch = (post, event = POST_UPDATED) => {
  store.add(post)
  dispatchEvent(event, post)
}

// removes any meta data
const getRollbackPost = (postToClean) => {
  const post = _.cloneDeep(postToClean)
  delete post[LOCAL_ID]
  delete post[IS_UPLOADING]
  delete post[IS_ERROR]
  delete post.images
  post.containerItems = _.filter(
    _.get(post, 'containerItems'),
    (containerItem) => !_.isNil(containerItem.id)
  )
  post.documents = _.filter(
    _.get(post, 'documents'),
    (document) => !_.isNil(document.fileId)
  )
  return post
}

const createUpdate = (post, options, localId = uuid()) =>
  Promise.resolve()
    .then(() => {
      const {
        channelId,
        parentPostId,
        content,
        mentions,
        images,
        documents,
        place,
        channelType,
        poll,
      } = options

      const placeId = _.get(place, 'id')

      const imageIds = _.map(images, 'id')

      let type = _.get(post, 'type')
      if (images.length === 1) {
        type = 'image'
      } else if (images.length > 1) {
        type = 'container'
      } else {
        type = 'text'
      }

      const getContainerItemByImageId = (imageId) =>
        _.find(
          _.get(post, 'containerItems'),
          (containerPost) => _.get(containerPost, 'media[0].id') === imageId
        )

      const placeHolderContainerItems = () =>
        _.map(images, (image) => {
          const containerItem = getContainerItemByImageId(image.id)
          return (
            containerItem || {
              [LOCAL_ID]: uuid(),
              _imageId: image.id,
              media: [image],
            }
          )
        })

      const placeHolderData = () => {
        const data = {}
        if (type === 'image') {
          data.media = images
        } else if (type === 'container') {
          data.containerItems = placeHolderContainerItems()
        }
        return data
      }

      const uploadingPost = (updatedPost) =>
        _.merge(
          {
            [LOCAL_ID]: post ? undefined : localId,
            images,
            documents,
            place,
            channelType,
          },
          placeHolderData(),
          updatedPost,
          {
            [IS_ERROR]: false,
            [IS_UPLOADING]: true,
            [ROLLBACK_POST]: post,
          }
        )

      const prepAlbumPostForContainerItemUploading = (post) => {
        // actually modify the original post object!
        post[ROLLBACK_POST] = getRollbackPost(post)
        post[IS_UPLOADING] = true
        post.images = images // need this in case of error
        // add placeholder container items and replace as we go...
        post.containerItems = placeHolderContainerItems()
      }

      // documents delta
      const [documentsToUpload, uploadedDocuments] = _.partition(
        documents,
        (document) => _.isNil(document.fileId)
      )
      const newDocumentFileIds = _.map(documentsToUpload, 'id')
      const documentIds = _.map(uploadedDocuments, 'id')

      // images delta
      const prevImageIds = _.map(Post.images(_.merge({}, post, { type })), 'id') // use the new type to get images (in case of going from image to album)
      const removedImageIds = _.difference(prevImageIds, imageIds)
      const newImageIds = _.difference(imageIds, prevImageIds)
      const postsToDelete = _.filter(
        _.get(post, 'containerItems'),
        (containerPost) =>
          _.includes(removedImageIds, _.get(containerPost, 'media[0].id'))
      )
      const maintainedImageIds = _.difference(prevImageIds, removedImageIds)

      const deleteContainerItemPosts = (parentPost) =>
        Promise.mapSeries(postsToDelete, (postToDelete) =>
          PostService.delete(postToDelete.id).then((deletedPost) => {
            // dispatchEvent(POST_DELETED, deletedPost)
            parentPost.containerItems = _.reject(parentPost.containerItems, {
              id: deletedPost.id,
            })
            parentPost[ROLLBACK_POST] = getRollbackPost(parentPost)
            storePostAndDispatch(parentPost)
          })
        )

      const addContainerItemPosts = (parentPost) =>
        Promise.mapSeries(newImageIds, (imageId) => {
          const image = _.find(images, { id: imageId })
          const { content, mentions, place } = _.get(image, META_DATA, {})
          return PostService.create({
            parentPostId: parentPost.id,
            channelId: parentPost.channelId,
            content,
            mentions,
            postType: 'containerItem',
            fileIds: [imageId],
            placeId: _.get(place, 'id'),
            type: 'image',
            meta: { containerIndex: _.indexOf(imageIds, imageId) },
          }).then((containerPost) => {
            const index = _.findIndex(parentPost.containerItems, {
              _imageId: imageId,
            })
            if (index !== -1) {
              parentPost.containerItems[index] = containerPost
            } else {
              parentPost.containerItems.push(containerPost)
            }
            parentPost[ROLLBACK_POST] = getRollbackPost(parentPost)
            storePostAndDispatch(parentPost)
          })
        }).then(() => {
          const updatedPost = _.cloneDeep(parentPost)
          delete updatedPost.images
          delete updatedPost[IS_UPLOADING]
          removePostAndDispatch(updatedPost)
        })

      const updateContainerItemPosts = (parentPost) =>
        Promise.mapSeries(maintainedImageIds, (imageId) => {
          const image = _.find(images, { id: imageId })
          const containerPost = _.find(
            _.get(post, 'containerItems'),
            (containerPost) => _.get(containerPost, 'media[0].id') === imageId
          )
          const { content, mentions, place } = _.get(image, META_DATA, {})
          if (
            _.get(containerPost, 'content') !== content ||
            _.get(containerPost, 'place') !== place
          ) {
            const updatedPost = _.clone(containerPost)
            updatedPost.content = content
            updatedPost.mentions = mentions
            delete updatedPost.place
            updatedPost.placeId = _.get(place, 'id', null) // || null is to remove it!
            updatedPost.meta.containerIndex = _.indexOf(imageIds, imageId)
            return PostService.update(updatedPost).then((updatedPost) => {
              // dispatchEvent(POST_UPDATED, updatedPost)
              parentPost.containerItems = _.map(
                parentPost.containerItems,
                (containerItem) =>
                  containerItem.id === updatedPost.id
                    ? updatedPost
                    : containerItem
              )
              parentPost[ROLLBACK_POST] = getRollbackPost(parentPost)
              storePostAndDispatch(parentPost)
            })
          }
        })

      const addDocuments = () =>
        Promise.mapSeries(newDocumentFileIds, (fileId) =>
          DocumentService.create({
            fileId,
            channelId: post ? post.channelId : channelId,
          }).then((document) => documentIds.push(document.id))
        )

      if (post) {
        const updatedPost = _.clone(post)
        updatedPost.content = content
        // updatedPost.documentIds = documentIds
        delete updatedPost.containerItems
        delete updatedPost.documents // let the documentIds control this
        updatedPost.mentions = mentions
        updatedPost.fileIds = type === 'container' ? undefined : imageIds
        delete updatedPost.place // let the placeId control this
        updatedPost.placeId = placeId || null // null is to remove it!
        updatedPost.type = type
        // todo this is temporary...remove once FOKOUD-3599 is fixed!
        if (Community.isPollingEnabled()) {
          updatedPost.poll = poll || null
        }
        storePostAndDispatch(uploadingPost(updatedPost))
        return addDocuments().then(() => {
          updatedPost.documentIds = documentIds
          return PostService.update(updatedPost).then((updatedPost) => {
            if (type === 'container') {
              prepAlbumPostForContainerItemUploading(updatedPost)
            }
            return deleteContainerItemPosts(updatedPost).then(() =>
              updateContainerItemPosts(updatedPost).then(() =>
                addContainerItemPosts(updatedPost)
              )
            )
          })
        })
      }
      const localPost = {
        parentPostId,
        channelId,
        content,
        mentions,
        postType: parentPostId ? 'comment' : undefined,
        fileIds: type === 'container' ? undefined : imageIds,
        placeId,
        type,
        poll,
      }
      storePostAndDispatch(uploadingPost(localPost), POST_CREATED)
      return addDocuments()
        .then(() => {
          localPost.documentIds = documentIds
          return PostService.create(localPost)
        })
        .then((post) => {
          post[LOCAL_ID] = localId
          if (type === 'container') {
            prepAlbumPostForContainerItemUploading(post)
            storePostAndDispatch(post)
            return addContainerItemPosts(post)
          }
          removePostAndDispatch(post)
        })
    })
    .catch(() => {
      const errorPost = store.getById(_.get(post, 'id'), localId) || {}
      errorPost[IS_ERROR] = true
      storePostAndDispatch(errorPost)
    })

export default createUpdate
