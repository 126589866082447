import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import { fokoud } from '../../../api'
import { UserLists } from '../models/userlist'
import { Session } from '../models/session'
import { Community } from '../models/community'
import { fokoServices } from '../../../api/services'
import { UserService as adminServices } from '../../admin/api/users'
import { User } from '../models/user'
import { getLanguage } from '../../../i18n'
import { exportFile } from '../../../utils/export'

const USERS = `/organizations/${Session.getOrganizationId()}/users`
const USERLISTS = `/organizations/${Session.getOrganizationId()}/userlists`
const USER_LISTS = '/users/userLists'
const USER = (userId) => `${USERS}/${userId}`
const USER_ID = (userId) => `/users/${userId}`
const PREFERENCES = (userId) => `${USER(userId)}/preferences`
const FIND_USERS = `${USERS}/find`
const FIND_USERLISTS = `${USERLISTS}/find`
const EXPORT_USER = (userId) => `${USER(userId)}/export`

let userListPromise

const fokoServicesUpdateUser = (user, newData) => {
  const { avatar, ...newUser } = newData

  return fokoServices.put(USER(user.servicesId), newUser).then(() => {
    const updatedUser = merge({}, user, newData)
    Session.updateUser(updatedUser)
    return updatedUser
  })
}

/**
 * updateUserLanguagePreference
 *
 * @param {object} user - User details
 * @param {object} language - language of preference
 * @returns {Promise} Promise object representing the updated user details object
 */
const updateUserLanguagePreference = async (user, language) => {
  const { preferences } = user
  await fokoServices.patch(PREFERENCES(user?.servicesId), { language })
  preferences.language = language
  const updatedUser = { ...user, ...{ preferences } }
  Session.updateUser(updatedUser)
  return updatedUser
}

/**
 * updateUserNotificationPreferences
 *
 * @param {object} user - User details
 * @param {object} notificationPrefs - new notification preferences
 * @returns {Promise} Promise object representing the updated user details object
 */
const updateUserNotificationPreferences = async (user, notificationPrefs) => {
  const { preferences } = user
  const { notification } = preferences

  // Get only the changed notifications
  const changedPrefs = Object.keys(notificationPrefs).reduce(
    (item, key) =>
      isEqual(notification[key], notificationPrefs[key])
        ? item
        : {
            ...item,
            [key]: notificationPrefs[key],
          },
    {}
  )
  // If there's is no notification changed - i.e. all notifications
  // remain the same as user session preferences - don't call the request
  const hasChangedPrefs = !isEqual(changedPrefs, {})
  const Promise = () =>
    hasChangedPrefs
      ? fokoServices.patch(PREFERENCES(user?.servicesId), {
          notification: changedPrefs,
        })
      : Promise.resolve

  await Promise()

  // Update user session
  preferences.notification = notificationPrefs
  const updatedUser = {
    ...user,
    ...{ preferences },
  }
  Session.updateUser(updatedUser)
  return updatedUser
}

export const UserService = {
  getFokoudUser: (userId, authToken) => {
    const headers = {}
    if (authToken) {
      headers.Authorization = authToken
    }
    return fokoud.get(USER_ID(userId), { headers })
  },

  getServicesUser: (userId) =>
    fokoServices.get(USER(userId)).then((res) => res.data),

  getUser: (user) => {
    const { id, servicesId } = user
    if (servicesId) {
      return UserService.getServicesUser(servicesId).then((servicesUser) =>
        merge({}, user, servicesUser, {
          id: user.id,
          servicesId: servicesUser.id,
        })
      )
    }
    return UserService.getFokoudUser(id).then((fokoudUser) => {
      if (fokoudUser.servicesId) {
        return UserService.getServicesUser(fokoudUser.servicesId).then(
          (servicesUser) =>
            merge({}, fokoudUser, servicesUser, {
              id: fokoudUser.id,
              servicesId: servicesUser.id,
            })
        )
      }
      return fokoudUser
    })
  },

  refreshCurrentUser: (getFokoud = true) =>
    (getFokoud
      ? fokoud.get(USER_ID(Session.getUser()?.id))
      : Promise.resolve({})
    ).then((fokoudUser) => {
      const getServices =
        Session.getOrganizationId() && Session.getServicesUserId()
      return (
        getServices
          ? fokoServices.get(USER(Session.getServicesUserId()))
          : Promise.resolve({})
      ).then((servicesUser) => {
        // @TODO: satisfy linting rules
        servicesUser.servicesId = servicesUser.data.id
        delete servicesUser.id
        // merge user objects; reliance on fokoudUser for communities data
        const mergedUser = merge(fokoudUser, servicesUser.data)
        Session.updateUser(mergedUser)

        // set session language, existing language preference having precedence
        Session.setLanguage(getLanguage(false) || User.getLanguage(mergedUser))

        return mergedUser
      })
    }),
  userLists: () => {
    const userLists = UserLists.get()
    if (userLists) {
      return Promise.resolve(userLists)
    }
    if (!userListPromise) {
      userListPromise = fokoud
        .get(USER_LISTS)
        .then((response) => {
          UserLists.set(response)
          return response
        })
        .finally(() => {
          userListPromise = undefined
        })
    }
    return userListPromise
  },
  updateLanguage: (language) =>
    updateUserLanguagePreference(Session.getUser(), language).then(() => {
      Session.setLanguage(language)
    }),
  update: (user, newData) => fokoServicesUpdateUser(user, newData),

  updateNotifications: (user) =>
    fokoud.put(USER_ID(user.id), user).then((res) => {
      Community.afterUpdateSettings(user?.communitySettings)
      return res
    }),

  updateUserNotifications: (notification) =>
    updateUserNotificationPreferences(Session.getUser(), notification),

  changePassword: (user, newPassword, currentPassword) =>
    adminServices
      .changeUserPassword(user.servicesId, newPassword, currentPassword)
      .then((res) => {
        Session.onPasswordUpdated()
        return res
      }),

  findUsers: (userIds, includeAvatars) =>
    fokoServices
      .post(FIND_USERS, { userIds, includeAvatars })
      .then((res) => res.data.found),

  /**
   * findUserlists
   *
   * @param {Array} userListIds - Userlist ids array
   * @returns {Promise}
   */
  findUserlists: (userListIds) =>
    fokoServices
      .post(FIND_USERLISTS, { userListIds })
      .then((res) => res.data.found),

  /**
   * exportUserData
   *
   * @param {String} userId - User id
   * @returns {Promise}
   */
  exportUserData: (userId) =>
    fokoServices
      .get(EXPORT_USER(userId))
      .then((res) => exportFile(res.data, `User-Data-${userId}`, 'csv')),

  ERRORS: {
    WEAK_PASSWORD: 'WEAK_PASSWORD',
    PASSWORD_PREVIOUSLY_USED: 'PASSWORD_PREVIOUSLY_USED',
    WRONG_CURRENT_PASSWORD: 'WRONG_CURRENT_PASSWORD',
    ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
    PLATFORM_LOGIN_DISABLED: 'PLATFORM_LOGIN_DISABLED',
    ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
  },
}
