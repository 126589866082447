/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import differenceWith from 'lodash/differenceWith'
import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import reject from 'lodash/reject'
import filter from 'lodash/filter'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import sortBy from 'lodash/sortBy'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { isAfter, isPast } from 'date-fns'
import {
  rawContentStateToHtml,
  htmlToContentState,
} from '../../../components/wysiwyg/utils/content-state'
import {
  dispatchEvent,
  BROADCAST_ACTIVITY_CHANGED,
  BROADCAST_UPDATED,
} from '../../../events'
import { BroadcastService } from '../api/broadcast'
import { Image } from '../../core/models/image'
import { getAttributeGroups } from '../../../utils/attributes'
import {
  getCleanHTMLConversion,
  removeHtmlTags,
} from '../../../utils/html-conversion'
import { SharedService } from '../../../api/shared'
import { Community } from '../../core/models/community'
import { User } from '../../core/models/user'
import { tr } from '../../../i18n'
import { StepsValidations } from '../../../utils/steps-validations'

const MAX_TITLE_LENGTH = 100
const MAX_DESCRIPTION_LENGTH = 10000

// Broadcast creation steps
const Steps = Object.freeze({
  POST: 'post',
  SCHEDULING: 'scheduling',
  AUDIENCE: 'audience',
  REVIEW: 'review',
})

// Broadcast statuses
const Status = Object.freeze({
  DRAFT: 'DRAFT',
  GATED: 'GATED',
  SCHEDULED: 'SCHEDULED',
  LIVE: 'LIVE',
})

let badges

const newBroadcast = () => ({
  _priority: 'NORMAL',
})

const isLive = (broadcast) => broadcast?.status === Status.LIVE

const isGated = (broadcast) =>
  broadcast?.id && broadcast?.status === Status.GATED

const isPlacesEnabled = (broadcast) => !isEmpty(broadcast._roles)

const isAttributesEnabled = (broadcast) =>
  isPlacesEnabled(broadcast) && !isEmpty(broadcast._places)

const sanitize = (_broadcast) => {
  const broadcast = {
    details: {
      title: _broadcast._title,
      description: getCleanHTMLConversion(_broadcast._description),
      departmentId: _broadcast?._department?.id,
      priority: _broadcast?._priority,
    },
    images: null,
    documents: null,
    audience: {
      userIds: null,
      userListIds: null,
      allRoles: false,
      roleIds: null,
      allPlaces: false,
      places: null,
      attributeGroups: null,
    },
  }

  if (!isEmpty(_broadcast._images)) {
    broadcast.images = map(_broadcast?._images, (image) => ({
      id: image.id,
      description: image?._meta?.content,
    }))
  }

  const removed = differenceWith(
    _broadcast.attachments,
    _broadcast._documents,
    (attachment, document) => attachment.id === document.id
  )

  broadcast.attachments = {
    added:
      _broadcast._documents.length > 0
        ? map(_broadcast._documents, 'id')
        : null,
    removed: removed?.length > 0 ? map(removed, 'id') : null,
  }

  if (_broadcast.id) {
    broadcast.broadcastId = _broadcast.id
  }

  if (!isLive(_broadcast) || !_broadcast.id) {
    broadcast.schedule = {
      visibleDate: _broadcast.startAt
        ? moment(_broadcast.startAt).valueOf()
        : null,
      archiveDate: _broadcast.endAt ? moment(_broadcast.endAt).valueOf() : null,
    }
  }

  if (!isEmpty(_broadcast._assignees)) {
    const userIds = compact(
      map(_broadcast?._assignees, (assignee) => {
        if (assignee?.type !== 'userList') {
          return assignee.id
        }
      })
    )
    if (!isEmpty(userIds)) {
      broadcast.audience.userIds = userIds
    }
    const userListIds = compact(
      map(_broadcast?._assignees, (assignee) => {
        if (assignee?.type === 'userList') {
          return assignee.id
        }
      })
    )
    if (!isEmpty(userListIds)) {
      broadcast.audience.userListIds = userListIds
    }
  }

  if (!isEmpty(_broadcast._roles)) {
    if (_broadcast._roles === 'all') {
      broadcast.audience.allRoles = true
    } else {
      broadcast.audience.roleIds = map(_broadcast?._roles, ({ id }) => id)
    }
  }

  if (!isEmpty(_broadcast._places) && isPlacesEnabled(_broadcast)) {
    if (_broadcast._places === 'all') {
      broadcast.audience.allPlaces = true
    } else {
      broadcast.audience.places = map(_broadcast?._places, (place) => ({
        placeId: place.id,
        isFullySelected: !place.isPartiallySelected,
      }))
    }
  }

  const attributeGroups = getAttributeGroups(_broadcast._attributes)
  if (!isEmpty(attributeGroups) && isAttributesEnabled(_broadcast)) {
    broadcast.audience.attributeGroups = attributeGroups
  }

  return broadcast
}

/**
 * desanitizeAudience
 *
 * @description Returns desanitize audience information to load on edit broadcast
 * @param {object} _audience The audience object
 * @return {object} The broadcast info with the audience desanitized data
 */
const desanitizeAudience = (_audience) => {
  const broadcast = {}
  broadcast._roles = _audience?.includeAllRoles ? 'all' : _audience?.roles
  broadcast._places = _audience?.includeAllPlaces
    ? 'all'
    : map(_audience?.places, (place) => ({
        ...place,
        ...{
          isPartiallySelected: !place.isFullySelected,
        },
      }))

  // Assignees
  broadcast._assignees = User.jointUsers(_audience)

  // Attributes
  const { allAttributes } = _audience
  const attributeGroups = []
  const groups = _audience?.attributeGroups || _audience?.attributes
  forEach(groups, (group) => {
    const newGroup = []
    forEach(group.isAttributes, (attributeId) => {
      const attribute = find(allAttributes, { id: attributeId })
      if (attribute) {
        newGroup.push({
          _attribute: attribute,
        })
      }
    })
    forEach(group.isNotAttributes, (attributeId) => {
      const attribute = find(allAttributes, { id: attributeId })
      if (attribute) {
        newGroup.push({
          _attribute: attribute,
          isNot: true,
        })
      }
    })
    attributeGroups.push(compact(newGroup))
  })
  broadcast._attributes = attributeGroups

  return broadcast
}

const desanitize = (_broadcast, isCopying) => {
  const broadcast = cloneDeep(_broadcast)
  if (isCopying) {
    delete broadcast.id
  }
  broadcast._id = _broadcast.id
  broadcast._title = _broadcast.title
  broadcast._description = htmlToContentState(_broadcast.description)
  broadcast._department = _broadcast.department
  broadcast._priority = _broadcast.priority
  broadcast._images = _broadcast.images

  merge(broadcast, desanitizeAudience(broadcast.audience))

  broadcast.startAt = _broadcast.visibleDate
  broadcast.endAt = _broadcast.archiveDate

  // Attachments
  broadcast._documents = _broadcast.attachments

  return broadcast
}

const image = (broadcast, desiredSize) =>
  Image.getSize(broadcast?.images?.[0] || broadcast, desiredSize)

const imageUrl = (broadcast, desiredSize) => {
  const img = image(broadcast, desiredSize)
  return img?.url
}

const associations = (_broadcast) => {
  const users = reject(_broadcast._assignees, { type: 'userList' })
  const userLists = filter(_broadcast._assignees, { type: 'userList' })
  const includeAllRoles = _broadcast._roles === 'all'
  const includeAllPlaces = _broadcast._places === 'all'
  const places = filter(_broadcast._places, { isPartiallySelected: true })
  const parentPlaces = reject(_broadcast._places, {
    isPartiallySelected: true,
  })
  const attributeGroups = getAttributeGroups(_broadcast._attributes)

  return pickBy(
    {
      userIds: !isEmpty(users) ? map(users, 'id') : undefined,
      userListIds: !isEmpty(userLists) ? map(userLists, 'id') : undefined,
      includeAllRoles,
      roleIds:
        includeAllRoles || isEmpty(_broadcast._roles)
          ? undefined
          : map(_broadcast._roles, 'id'),
      includeAllPlaces,
      placeIds:
        includeAllPlaces || isEmpty(places) || !isPlacesEnabled(_broadcast)
          ? undefined
          : map(places, 'id'),
      parentPlaceIds:
        includeAllPlaces ||
        isEmpty(parentPlaces) ||
        !isPlacesEnabled(_broadcast)
          ? undefined
          : map(parentPlaces, 'id'),
      attributeGroups:
        isEmpty(attributeGroups) || !isAttributesEnabled(_broadcast)
          ? undefined
          : attributeGroups,
    },
    identity
  )
}

const setUnreadCount = (activity) => {
  if (!badges) {
    badges = {}
  }
  badges.unreadCount = activity
  dispatchEvent(BROADCAST_ACTIVITY_CHANGED)
}

const setBadging = (res) => {
  badges = res
  dispatchEvent(BROADCAST_ACTIVITY_CHANGED)
}

const getHasNewActivity = () => badges?.unreadCount > 0

const getUnreadCount = () => badges?.unreadCount

const getScheduledCount = () => badges?.scheduledCount

const getDraftCount = () => badges?.draftCount

const getGatedCount = () => badges?.gatedCount

const getLastUnreadMessage = () => badges?.unreadBroadcastId

const handleBroadcastCreated = (broadcast) => {
  const unreadCount = (badges?.unreadCount || 0) + 1
  if (!badges) {
    badges = {}
  }
  if (isEmpty(badges.unreadBroadcastId)) {
    badges.unreadBroadcastId = broadcast.broadcastId
  }
  setUnreadCount(unreadCount)
}

const Errors = {
  NO_TITLE: 0,
  NO_DESCRIPTION: 1,
  DESCRIPTION_TOO_LONG: 2,
  NO_DEPARTMENT: 3,
  NO_ASSIGNEES: 4,
  IS_AND_ISNOT_HAVE_SAME_ATTRIBUTE: 5,
  DOCUMENT_NOT_UPLOADED: 6,
  NO_ROLES: 7,
  NO_PLACES: 8,
  START_DATE_IN_THE_PAST: 9,
  ARCHIVE_DATE_IN_THE_PAST: 10,
  ARCHIVE_DATE_EARLIER_THAN_VISIBLE_DATE: 11,
}

const hasContradictoryPremises = (group) => {
  const sortedAttributes = sortBy(group, '_attribute.id') || []
  for (let i = 0; i < sortedAttributes.length; i++) {
    if (
      sortedAttributes?.[`${i + 1}`]?._attribute?.id ===
        sortedAttributes?.[`${i}`]?._attribute?.id &&
      sortedAttributes?.[`${i + 1}`]?.isNot !==
        sortedAttributes?.[`${i}`]?.isNot
    ) {
      return true
    }
  }
  return false
}

const getScheduleErrors = (errors) => ({
  pastStartDate: errors?.includes(Errors.START_DATE_IN_THE_PAST)
    ? tr('broadcasts.errors.visibleDateInThePast')
    : undefined,
  pastArchiveDate: errors?.includes(Errors.ARCHIVE_DATE_IN_THE_PAST)
    ? tr('broadcasts.errors.archiveDateInThePast')
    : undefined,
  archiveEarlierThanStart: errors?.includes(
    Errors.ARCHIVE_DATE_EARLIER_THAN_VISIBLE_DATE
  )
    ? tr('broadcasts.errors.archiveBeforeVisible')
    : undefined,
})

const checkForSchedulingErrors = (broadcast) => {
  const errors = []
  if (
    broadcast?.endAt &&
    isAfter(new Date(broadcast?.startAt), new Date(broadcast?.endAt))
  ) {
    errors.push(Errors.ARCHIVE_DATE_EARLIER_THAN_VISIBLE_DATE)
  }
  if (!isLive(broadcast) && isPast(new Date(broadcast.startAt))) {
    errors.push(Errors.START_DATE_IN_THE_PAST)
  }
  if (!isLive(broadcast) && isPast(new Date(broadcast.endAt))) {
    errors.push(Errors.ARCHIVE_DATE_IN_THE_PAST)
  }
  return errors
}

const checkForAllErrors = (broadcast) => {
  const errors = checkForSchedulingErrors(broadcast)
  if (isEmpty(broadcast?._title?.trim())) {
    errors.push(Errors.NO_TITLE)
  }
  const description = rawContentStateToHtml(broadcast?._description)
  const htmlRemoved = removeHtmlTags(description)?.trim()
  if (isEmpty(htmlRemoved)) {
    errors.push(Errors.NO_DESCRIPTION)
  }
  if (htmlRemoved.length > MAX_DESCRIPTION_LENGTH) {
    errors.push(Errors.DESCRIPTION_TOO_LONG)
  }
  if (isNil(broadcast?._department) || isEmpty(broadcast?._department)) {
    errors.push(Errors.NO_DEPARTMENT)
  }
  if (
    !isEmpty(broadcast?._documents) &&
    find(broadcast?._documents, (document) => isUndefined(document.id))
  ) {
    errors.push(Errors.DOCUMENT_NOT_UPLOADED)
  }
  if (isEmpty(broadcast?._assignees) && isEmpty(broadcast?._roles)) {
    errors.push(Errors.NO_ASSIGNEES)
  }
  if (!isEmpty(broadcast?._roles) && isEmpty(broadcast?._places)) {
    errors.push(Errors.NO_PLACES)
  }

  forEach(broadcast?._attributes, (group) => {
    if (hasContradictoryPremises(group)) {
      errors.push(Errors.IS_AND_ISNOT_HAVE_SAME_ATTRIBUTE)
      return null
    }
  })
  return errors
}

const checkForSaveErrors = (broadcast) => {
  const errors = checkForSchedulingErrors(broadcast)
  if (isEmpty(broadcast?._title?.trim())) {
    errors.push(Errors.NO_TITLE)
  }
  return errors
}

const hasAssigneeErrors = (errors) =>
  errors?.includes(Errors.NO_ASSIGNEES) ||
  errors?.includes(Errors.NO_ROLES) ||
  errors?.includes(Errors.NO_PLACES)

const hasRolesErrors = (broadcast) =>
  isEmpty(broadcast?._roles) &&
  (!isEmpty(broadcast?._attributes) || !isEmpty(broadcast?._places))

const hasPlaceErrors = (errors) => errors?.includes(Errors.NO_PLACES)

const loadSingleDocument = (broadcast, document) => {
  const updatedBroadcast = { ...broadcast }
  document.retryCount = isUndefined(document.retryCount)
    ? 1
    : document.retryCount
  return BroadcastService.getDocuments([document.id])
    .then((res) => {
      const attachment = res?.data?.[0]

      const newDoc = map(broadcast.attachments, (doc) => {
        if (doc.id === attachment.id) {
          attachment.retryCount = doc.retryCount + 1
          if (attachment.error && attachment.retryCount > 3) {
            attachment.error = 'DOCUMENT_NOT_FOUND'
          }
          return attachment
        }
        return doc
      })

      updatedBroadcast.attachments = newDoc
    })
    .catch((e) => {
      const newDoc = map(updatedBroadcast.attachments, (doc) => {
        if (doc.id === document.id) {
          return {
            error: doc.retryCount + 1 <= 3 ? e?.message : 'DOCUMENT_NOT_FOUND',
            id: doc.id,
            retryCount: doc.retryCount + 1,
          }
        }
        return doc
      })

      updatedBroadcast.attachments = newDoc
    })
    .finally(() => {
      dispatchEvent(BROADCAST_UPDATED, updatedBroadcast)
    })
}

const loadDocuments = (broadcast) => {
  if (isEmpty(broadcast.attachmentIds)) {
    return
  }
  if (broadcast.isLoadingAttachments) {
    return
  }
  broadcast.isLoadingAttachments = true
  const updatedBroadcast = cloneDeep(broadcast)
  return BroadcastService.getDocuments(broadcast.attachmentIds)
    .then((attachments) => {
      updatedBroadcast.attachments = attachments
    })
    .catch((e) => {
      updatedBroadcast.attachments = map(
        updatedBroadcast.attachments,
        (attachment) => ({
          error: e?.message,
          id: attachment.id,
        })
      )
    })
    .finally(() => {
      dispatchEvent(BROADCAST_UPDATED, updatedBroadcast)
    })
}

const loadImages = (broadcast) => {
  if (isEmpty(broadcast.imageIds)) {
    return
  }
  if (broadcast.isLoadingImages) {
    return
  }
  broadcast.isLoadingImages = true
  return SharedService.getFiles(broadcast.imageIds)
    .then((images) => {
      const updatedBroadcast = cloneDeep(broadcast)
      merge(updatedBroadcast.images, images)
      dispatchEvent(BROADCAST_UPDATED, updatedBroadcast)
      return updatedBroadcast
    })
    .catch(() => {
      delete broadcast.isLoadingImages
    })
}

const loadFullBroadcast = async (broadcast) =>
  Promise.resolve(loadImages(broadcast)).then((updatedBroadcast) =>
    loadDocuments(updatedBroadcast || broadcast)
  )

/**
 * isLoadingFiles
 *
 * @description checks whether images or documents are being loaded
 *
 * @param {object} files - files data containing an array of images and an array of documents (i.e. {
 *              _images: [image, image...],
 *              _documents: [document, document...],
 *             }
 * @returns {boolean} returns a boolean. true if there are files being loaded, false otherwise.
 */
const isLoadingFiles = (files) => {
  const isLoadingImages = files._images?.some(
    (file) => file.progress < 100 && !file.id
  )

  const isLoadingDocuments = files._documents?.some(
    (file) => file.progress < 100 && !file.id
  )
  return isLoadingImages || isLoadingDocuments
}

/**
 * Evaluates whether the audience can be counted,
 * disregarding all the possibilities in which the counter must remain zero
 * @param {object} audience date to be formatted
 * @returns {boolean} returns true if there is no reason not to call the counter
 */
const canCountAudience = (audience) => {
  if (isEmpty(audience)) {
    return false
  }
  if (
    !audience.roleIds &&
    !audience.includeAllRoles &&
    !audience.userIds &&
    !audience.userListIds
  ) {
    return false
  }
  if (
    (!!audience.roleIds || audience.includeAllRoles) &&
    !audience.parentPlaceIds &&
    !audience.includeAllPlaces
  ) {
    return false
  }
  return true
}

/**
 * isGatekeeperEnabled
 *
 * @description Check if user is gatekeeper and broadcaster
 * @returns True if user is gatekeeper and broadcaster
 */
const isGatekeeperEnabled =
  Community.isGateKeeperSettingEnabled() &&
  Community.isBroadcastGateKeeperEnabled()

/**
 * isBroadcasterEditingScheduled
 *
 * @description determines if the broadcaster is editing a scheduled broadcast
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if broadcaster is editing scheduled broadcast
 */
const isBroadcasterEditingScheduled = (broadcast) =>
  isGatekeeperEnabled &&
  User.isBroadcasterOnly() &&
  broadcast.status === Status.SCHEDULED

/**
 * isBroadcasterEditingLive
 *
 * @description determines if the broadcaster is editing a live broadcast
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if broadcaster is editing live broadcast
 */
const isBroadcasterEditingLive = (broadcast) =>
  isGatekeeperEnabled &&
  User.isBroadcasterOnly() &&
  broadcast.status === Status.LIVE

/**
 * saveBtnLabel
 *
 * @description Returns the text to use in the Save button when editing an existing broadcast
 * @param {object} broadcast Broadcast data object
 * @returns {string} Save button text
 */
const saveBtnLabel = (broadcast) => {
  if (isBroadcasterEditingScheduled(broadcast)) {
    return tr('broadcasts.schedule')
  }
  if (isBroadcasterEditingLive(broadcast)) {
    return tr('submit')
  }
  return tr('save')
}

/**
 * canApprove
 *
 * @description Checks if this broadcast can be approved
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if this broadcast can be approved
 */
const canApprove = (broadcast) =>
  isGated(broadcast) &&
  isEqual(StepsValidations.getStep(window?.location?.pathname), Steps.REVIEW)

/**
 * isEditing
 *
 * @description If broadcast is being edited
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if broadcast id exists
 */
const isEditing = (broadcast) => !isNil(broadcast?.id)

/**
 * canSubmit
 *
 * @description Checks if this broadcast can be submitted
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if broadcast can be submitted
 */
const canSubmit = (broadcast) =>
  isEqual(StepsValidations.getStep(window?.location?.pathname), Steps.REVIEW) &&
  (!isEditing(broadcast) || broadcast.status === Status.DRAFT)

/**
 * saveBtnLabel
 *
 * @description Returns the text to use in the Save button when editing an existing broadcast
 * @param {object} broadcast Broadcast data object
 * @returns {string} Save button text
 */
const nextBtnText = (broadcast) => {
  if (canApprove(broadcast)) {
    return tr('broadcasts.approve')
  }
  if (
    isEqual(StepsValidations.getStep(window?.location?.pathname), Steps.REVIEW)
  ) {
    if (isNil(broadcast.startAt) && isNil(broadcast.endAt)) {
      return tr('submit')
    }
    return tr('broadcasts.schedule')
  }
}

/**
 * isPostDisabled
 *
 * @description Disable posting the broadcast
 * @param {object} broadcast Broadcast data object
 * @returns {boolean}
 */
const isPostDisabled = (broadcast) => !isEmpty(checkForAllErrors(broadcast))

/**
 * isNextDisabled
 *
 * @description Determines when Next button for the broadcast navigation is disabled
 * @param {object} broadcast Broadcast data object
 * @returns {boolean} True if Next button should be disabled
 */
const isNextDisabled = (broadcast) =>
  isEqual(StepsValidations.getStep(window?.location?.pathname), Steps.REVIEW) &&
  isPostDisabled(broadcast)

export const Broadcast = {
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  getUnreadCount,
  desanitizeAudience,
  desanitize,
  newBroadcast,
  sanitize,
  image,
  imageUrl,
  associations,
  setUnreadCount,
  setBadging,
  getHasNewActivity,
  getLastUnreadMessage,
  handleBroadcastCreated,
  getScheduledCount,
  getDraftCount,
  getGatedCount,
  getScheduleErrors,
  checkForSchedulingErrors,
  checkForAllErrors,
  checkForSaveErrors,
  hasAssigneeErrors,
  hasRolesErrors,
  hasPlaceErrors,
  loadFullBroadcast,
  isLive,
  isGated,
  loadSingleDocument,
  isPlacesEnabled,
  isAttributesEnabled,
  isLoadingFiles,
  canCountAudience,
  isGatekeeperEnabled,
  isBroadcasterEditingScheduled,
  isBroadcasterEditingLive,
  saveBtnLabel,
  nextBtnText,
  canSubmit,
  canApprove,
  isPostDisabled,
  isEditing,
  isNextDisabled,
  Steps,
  Status,
  Errors,
}
