/**
 * getStep
 *
 * @description Returns the step according to location
 * @param {string} location Location path
 * @return {string} step i.e. 'post' or 'assigned'
 */
const getStep = (location) => {
  const locationSplit = location?.split('/')
  return locationSplit?.[locationSplit?.length - 1]
}

/**
 * getNextStep
 *
 * @description Get the next step
 * @param {array} steps An array containing the steps in order
 * @param {string} currentStep The current step
 * @returns {string} The next step
 */
const getNextStep = (steps, currentStep) => {
  const stepIndex = steps.indexOf(currentStep)
  return stepIndex + 1 > steps.length - 1
    ? steps[stepIndex]
    : steps[stepIndex + 1]
}

/**
 * getPrevStep
 *
 * @description Get the previous step
 * @param {array} steps An array containing the steps in order
 * @param {string} currentStep The current step
 * @returns {string} The previous step
 */
const getPrevStep = (steps, currentStep) => {
  const stepIndex = steps.indexOf(currentStep)
  return stepIndex - 1 < 0 ? steps[stepIndex] : steps[stepIndex - 1]
}

export const StepsValidations = {
  getStep,
  getNextStep,
  getPrevStep,
}
