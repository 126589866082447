import _ from 'lodash'
import axios from 'axios'
import { Session, authToken } from '../modules/core/models/session'
import { Socket } from '../modules/core/api/websocket'
import { Config } from '../config'

/**
 * getBaseUrl
 *
 * @description Returns the correct request baseURL to be used in the axios configuration
 * Uses the baseURL stored in local storage if there is any, otherwhise use the default api host configuration
 * @return {string} Returns the baseURL to be used in the axios configuration
 */
const getBaseUrl = () =>
  Session.getBaseApiUrl()
    ? `${Session.getBaseApiUrl()}/api/v17`
    : `${Config.apiHost()}/api/v17`

const createFokoud = (asAdmin = false) => {
  const fokoud = axios.create()
  fokoud.defaults.headers.post['Content-Type'] = 'application/json'
  fokoud.defaults.headers.common.Authorization = authToken
  fokoud.defaults.headers.Pragma = 'no-cache'
  if (asAdmin) {
    fokoud.defaults.headers['x-foko-admin'] = true
  }

  /**
   * @description Intercept request to ensure correct baseURL is being used (multi-region support)
   * @return {object} Returns the merged request object using the defined baseURL
   */

  fokoud.interceptors.request.use((request) => ({
    ...request,
    ...{
      baseURL: getBaseUrl(),
    },
  }))

  fokoud.interceptors.response.use(
    (response) => response.data,
    (error) => {
      const status = _.get(error, 'response.status')
      if (authToken && status === 401) {
        // UNAUTHORIZED
        Session.onLogout(true)
      }
      return Promise.reject(error)
    }
  )
  return fokoud
}

export const fokoud = createFokoud()
export const fokoudAdmin = createFokoud(true)

export const connectOldWebScoket = () => {
  if (authToken) {
    const url = Session.getWebsocketUrl()
    if (url) {
      Socket.connect(url)
    } else {
      Socket.get().then((websocketUrl) => {
        Session.setWebsocketUrl(websocketUrl)
        Socket.connect(Session.getWebsocketUrl())
      })
    }
  }
}

connectOldWebScoket()
