import _ from 'lodash'

// Fails to build when rule (node/no-process-env) is added to the eslint-disable

// process can be undefined whilst process.env being defined (react-scripts v5)
// q.v. https://github.com/facebook/create-react-app/issues/11951#issuecomment-1208642012
const processEnv = process.env || {} // eslint-disable-line
const injectedEnv = window && window.injectedEnv ? window.injectedEnv : {}

// injectedEnv is sent during docker container startup via scripts/97-inject-env.sh
// environement variables should be accessed by importing config.js
// direct access to process.env is disabled by eslint rule
const env = {
  ...processEnv,
  ...injectedEnv,
}

const apiHost = () => env.REACT_APP_API_HOST
const appId = () => env.REACT_APP_APPLICATION_ID
const appVersion = () => env.REACT_APP_VERSION
const authenticatedHost = () => {
  // this line is local dev
  if (window.location.origin === env.REACT_APP_AUTHENTICATED_HOST) {
    return
  }
  return env.REACT_APP_AUTHENTICATED_HOST
}
const reactAppUrl = () => env.REACT_APP_AUTHENTICATED_HOST
const brandedUniqueName = () => env.REACT_APP_BRANDING_UNIQUE_NAME
const buildType = () => env.REACT_APP_BUILD_TYPE
const googleMapsApiKey = () => env.REACT_APP_GOOGLE_MAPS_API_KEY
const isBranded = () =>
  Boolean(env.REACT_APP_IS_BRANDED) === true || !_.isNil(brandedUniqueName())
const isDevelopment = () => env.NODE_ENV === 'development'
const sentryDSN = () => env.REACT_APP_SENTRY_DSN
// For local dev, use a separate URL otherwise you need a local ingress controller to route traffic
const servicesHost = () => env.REACT_APP_SERVICES_HOST || env.REACT_APP_API_HOST

export const Config = {
  apiHost,
  appId,
  appVersion,
  authenticatedHost,
  brandedUniqueName,
  buildType,
  googleMapsApiKey,
  isBranded,
  isDevelopment,
  sentryDSN,
  servicesHost,
  reactAppUrl,
}
