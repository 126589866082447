import _ from 'lodash'
import {
  convertFromRaw,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import draftToHtml from 'draftjs-to-html'

export const rawContenStateToMarkdown = (rawContentState) => {
  if (_.isNil(rawContentState)) {
    return undefined
  }
  const contentState = convertFromRaw(rawContentState)
  if (_.isEmpty(_.trim(contentState.getPlainText()))) {
    return undefined
  }
  return draftToMarkdown(rawContentState)
}

export const markdownToRawContenState = (markdown) =>
  markdownToDraft(markdown || '')

// Sanitize links that do not start with these protocols: http, https or ftp
const sanitizeLinks = (html) => {
  const anchorTagRegex =
    /<a\s+(?:[^>]*?\s+)?href\s*=\s*(['"])([^(http|https|ftp)]+)(.*?)\1/gi
  let anchorTag = anchorTagRegex.exec(html)
  const HREF = 'href="'
  while (anchorTag !== null) {
    const link = anchorTag[0]
    const linkStartPosition = link.indexOf(HREF)
    const linkEndPosition = link.length - 1
    const url = link.substring(linkStartPosition + HREF.length, linkEndPosition)
    // eslint-disable-next-line no-param-reassign
    html = _.replace(html, url, `http://${encodeURIComponent(url)}`)
    anchorTag = anchorTagRegex.exec(html)
  }
  return html
}

export const rawContentStateToHtml = (rawContentState) => {
  const html = draftToHtml(rawContentState)
  const decoded = _.unescape(html)
  return sanitizeLinks(decoded)
}

export const htmlToContentState = (html) => {
  const blocksFromHtml = convertFromHTML(html || '')
  const state = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  )
  return convertToRaw(state)
}

/**
 * Receives the editor state and returns the length of the selected text
 */
export const getSelectedTextLength = (editorState) => {
  const currentSelection = editorState.getSelection()
  const isCollapsed = currentSelection.isCollapsed()
  let length = 0
  if (!isCollapsed) {
    const currentContent = editorState.getCurrentContent()
    const startKey = currentSelection.getStartKey()
    const endKey = currentSelection.getEndKey()
    const startBlock = currentContent.getBlockForKey(startKey)
    const isStartAndEndBlockAreTheSame = startKey === endKey
    const startBlockTextLength = startBlock.getLength()
    const startSelectedTextLength =
      startBlockTextLength - currentSelection.getStartOffset()
    const endSelectedTextLength = currentSelection.getEndOffset()
    const keyAfterEnd = currentContent.getKeyAfter(endKey)
    if (isStartAndEndBlockAreTheSame) {
      length +=
        currentSelection.getEndOffset() - currentSelection.getStartOffset()
    } else {
      let currentKey = startKey
      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1
        } else if (currentKey === endKey) {
          length += endSelectedTextLength
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1
        }

        currentKey = currentContent.getKeyAfter(currentKey)
      }
    }
  }
  return length
}
