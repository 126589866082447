import { dispatchEvent, TASKS_ACTIVITY_CHANGED } from '../../../events'

let activityBadges
/**
 * setActivityBadge
 *
 * Assigns the task badges information and persist its value in activityBadges
 * Dispatches TASKS_ACTIVITY_CHANGED event to show the live update.
 * @param {object} badge The badge object i.e. {
 *                        activeAssignments: 22,
 *                        ...}
 */
const setActivityBadge = (badge) => {
  activityBadges = badge
  dispatchEvent(TASKS_ACTIVITY_CHANGED)
}

export const TaskBadges = {
  setActivityBadge,
  getActivityBadge: () => activityBadges,
}
