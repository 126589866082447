import result from 'lodash/result'
import { Router } from './router'
import { deeplinkToRoute } from './deeplink'
import { Branding } from './branding'
import { Community } from '../modules/core/models/community'
import logo from '../resources/logo.png'

let enabled = false
const init = () => {
  const { Notification } = window
  if (!Notification) {
    return
  }
  if (Notification.permission === 'default') {
    Notification.requestPermission((permission) => {
      enabled = permission === 'granted'
    })
  } else {
    enabled = Notification.permission === 'granted'
  }
}
init()

const process = (data) => {
  if (!enabled) {
    return
  }
  const title = data?.actorName
  const body = data?.content
  const icon = data?.imageUrl
    ? data?.imageUrl
    : Branding.logoUrl(Community.getBranding()) || logo

  const notification = new Notification(title, {
    body,
    icon,
  })
  notification.onclick = () => {
    result(window, 'focus')
    Router.routeTo(deeplinkToRoute(data?.deeplink))
    notification.close()
  }
}

export const PushNotification = {
  process,
}
