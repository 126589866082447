import { Messages } from '../../models/message'
import { Channels } from '../../models/channel'
import { ChannelsService } from '../channel'
import { MessagesService } from '../message'
import { CommunitiesService } from '../community'
import { dispatchEvent, APP_RECONNECT } from '../../../../events'
import { Community } from '../../models/community'

const refresh = () => {
  const loadChannels = !!Channels.get() && Community.isCommsEnabled()
  const loadMessages = !!Messages.get() && Community.isCommsEnabled()
  if (loadChannels) {
    ChannelsService.get(true)
  }
  if (loadMessages) {
    MessagesService.get(true)
  }
  const loadChannelBadge = !loadChannels
  const loadMessageBadge = !loadMessages

  CommunitiesService.badges(
    loadChannelBadge,
    loadMessageBadge,
    Community.isTasksEnabled(),
    Community.isNewTasksEnabled()
  )

  dispatchEvent(APP_RECONNECT)
}

export default refresh
