import _ from 'lodash'

export const sortBy = (sortString) => {
  if (_.isEmpty(sortString)) {
    return
  }
  const [propertyName, order] = _.split(sortString, ' ')
  return {
    propertyName,
    order: _.toUpper(order),
  }
}
