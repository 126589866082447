/* eslint-disable no-param-reassign */
import merge from 'lodash/merge'
import map from 'lodash/map'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import unionBy from 'lodash/unionBy'
import clone from 'lodash/clone'
import { DEFAULT_LIMIT } from '../../core/api/constants'
import { fokoServices } from '../../../api/services'
import { fokoud } from '../../../api'
import { Session, getCommunityId } from '../../core/models/session'
import { sortBy } from '../../../api/services/sort-by'
import { Broadcast } from '../models/broadcast'
import { SharedService } from '../../../api/shared'
import { UserService } from '../../core/api/user'

const MAX_BROADCAST_MESSAGES = 5

const findAttributes = (attributeIds) =>
  fokoServices
    .post(`/organizations/${Session.getOrganizationId()}/attributes/find`, {
      attributeIds,
    })
    .then((res) => res.data.found)

const getDocuments = (documentIds) =>
  fokoud.post('/documents/get', { documentIds })

const createDocument = (fileId) =>
  fokoud.post('/documents', {
    fileId,
    communityId: getCommunityId(),
  })

const findRoles = (roleIds) =>
  fokoServices
    .post(`/organizations/${Session.getOrganizationId()}/roles/find`, {
      roleIds,
    })
    .then((res) => res.data.found)

const findPlaces = (placeIds) =>
  fokoServices
    .post(`/organizations/${Session.getOrganizationId()}/places/find`, {
      placeIds,
    })
    .then((res) => res.data.found)

const createDocuments = async (_broadcast) => {
  const existingDocuments = filter(
    get(_broadcast, '_documents'),
    (document) => !isUndefined(document.fileId)
  )
  const fileIds = map(
    filter(get(_broadcast, '_documents'), (document) =>
      isUndefined(document.fileId)
    ),
    'id'
  )
  const newDocuments = []
  if (!isEmpty(fileIds)) {
    await Promise.mapSeries(fileIds, (fileId) =>
      createDocument(fileId).then((document) => newDocuments.push(document))
    )
  }
  return concat(existingDocuments, newDocuments)
}

const loadAudience = async (_audience = {}) => {
  const audience = cloneDeep(_audience)

  // fetch users and userlist
  audience.users = isEmpty(get(_audience, 'userIds'))
    ? []
    : await UserService.findUsers(get(_audience, 'userIds'))

  audience.userLists = isEmpty(get(_audience, 'userListIds'))
    ? []
    : await UserService.findUserlists(get(_audience, 'userListIds'))

  // fetch roles
  audience.roles = isEmpty(get(_audience, 'roleIds'))
    ? []
    : await findRoles(get(_audience, 'roleIds'))

  // fetch places
  const places = isEmpty(get(_audience, 'places'))
    ? []
    : await findPlaces(map(get(_audience, 'places'), 'placeId'))
  audience.places = map(places, (place) => {
    const audiencePlace = find(get(_audience, 'places'), ['placeId', place.id])
    return merge(place, audiencePlace)
  })

  // fetch attributes
  const groups = _audience.attributeGroups || _audience.attributes
  let allAttributesIds = []
  forEach(groups, (group) => {
    allAttributesIds = concat(
      allAttributesIds,
      group.isAttributes,
      group.isNotAttributes
    )
  })
  audience.allAttributes = isEmpty(allAttributesIds)
    ? []
    : await findAttributes(allAttributesIds)

  return audience
}

export const BroadcastService = {
  searchDepartments: (searchValue = '', offset) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/departments/search`,
      {
        limit: DEFAULT_LIMIT,
        offset,
        searchValue,
      }
    ),
  save: async (broadcast, isSubmit) => {
    const broadcastCopy = broadcast
    broadcastCopy._documents = await createDocuments(broadcastCopy)
    const sanitizedBroadcast = Broadcast.sanitize(broadcastCopy)
    return fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/broadcasts/save`,
      merge(sanitizedBroadcast, { isSubmit })
    )
  },

  feed: (offset, limit = DEFAULT_LIMIT) =>
    fokoServices
      .post(
        `/organizations/${Session.getOrganizationId()}/users/${Session.getServicesUserId()}/broadcasts/feed`,
        {
          limit,
          offset,
        }
      )
      .then((data) => {
        const { feed } = data.data
        forEach(feed, (broadcast) => {
          broadcast.attachments = map(
            broadcast.attachmentIds,
            (attachmentId) => ({
              id: attachmentId,
              isLoading: true,
            })
          )
          forEach(broadcast.images, (image) => {
            image.isLoading = true
          })
        })
        return feed
      }),

  search: (section, offset, includeTotal, sort, limit) => {
    let status
    if (section === 'drafts') {
      status = 'DRAFT'
    } else if (section === 'scheduled') {
      status = 'SCHEDULED'
    } else if (section === 'archived') {
      status = 'ARCHIVED'
    } else if (section === 'gated') {
      status = 'GATED'
    }
    return fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/broadcasts/search`,
      {
        includeTotal,
        limit: limit || DEFAULT_LIMIT,
        offset,
        status,
        sortBy: sortBy(sort),
      }
    )
  },
  announcements: () => BroadcastService.feed(0, MAX_BROADCAST_MESSAGES),
  archive: (broadcast) =>
    fokoServices.put(
      `/organizations/${Session.getOrganizationId()}/broadcasts/${
        broadcast.broadcastId || broadcast.id
      }/archive`
    ),
  searchUsersByAssociations: (associations, searchValue = '', offset) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/users/associations/search`,
      merge(associations, {
        limit: DEFAULT_LIMIT,
        offset,
        searchValue,
      })
    ),
  countUsersByAssociations: (associations) => {
    // keep the counter at zero if associations do not meet the valid audience conditions
    return Broadcast.canCountAudience(associations)
      ? fokoServices
          .post(
            `/organizations/${Session.getOrganizationId()}/users/associations/count`,
            associations
          )
          .then((res) => Number(res.data.total))
      : Promise.resolve(0)
  },
  markAsRead: (broadcast) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/broadcasts/read`,
      {
        broadcastIds: [broadcast.broadcastId],
      }
    ),
  badges: () =>
    fokoServices
      .get(
        `/organizations/${Session.getOrganizationId()}/users/${Session.getServicesUserId()}/broadcasts/badges`
      )
      .then((res) => {
        Broadcast.setBadging(res.data)
        return res
      }),
  viewers: async (broadcastId, offset) => {
    const includeTotals = offset === 0
    const res = await fokoServices.get(
      `/organizations/${Session.getOrganizationId()}/broadcasts/${broadcastId}/viewers`,
      {
        params: {
          limit: DEFAULT_LIMIT,
          offset,
          includeTotals,
        },
      }
    )
    const { viewers, viewersCount, audienceCount } = res.data
    const users = isEmpty(viewers)
      ? []
      : await UserService.findUsers(map(viewers, 'id'), true)
    forEach(users, (user) => {
      user.servicesId = user.id
      user.viewedAt = viewers.find((viewer) => viewer.id === user.id).viewedAt
    })
    return {
      viewers: unionBy(users, viewers, 'id'),
      viewersCount,
      audienceCount,
    }
  },
  getViewersReport: (broadcastId) =>
    fokoServices.get(
      `/organizations/${Session.getOrganizationId()}/broadcasts/${broadcastId}/viewersReport`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),
  delete: (broadcastIds) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/broadcasts/delete`,
      {
        broadcastIds,
      }
    ),
  getById: (broadcastId, includeAudience) =>
    fokoServices
      .get(
        `/organizations/${Session.getOrganizationId()}/broadcasts/${broadcastId}`
      )
      .then(async (res) => {
        // fetch documents
        res.data.attachments = isEmpty(get(res.data, 'attachmentIds'))
          ? []
          : await getDocuments(get(res.data, 'attachmentIds'))

        // // fetch images
        const images = isEmpty(get(res.data, 'imageIds'))
          ? []
          : await SharedService.getFiles(get(res.data, 'imageIds'))
        const imageDescriptions = clone(res.data.images)
        res.data.images = map(images, (image) => {
          const img = find(imageDescriptions, { id: image.id })
          image._meta = { content: img.description } // This fits with the wysiwyg image editor format
          return image
        })

        // audience
        if (includeAudience) {
          merge(res.data, { audience: await loadAudience(res.data.audience) })
        }

        res.data.broadcastId = res.data.id

        return res
      }),

  loadFullAudience: (audience) => loadAudience(audience),

  getDocuments,
  findPlaces,
  findAttributes,

  saveAudience: (audience) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/audience`,
      audience
    ),

  searchAudience: (offset, searchValue, includeTotal) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/audience/search`,
      {
        limit: DEFAULT_LIMIT,
        offset,
        searchValue: searchValue || '',
        includeTotal,
      }
    ),

  deleteAudience: (audienceId) =>
    fokoServices.delete(
      `/organizations/${Session.getOrganizationId()}/audience/${audienceId}`
    ),

  approveGatedBroadcast: (broadcastId) =>
    fokoServices.post(
      `/organizations/${Session.getOrganizationId()}/broadcasts/${broadcastId}/approve-gated`
    ),

  ERRORS: {
    DUPLICATE_AUDIENCE_NAME: 'DUPLICATE_AUDIENCE_NAME',
  },

  FEED_LIMIT: DEFAULT_LIMIT,
}
