import _ from 'lodash'

export const SessionDataFactory = (() => ({
  /**
   * Use the exact response from Services to build the session
   *
   * @param {*} response body from the Services Login request
   * @returns {any} data that can be used to create a session
   */
  createFromServicesLoginResponse: (response) => {
    const servicesAccessToken = _.get(response, 'headers.access-token')
    const fokoudAccessToken = _.get(response, 'headers.authorization')
    const fokoudUserId = _.get(response, 'data.fokoudUserId')
    const servicesUserId = _.get(response, 'data.userId')
    // TODO: if we're missing any of these tokens, throw error

    // Construct the services auth data
    const servicesSessionData = _.get(response, 'data')
    servicesSessionData.accessToken = servicesAccessToken

    // Construct the fokoud auth data
    const fokoudSessionData = {
      id: fokoudAccessToken,
      user: {
        id: fokoudUserId,
        servicesId: servicesUserId,
      },
      userId: fokoudUserId,
    }

    return { servicesSessionData, fokoudSessionData }
  },

  /**
   * Create the session data from the Services SSO Login Response
   *
   * @param {*} servicesAccessToken
   * @param {*} fokoudAccessToken
   * @param {*} servicesUserId
   * @param {*} fokoudUserId
   * @param {*} organizationId
   * @returns {any} data that can be used to create a session
   */
  createFromServicesSSOLoginResponse: (
    servicesAccessToken,
    fokoudAccessToken,
    servicesUserId,
    fokoudUserId,
    organizationId
  ) => {
    // TODO: ensure all arguments are valid and present

    // Construct the services auth data
    const servicesSessionData = {
      accessToken: servicesAccessToken,
      userId: servicesUserId,
      organizationId,
    }

    // Construct the fokoud auth data
    const fokoudSessionData = {
      id: fokoudAccessToken,
      user: {
        id: fokoudUserId,
        servicesId: servicesUserId,
      },
      userId: fokoudUserId,
    }

    return { servicesSessionData, fokoudSessionData }
  },

  /**
   * use the raw session data to export a core
   * set of data that can be used to pass in a query string
   * in order to rebuild the session with those query string params
   *
   * @param {any} sessionData from the Session
   * @returns {URLSearchParams} the query string params that can be used for redirecting
   */
  generateRedirectURLParams: (sessionData) => {
    const params = {
      fokoudAccessToken: sessionData.fokoudSessionData.id,
      fokoudUserId: sessionData.fokoudSessionData.userId,
    }

    if (sessionData.servicesSessionData) {
      params.servicesAccessToken = sessionData.servicesSessionData.accessToken
      params.servicesUserId = sessionData.servicesSessionData.userId
      params.organizationId = sessionData.servicesSessionData.organizationId
    }

    return new URLSearchParams(params)
  },

  /**
   * Create the session data from a core set of params
   * use: `generateRedirectURLParams` to export the params
   * from the raw session data
   *
   * @param {URLSearchParams} urlParams - The params obtained from the current URL
   * @returns
   */
  createFromRedirectURLParams: (urlParams) => {
    // extract all the URL params needed to construct the
    // session data
    const sessionData = {
      fokoudSessionData: {
        id: urlParams.get('fokoudAccessToken'),
        userId: urlParams.get('fokoudUserId'),
        user: {
          id: urlParams.get('fokoudUserId'),
        },
      },
    }

    // if we have services session data as well...
    if (urlParams.has('servicesAccessToken')) {
      sessionData.servicesSessionData = {
        accessToken: urlParams.get('servicesAccessToken'),
        fokoudUserId: urlParams.get('fokoudUserId'),
        organizationId: urlParams.get('organizationId'),
        userId: urlParams.get('servicesUserId'),
      }
    }

    return sessionData
  },
}))()
