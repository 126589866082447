import _ from 'lodash'
import { getCommunityId, Session } from './session'
import { Branding } from '../../../utils/branding'
import { tr } from '../../../i18n'

let communities

const get = () => Session.getCommunities()

const getById = (id) => _.find(get(), { id })
const getCurrent = () => getById(getCommunityId())
const getId = () => getCommunityId()
const getName = () => _.get(getCurrent(), 'name')
const getUniqueName = () => _.get(getCurrent(), 'uniqueName')

const isAdmin = () => {
  const community = getCurrent()
  if (!community) {
    return false
  }
  return (
    _.includes(community.roles, 'admin') ||
    _.includes(community.communityRoles, 'admin')
  )
}
const isSuperAdmin = () =>
  _.some(
    get(),
    (community) =>
      _.includes(community.roles, 'superAdmin') ||
      _.includes(community.communityRoles, 'superAdmin')
  )

const canSendDm = () => _.get(getCurrent(), 'settings.directMessage') !== false
const canCreateGroupDMs = () =>
  _.get(getCurrent(), 'settings.createGroupDMs') !== false
const canCreateTask = () =>
  _.get(getCurrent(), 'settings.taskCreation') !== false

const isMyDocsEnabled = (isFeatureOnly) => {
  const isFeatureEnabled = _.get(getCurrent(), 'featureFlags.folderStorage')
  if (isFeatureOnly) {
    return isFeatureEnabled
  }
  return _.get(getCurrent(), 'settings.myDocs') !== false && isFeatureEnabled
}

const canShareFromMyDocs = () =>
  isMyDocsEnabled() && _.get(getCurrent(), 'settings.myDocsShare') !== false

const isPasswordAgeEnabled = () =>
  _.get(getCurrent(), 'settings.passwordAgeEnabled')
const canChangePassword = () =>
  _.get(getCurrent(), 'settings.passwordReset') !== false
const canUpdateProfile = () =>
  _.get(getCurrent(), 'settings.profileUpdate') !== false

const isTaskApprovalEnabled = () =>
  _.get(getCurrent(), 'featureFlags.taskApproval')

const isTaskStructuresEnabled = () =>
  _.get(getCurrent(), 'featureFlags.assigneeSchemas')

const isDashboardEnabled = () =>
  _.get(getCurrent(), 'featureFlags.dashboard') !== false

const isTasksEnabled = () =>
  _.get(getCurrent(), 'featureFlags.disableTasks') === false

const isNewTasksEnabled = () =>
  _.get(getCurrent(), 'featureFlags.newTask') === true

const isNewTasksDataExportEnabled = () =>
  _.get(getCurrent(), 'featureFlags.newTaskDataExport') === true

const isCommsEnabled = () =>
  _.get(getCurrent(), 'featureFlags.disableComms') === false

const isPollingEnabled = () =>
  _.get(getCurrent(), 'featureFlags.polling') === true

const hasLoadedFeatureFlags = () =>
  _.get(getCurrent(), 'featureFlags') !== undefined

const getBranding = () => _.get(getCurrent(), 'branding')

/**
 * @deprecated only referenced in unused code paths.
 *  see admin-creation-edition-modal.js isTranslatable, emptyI18N
 * @returns {object} a list of language code keys and values
 */
const getLanguages = () => Branding.i18n(getBranding())

const isPendoEnabled = () => getCurrent()?.featureFlags?.pendo

const isAlphaUIenabled = () =>
  _.get(getCurrent(), 'featureFlags.alphaUIenabled') === true

const isRudimentaryUIenabled = () =>
  _.get(getCurrent(), 'featureFlags.rudimentaryUI') === true

const isBroadcastEnabled = () =>
  _.get(getCurrent(), 'featureFlags.broadcast') === true

const isBroadcastGateKeeperEnabled = () =>
  _.get(getCurrent(), 'featureFlags.broadcastGatekeeper') === true

const isGateKeeperSettingEnabled = () =>
  _.get(getCurrent(), 'settings.broadcastGatekeeperEnabled') === true

const isGatekeeperFullyEnabled = () =>
  isBroadcastGateKeeperEnabled() && isGateKeeperSettingEnabled()

const getBroadcastName = () => {
  const broadcastName = getCurrent()?.settings?.broadcastName
  return broadcastName?.toLowerCase() === 'broadcast'
    ? tr('broadcasts.title')
    : broadcastName
}

const isInlineVideoEnabled = () =>
  _.get(getCurrent(), 'featureFlags.inlineVideo') === true

const isThemingEnabled = () =>
  _.get(getCurrent(), 'featureFlags.theming') === true

const isAnnouncementsEnabled = () =>
  _.get(getCurrent(), 'settings.announcementsEnabled') === true ||
  isBroadcastEnabled()

const isTaskCreationRestricted = () =>
  !!getCurrent()?.settings?.taskCreationRestrictionEnabled

export const getRootFolderId = () => _.get(getCurrent(), 'rootFolderId')

const afterUpdateName = (name, uniqueName) => {
  const communities = get()
  const index = _.findIndex(communities, { id: getCommunityId() })
  if (index >= 0) {
    communities[index] = _.merge({}, communities[index], { name, uniqueName })
    Session.setCommunities(communities)
  }
}

const canRenameChannels = () => _.get(getCurrent(), 'settings.channelRenaming')

const canRenameGroupDMs = () =>
  _.get(getCurrent(), 'settings.dmChannelRenaming', true)

const afterUpdateSetting = (setting, value) => {
  const communities = get()
  const index = _.findIndex(communities, { id: getCommunityId() })
  if (index >= 0) {
    const communitySettings = _.get(communities[index], 'settings')
    communitySettings[setting] = value
    Session.setCommunities(communities)
  }
}

const afterUpdateSettings = (newSettings) => {
  const settings = _.head(_.values(newSettings))
  _.forEach(_.keys(settings), (settingName) => {
    afterUpdateSetting(settingName, settings[settingName])
  })
}

const isBranded = () => !_.isEmpty(_.get(getCurrent(), 'branding.loginUrl'))

const isAdvancedTasksActive = () =>
  getCurrent()?.featureFlags?.newTaskAdvanced === true

export const Communities = {
  getRaw: () => communities, // DO NOT use communities stored in session!
  get,
  set: (updatedCommunities) => {
    communities = updatedCommunities
    Session.setCommunities(communities)
  },
}

export const Community = {
  getById,
  getCurrent,
  getId,
  getName,
  getUniqueName,
  canSendDm,
  canCreateGroupDMs,
  canCreateTask,
  isAdmin,
  isSuperAdmin,
  isMyDocsEnabled,
  canShareFromMyDocs,
  isPasswordAgeEnabled,
  canChangePassword,
  canUpdateProfile,
  isAnnouncementsEnabled,
  isTaskApprovalEnabled,
  isTaskStructuresEnabled,
  isDashboardEnabled,
  isTasksEnabled,
  isNewTasksEnabled,
  isCommsEnabled,
  hasLoadedFeatureFlags,
  getBranding,
  getLanguages,
  isPollingEnabled,
  isPendoEnabled,
  isAlphaUIenabled,
  isRudimentaryUIenabled,
  isBroadcastEnabled,
  isBroadcastGateKeeperEnabled,
  isGateKeeperSettingEnabled,
  isGatekeeperFullyEnabled,
  afterUpdateName,
  getBroadcastName,
  isInlineVideoEnabled,
  isThemingEnabled,
  isTaskCreationRestricted,
  canRenameChannels,
  afterUpdateSetting,
  afterUpdateSettings,
  canRenameGroupDMs,
  isBranded,
  isAdvancedTasksActive,
  isNewTasksDataExportEnabled,
}
