/**
 * The regex matching a URI defined by RFC 3986
 * @see https://tools.ietf.org/html/rfc3986#page-50
 */
export const URI_REGEX =
  /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/

/**
 * Make sure that the URI is valid. If not, assume that the link is meant to be HTTP, and prepend it
 * @param {*} uri The modified URI, if it needed to be modified
 */
export const appendHttpIfRequired = (uri) => {
  const uriMatches = uri.match(URI_REGEX)

  // We want to make sure that groups 1 and 3 match, according to
  // https://tools.ietf.org/html/rfc3986#page-50
  return !uriMatches[1] || !uriMatches[3] ? `http://${uri}` : uri
}
