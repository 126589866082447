import compact from 'lodash/compact'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import concat from 'lodash/concat'
import reject from 'lodash/reject'
import find from 'lodash/find'
import moment from 'moment'
import {
  dispatchEvent,
  CHANNELS_UPDATED,
  HAS_NEW_CHANNEL_ACTIVITY_CHANGED,
  ADMIN_OBJECT_UPDATED,
} from '../../../events'
import { Post } from './post'
import { Store } from '../../../utils/store'
import { POST_DRAFTS } from '../../../utils/local-storage'
import { LOCAL_URL, UPLOADING_PROMISE_METHOD } from './file'
import { replaceById } from '../../../utils/replaceById'

let channels
let hasNewActivity
let hasPriorityActivity

const weekAgo = moment().subtract(7, 'days').startOf('day')

const getById = (id) => find(channels, { id })

const isPrivate = (channel) => channel?.type === 'private'

const isArchived = (channel) => channel?.isHidden === true

const isGeneral = (channel) => channel?.usage === 'general'

const isQuiet = (channel) =>
  channel.hasNewActivity !== true &&
  moment(
    channel.postPokedAt || channel.postCreatedAt || channel.createdAt
  ).isBefore(weekAgo)

const setHasNewActivity = (_hasNewActivity, _hasPriorityActivity) => {
  const hadNewActivity = hasNewActivity
  hasNewActivity = _hasNewActivity

  const hadPriorityActivity = hasPriorityActivity
  hasPriorityActivity = _hasPriorityActivity

  if (
    hadNewActivity?.length !== hasNewActivity?.length ||
    hadPriorityActivity?.length !== hasPriorityActivity?.length
  ) {
    dispatchEvent(HAS_NEW_CHANNEL_ACTIVITY_CHANGED)
  }
}

const set = (updatedChannels) => {
  if (!updatedChannels) {
    return
  }
  channels = updatedChannels.slice()
  dispatchEvent(CHANNELS_UPDATED, channels)
  setHasNewActivity(
    compact(
      map(channels, (channel) =>
        channel.hasNewActivity && !channel.hasPriorityActivity
          ? channel.id
          : undefined
      )
    ),
    compact(
      map(channels, (channel) =>
        channel.hasPriorityActivity ? channel.id : undefined
      )
    )
  )
}

const shouldCheckpoint = (nextCheckpoint, checkpoint) =>
  nextCheckpoint &&
  (isNil(checkpoint) || moment(nextCheckpoint).isAfter(checkpoint))

const onCheckpointed = (channelId) => {
  if (!channels) {
    // impossible!
    return
  }
  const channel = getById(channelId)
  if (channel) {
    channel.hasNewActivity = false
    channel.hasPriorityActivity = false
    set(channels)
  }
}

const onUserRemoved = (channelId, updatedChannel) => {
  const channel = getById(channelId)
  if (channel) {
    channel.userCount = updatedChannel.userCount
  }
  set(channels)
}

const handlePostCreated = (post) => {
  const newHasPriorityActivity = Post.isMentioned(post)
  if (Post.isOwner(post)) {
    return
  }
  if (!channels) {
    setHasNewActivity(
      union(
        hasNewActivity || [],
        newHasPriorityActivity ? [] : [post.channelId]
      ),
      union(
        hasPriorityActivity || [],
        newHasPriorityActivity ? [post.channelId] : []
      )
    )
    return
  }
  const channel = getById(post.channelId)
  if (!channel) {
    return
  }
  channel.hasNewActivity = true
  channel.hasPriorityActivity =
    channel.hasPriorityActivity || newHasPriorityActivity
  set(channels)
}

const saveDraft = (channelId, data) => {
  const draft = cloneDeep(data)
  delete draft.channelId
  draft.images = map(draft?.images, (image) =>
    omit(image, [LOCAL_URL, UPLOADING_PROMISE_METHOD])
  )
  draft.documents = map(draft?.documents, (media) =>
    omit(media, [LOCAL_URL, UPLOADING_PROMISE_METHOD])
  )
  Store.add(POST_DRAFTS, draft, channelId)
}

const loadDraft = (channelId) => {
  const drafts = Store.get(POST_DRAFTS) || {}
  const draft = drafts[channelId] || {}
  draft.channelId = channelId
  return draft
}

const afterArchive = (_channel, isRestore = false) => {
  const channel = cloneDeep(_channel)
  channel.isHidden = !isRestore
  dispatchEvent(ADMIN_OBJECT_UPDATED, channel)
}

const afterUpdate = (channel) => {
  set(replaceById(channels, channel))
}

const isFollowing = (channelId) => !isNil(getById(channelId))

export const Channels = {
  getHasNewActivity: () =>
    hasNewActivity?.length > 0 || hasPriorityActivity?.length > 0,
  getPriorityActivityCount: () => hasPriorityActivity?.length,
  setHasNewActivity,
  get: () => channels,
  set,
  add: (channel) => {
    Channels.set(concat(channels, channel))
  },
  remove: (channelId) => {
    Channels.set(reject(channels, { id: channelId }))
  },
  onCheckpointed,
  onUserRemoved,
  handlePostCreated,
  saveDraft,
  loadDraft,
}

export const Channel = {
  getById,
  isPrivate,
  isQuiet,
  isArchived,
  isGeneral,
  shouldCheckpoint,
  afterArchive,
  afterUpdate,
  isFollowing,
}
