import _ from 'lodash'
import { userId } from './session'
import { UserList } from './userlist'

const includesMe = (mention) => {
  const { id, idType } = mention
  if (idType === 'User') {
    if (id === userId) {
      return true
    }
  } else if (idType === 'UserList') {
    if (!_.isNil(UserList.getById(id))) {
      return true
    }
  }
  return false
}

export const Mention = {
  includesMe,
}
