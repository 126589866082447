import _ from 'lodash'
import { fokoud } from '../../../api'
import { getCommunityId, Session } from '../../core/models/session'
import { Completion } from '../models/completion'
import { Task } from '../models/task'
import { DEFAULT_LIMIT } from '../../core/api/constants'

const myCurrentTasks = (status, skip) =>
  fokoud.get(`/communities/${getCommunityId()}/myCurrentTasks`, {
    params: {
      limit: DEFAULT_LIMIT,
      status,
      skip,
    },
  })

const generateActiveProjectsReport = (userRole) => {
  const user = Session.getUser()
  return fokoud.get(`/communities/${getCommunityId()}/reports`, {
    params: { userId: user.id, userRole, projectStatus: 'active' },
  })
}

export const TasksService = {
  create: (task) => fokoud.post('/projects', task),
  update: (task) => fokoud.put(`/projects/${task.id}`, task),
  assigneeSchemas: () =>
    fokoud.get(`/communities/${getCommunityId()}/myAssigneeSchemas`),
  MY_TASKS_LIMIT: DEFAULT_LIMIT,
  assignedTasks: (skip) => myCurrentTasks('assigned', skip),
  reviewingTasks: (skip) => myCurrentTasks('reviewing', skip),
  completedTasks: (skip) => myCurrentTasks('completed', skip),
  archivedTasks: (skip) =>
    fokoud.get(`/communities/${getCommunityId()}/myArchivedTasks`, {
      params: {
        limit: DEFAULT_LIMIT,
        skip,
      },
    }),
  scheduledTasks: (skip) =>
    fokoud.get('/projects/future-reviewing', {
      params: {
        limit: DEFAULT_LIMIT,
        skip,
      },
    }),
  get: (id) => fokoud.get(`/projects/${id}`),
  saveDraftOrSubmit: (task, completion) =>
    fokoud.put(`/tasks/${completion.id}`, completion).then((completion) => {
      Task.afterDraftSaveOrSubmit(task, completion)
      return completion
    }),
  upcomingRecurrences: (frequency, startAt) =>
    fokoud.get('/utils/recurrence/upcoming', {
      params: {
        recurrence: { frequency },
        startAt,
        // utcOffset
      },
    }),
  COMPLETIONS_LIMIT: DEFAULT_LIMIT,
  completions: (
    id,
    status,
    skip,
    includePost = false,
    limit = TasksService.COMPLETIONS_LIMIT
  ) =>
    fokoud.get(`/projects/${id}/tasks`, {
      params: {
        // 'filter[where][status]': status,
        'filter[where][status][inq]': _.isArray(status) ? status : [status],
        'filter[include][post]': includePost ? 'containerItems' : undefined,
        'filter[limit]': limit,
        'filter[skip]': skip,
      },
    }),
  completion: (taskId, id) =>
    fokoud
      .get(`/projects/${taskId}/tasks`, {
        params: {
          'filter[include][post]': 'containerItems',
          'filter[where][id]': id,
        },
      })
      .then((response) => {
        // massage some data so posts are consistent to feed
        response = _.head(response)
        const { resultSteps } = response
        _.forEach(resultSteps, (resultStep) => {
          const post = Completion.resultStepPost(response, resultStep)
          if (post) {
            post.media = resultStep.file ? [resultStep.file] : undefined
            post.owner = response.user
          }
        })
        return response
      }),
  children: (id) => fokoud.get(`/projects/${id}/childProjects`),
  occurrences: (id) => fokoud.get(`/projects/${id}/occurrences`),
  markAsRead: (id) =>
    fokoud.post(`/projects/${id}/mark`, {
      type: 'read',
    }),
  approveItem: (type, completion, resultStep) =>
    fokoud.post(`/tasks/${completion.id}/resultSteps/${resultStep.id}/mark`, {
      type,
    }),
  finishReview: (task, completion, type) =>
    fokoud
      .post(`/tasks/${completion.id}/mark`, {
        type: type === Completion.COMPLETED ? 'done' : type, // stupid fokoud
      })
      .then((response) => {
        Task.afterReview(task, completion, type)
        return response
      }),
  close: (task) =>
    fokoud.put(`/projects/${task.id}`, { status: 'closed' }).then((task) => {
      task.status = 'closed'
      Task.afterClose(task)
      return task
    }),
  terminate: (task) =>
    fokoud
      .post(`/projects/${task.id}/terminate`, {
        endScheduler: true,
        endAllProjects: true,
      })
      .then((task) => {
        task.status = 'closed'
        Task.afterClose(task)
        return task
      }),
  remind: (taskId, content, role) =>
    fokoud.post(`/projects/${taskId}/remind`, { content, role }),
  newDraft: (task) =>
    fokoud.post(`/projects/${task.id}/tasks`).then((draft) => {
      Task.afterDraftCreated(task, draft)
      return draft
    }),
  deleteCompletion: (task, completion) =>
    fokoud.delete(`/tasks/${completion.id}`).then((response) => {
      Task.afterCompletionDeleted(task, completion)
      return response
    }),
  templates: () => fokoud.get(`/communities/${getCommunityId()}/myProjects`),
  generateReport: (task, asPdf) =>
    fokoud.post(`/projects/${task.id}/reports${asPdf ? '/pdf' : ''}`),
  share: (task) =>
    fokoud.post(`/projects/${task.id}/shares`, {
      shareableId: task.id,
      shareableType: 'projects',
      types: ['link'],
    }),
  stepStats: (task) => fokoud.get(`/projects/${task.id}/stepStats`),
  STEP_RESULTS_LIMIT: DEFAULT_LIMIT,
  stepResults: (task, stepId, skipped, limit, skip) =>
    fokoud.get(`/projects/${task.id}/results/${stepId}`, {
      params: {
        skipped,
        limit,
        skip,
      },
    }),
  SURVEY_RESULTS_LIMIT: DEFAULT_LIMIT,
  surveyOptionResults: (task, stepId, optionId, limit, skip) =>
    fokoud.get(`/projects/${task.id}/surveyStats/${stepId}`, {
      params: {
        optionId,
        limit,
        skip,
      },
    }),

  calendar: (startDate, endDate, role) =>
    fokoud.get('/calendarEvents', {
      params: {
        communityId: getCommunityId(),
        startDate,
        endDate,
        role,
      },
    }),

  manuallySyncCalendarEvents: (startDate, endDate) =>
    fokoud.post(
      '/calendarEvents/manual-sync',
      { communityId: getCommunityId() },
      { params: { startDate, endDate } }
    ),

  getAssignedTaskInfo: (projectIds) =>
    fokoud.post('/tasks/assigned', { projectIds }),

  getReviewingTasksInfo: (projectIds) =>
    fokoud.post('/tasks/reviewing', { projectIds }),

  generateAllActiveProjectsReport: () =>
    Promise.all([
      generateActiveProjectsReport('creator'),
      generateActiveProjectsReport('manager'),
      generateActiveProjectsReport('observer'),
    ]),
}
