import _ from 'lodash'
import { dispatchEvent, USER_USERLISTS_CHANGED } from '../../../events'
import { UserService } from '../api/user'

let userLists

export const UserLists = {
  get: () => userLists,
  set: (updatedUserLists) => {
    userLists = updatedUserLists
    dispatchEvent(USER_USERLISTS_CHANGED)
  },
}

export const UserList = {
  getById: (id) => {
    if (!userLists) {
      // trigger user list as needed
      UserService.userLists()
    }
    return _.find(userLists, { id })
  },
}
