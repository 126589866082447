// list of local storage keys
export const SESSION = 'session'
export const WFS_SESSION = 'wfsSession'
export const COMMUNITY_ID = 'communityId'
export const UNAUTHORIZED = 'unauthorized'
export const INSTALLATION_ID = 'installationId'
export const SERVICES_AUTH = 'servicesAuth'
export const BASE_API_URL = 'baseApiUrl'
export const WFS_MESSAGE = 'wfsMessage'
export const WFX_RELOAD = 'wfxReload'

export const I18N = 'i18n'

export const ORGANIZATION_UNIQUE_NAME = 'organizationUniqueName'

// ui stuff
export const SELECTED_MESSAGE = 'selectedMessage'
export const IS_CHANNEL_BAR_MAXIMIZED = 'isChannelBarMaximized'
export const IS_NAVIGATION_MENU_MINIMIZED = 'isNavigationMenuMinimized'
export const MY_DOCS_IS_GRID_LAYOUT = 'isGridLayout'
export const CALENDAR_IS_WEEK_VIEW = 'isWeekView'
export const CURRENT_CHANNEL_SLUG = 'channelSlug'
export const IS_RICH_TEXT_ENABLED = 'isRichTextEnabled'
export const PREPOPULATE_MESSAGE_ID = 'prepopulateMessageId'
export const POST_DRAFTS = 'postDrafts'

export const getLocalStorageItem = (key, defaultValue) => {
  const parsedItem = JSON.parse(localStorage.getItem(key))
  return parsedItem?.value || defaultValue
}
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify({ value }))
export const removeLocalStorageItem = (key) => localStorage.removeItem(key)
