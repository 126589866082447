import _ from 'lodash'
import { useState, useEffect } from 'react'
import {
  SESSION,
  SELECTED_MESSAGE,
  UNAUTHORIZED,
  COMMUNITY_ID,
  setLocalStorageItem,
  SERVICES_AUTH,
  getLocalStorageItem,
  I18N,
  ORGANIZATION_UNIQUE_NAME,
  BASE_API_URL,
  WFS_MESSAGE,
  WFX_RELOAD,
  removeLocalStorageItem,
} from '../../../utils/local-storage'
import {
  dispatchEvent,
  CURRENT_USER_UPDATED,
  I18N_CHANGE_END,
  COMMUNITY_ID_UPDATED,
  addEventListener,
} from '../../../events'
import { Community } from './community'
import { Branding } from '../../../utils/branding'
import { getLanguage } from '../../../i18n'
import { ENGLISH_LANG_CODE } from '../i18n/constants'

/**
 * Gets the Session Data that include the FOKOUD Auth Info
 *
 * @return {any} object instance of Session data
 */
const get = () => JSON.parse(localStorage.getItem(SESSION)) || {}

const session = get()
export const authToken = session.id
export const { userId } = session

/**
 * Get Fokoud Auth token from local storage (from Fokoud session data)
 * @returns Fokoud Auth token from local storage
 */
export const getAuthToken = () => get()?.id

/**
 * Gets the Services-specific Session Data that includes auth info
 *
 * @return {any} object instance of the Services Session data
 */
const getServicesSessionData = () => getLocalStorageItem(SERVICES_AUTH, {})

/**
 * Get both the Fokoud and Services session data
 *
 * @return {any} object instance of combined session data
 */
const getSessionData = () => {
  const sessionData = {
    fokoudSessionData: get(),
  }
  sessionData.servicesSessionData = getServicesSessionData()

  return sessionData
}

/**
 * Try to find the community object from the users session that has the ID
 * that is stored in localStorage.getItem(COMMUNITY_ID)
 *
 * @return {any} object instance of the found Community object
 */
const findCommunityFromLocalStorageId = () =>
  _.find(_.get(session, 'user.communities'), {
    id: localStorage.getItem(COMMUNITY_ID),
  })

/**
 * Get the ID of the first community in the users session
 *
 * @return {string} ID of the found community
 */
const getFirstCommunityId = () => {
  const communities = _.get(session, 'user.communities')
  return _.get(_.head(communities), 'id')
}

let communityId =
  _.get(findCommunityFromLocalStorageId(), 'id') || getFirstCommunityId()

/**
 * This is the main way the Application gets the selected community
 * ID of the session.
 *
 * TODO: we should probably not export this function directly and
 * instead export is as a part of the session below
 *
 * @return {string} ID of the community this session is using
 */
export const getCommunityId = () => communityId

/**
 * Set the ID of the specified communityId for the current session
 *
 * @param {string} ID of the communtiy to use for the current session
 */
const setCommunityId = (_communityId) => {
  communityId = _communityId || getFirstCommunityId()
  localStorage.setItem(COMMUNITY_ID, communityId)
  // any else...
  localStorage.removeItem(SELECTED_MESSAGE)
  dispatchEvent(COMMUNITY_ID_UPDATED, communityId)
}

/**
 * Create a new session with the specified session data
 *
 * @param {any} the session data that was obtained from the SessionDataFactory
 */
const create = (sessionData) => {
  localStorage.setItem(SESSION, JSON.stringify(sessionData.fokoudSessionData))

  if (sessionData.servicesSessionData) {
    localStorage.setItem(
      SERVICES_AUTH,
      JSON.stringify({ value: sessionData.servicesSessionData })
    )
  }

  setCommunityId()
}

/**
 * Determines if the user of the current session has authenticated
 *
 * @return {boolean} Whether or not the user is authenticated
 */
const isAuthenticated = () => !_.isNil(authToken)

/**
 * Determines if the user of the current session was "Logged Out" by the backend
 *
 * NOTE: Once you call this function, the value is cleared from localStorage
 *
 * @return {boolean} Whether or not the session was forced to logout
 */
const wasForceLoggedOut = () => {
  const toReturn = localStorage.getItem(UNAUTHORIZED)
  // NOTE: this kind of behaviour to clear the data inside of an accessor function
  // is probably not the right way we want to handle this
  localStorage.removeItem(UNAUTHORIZED) // one time access!
  return toReturn
}

/**
 * Helper function to erase the data in the local storage after a user logs out,
 * excepting keys relating to alpha UI, organization name, and i18n preference
 */
const clearLocalStorage = () => {
  // maintain alphaUI, organizationUniqueName
  const preserve = {}
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (
      _.includes(key, 'alpha-') ||
      _.includes(key, ORGANIZATION_UNIQUE_NAME) ||
      _.includes(key, I18N) ||
      _.includes(key, WFX_RELOAD)
    ) {
      preserve[key] = localStorage.getItem(key)
    }
  }
  localStorage.clear()
  _.forEach(_.keys(preserve), (key) => {
    localStorage.setItem(key, preserve[key])
  })
}

let isLoggingOut = false

/**
 * To be called when a user logs out.
 * Performs all the Session cleanup and reset
 *
 * @param {boolean} whether or not the user was forced to logout.  If the param is
 * not specified we will assume the value is false.
 */
const onLogout = (isForceLogout = false) => {
  if (isLoggingOut) {
    // prevent multiple cases of this as can prevent redirect to proper branded url
    return
  }
  isLoggingOut = true
  const logoutRedirectUrl = Branding.logoutRedirectUrl()
  clearLocalStorage()
  if (isForceLogout && logoutRedirectUrl === '/') {
    localStorage.setItem(UNAUTHORIZED, true)
  }

  // If a user logs out of the Suite, the WFX session gets destroyed in the backend but
  // its session value in the local storage is still present. Therefore when a user accesses
  // WFX, it tries to use the (invalid) session from local storage first which triggers this
  // onLogout call because it intercepts 401 error from using the invalid session data.
  // As a result, the handshake is never called. By doing a reload after the WFX cache is cleared,
  // it allows the handshake to happen and we get the valid session.
  if (localStorage.getItem(WFX_RELOAD) === 'null' && window.parent !== window) {
    localStorage.setItem(WFX_RELOAD, 'true')
    window.location.reload()
  } else {
    localStorage.setItem(WFX_RELOAD, null)
    window.location.href = logoutRedirectUrl
  }
}

/**
 * Get the communities of the user in the current session
 *
 * NOTE: If this value is array is empty, the user is force logged out
 *
 * @returns {Array} An Array of the community objects
 */
const getCommunities = () => {
  const communities = _.get(session.user, 'communities')
  if (authToken && !_.isNil(communities) && _.isEmpty(communities)) {
    onLogout(true) // NOTE: this is probably not the right place to trigger this
  }
  return communities
}

/**
 * Set the communities of the users session to the specified param
 *
 * @param {Array} communities
 */
const setCommunities = (communities) => {
  if (_.get(session, 'user')) {
    session.user.communities = communities
    localStorage.setItem(SESSION, JSON.stringify(session))
  }
}

/**
 * Set the websocketURL for the current session
 *
 * @param {string} websocketUrl
 */
const setWebsocketUrl = (websocketUrl) => {
  session.websocketUrl = websocketUrl
  localStorage.setItem(SESSION, JSON.stringify(session))
}

/**
 * Set the base URL for the current session used for multi region purposes
 * @param {string} baseApiUrl The base URL for the current user/region
 */
const setBaseApiUrl = (baseApiUrl) => {
  setLocalStorageItem(BASE_API_URL, baseApiUrl)
}

/**
 * Returns the base URL of the current/user region to be used on the API requests around the app
 * @return {string} The base URL for the current user/region
 */
const getBaseApiUrl = () => getLocalStorageItem(BASE_API_URL)

/**
 * Update the user data in the current session to the specifed user
 *
 * @param {any} user
 */
const updateUser = (user) => {
  // logic to update local storage
  // maintain existing communities (e.g. bugs due to feature flags not returned)
  const { communities, preferences, id } = session.user
  session.user = user
  session.user.id = id
  if (session.user.communities) {
    session.user.communities = _.unionBy(communities, user.communities, 'id')
  }
  if (!session.user.preferences) {
    session.user.preferences = preferences
  }
  localStorage.setItem(SESSION, JSON.stringify(session))
  dispatchEvent(CURRENT_USER_UPDATED, user)
}

/**
 * Checks the current session to see if the password for the current user has expired
 *
 * @returns {boolean} whether or not the password has expired
 */
const isPasswordExpired = () => {
  const currentCommunity = Community.getCurrent()
  const hasPasswordExpired = Community.isPasswordAgeEnabled()
  if (hasPasswordExpired) {
    const passwordMaxAge = _.get(
      currentCommunity,
      'settings.maxPasswordAgeDays'
    )

    const passwordLastSetAt = new Date(
      _.get(getLocalStorageItem(SERVICES_AUTH), 'passwordLastSetAt')
    )

    const daysFromLastPasswordChange =
      (new Date().getTime() - passwordLastSetAt.getTime()) / (1000 * 3600 * 24)
    return daysFromLastPasswordChange >= passwordMaxAge
  }
}

/**
 * Get the ID of the User in Foko Services
 *
 * @returns {string} The UUID value of the User's ID in Foko Services
 */
const getServicesUserId = () => {
  const servicesId = session.user?.servicesId
  return servicesId || _.get(getLocalStorageItem(SERVICES_AUTH), 'userId')
}

/**
 * Get the Organization ID of the Current User's session
 * @returns {string} The UUID value of the User's Organization ID
 */
const getOrganizationId = () => {
  const community = Community.getCurrent()
  if (community) {
    return _.get(community, 'servicesId')
  }
  return _.get(getLocalStorageItem(SERVICES_AUTH), 'organizationId')
}

/**
 * Called after the user's password was updated
 */
const onPasswordUpdated = () => {
  const servicesAuth = _.merge(getLocalStorageItem(SERVICES_AUTH), {
    passwordLastSetAt: new Date().toISOString(),
  })
  setLocalStorageItem(SERVICES_AUTH, servicesAuth)
}

/**
 * Set the language for the current session
 *
 * @param {string} language desired
 */
const setLanguage = (language) => {
  document.documentElement.lang = language
  if (language === getLanguage()) {
    return
  }

  // do not add an undefined language to local storage
  setLocalStorageItem(I18N, language || ENGLISH_LANG_CODE)
  dispatchEvent(I18N_CHANGE_END)
}

/**
 * Checks if the current users is logged in with a shared account.
 * If that is the case, there are some restrictions: Can't change password
 *
 * @returns {boolean} whether or not the user is a "shared user"
 */
const isSharedUser = () => _.get(session, 'isSharedUser') === true

const getUserNotifications = () => get()?.user?.preferences?.notification

/**
 * It sets the wfsMessage key in the local storage with the selected message object
 * @param {object} selectedMessage
 */
const setSelectedWfsMessage = (selectedMessage) =>
  setLocalStorageItem(WFS_MESSAGE, JSON.stringify(selectedMessage))

/**
 * Retrieve the wfs message object from local storage
 * @returns {object} parsed wfs message item from local storage
 */
const getSelectedWfsMessage = () => JSON.parse(getLocalStorageItem(WFS_MESSAGE))

/**
 * Checks if wfsMessage key exists in local storage
 * @returns {boolean} true if wfsMessage key is present in local storage
 */
const hasSelectedWfsMessage = () => !!getLocalStorageItem(WFS_MESSAGE)

/**
 * Removes wfsMessage key from local storage
 */
const removeSelectedWfsMessageFromStorage = () =>
  removeLocalStorageItem(WFS_MESSAGE)

const useCommunityIdState = () => {
  const [updatedCommunityId, setUpdatedCommunityId] = useState(getCommunityId())
  useEffect(
    () =>
      addEventListener(COMMUNITY_ID_UPDATED, () => {
        setUpdatedCommunityId(getCommunityId())
      }),
    []
  )
  return updatedCommunityId
}

const isWfsUser = () => !!session?.user?.wfsId

export const Session = {
  create,
  isAuthenticated,
  get,
  getServicesSessionData,
  getSessionData,
  setCommunityId,
  onLogout,
  wasForceLoggedOut,
  getUser: () => get().user,
  getServicesUserId,
  getCommunities,
  setCommunities,
  updateUser,
  isPasswordExpired,
  getOrganizationId,
  getWebsocketUrl: () => session.websocketUrl,
  setWebsocketUrl,
  onPasswordUpdated,
  setLanguage,
  isSharedUser,
  setBaseApiUrl,
  getBaseApiUrl,
  getUserNotifications,
  getCommunityId,
  getAuthToken,
  setSelectedWfsMessage,
  getSelectedWfsMessage,
  removeSelectedWfsMessageFromStorage,
  hasSelectedWfsMessage,
  useCommunityIdState,
  isWfsUser,
}
