import _ from 'lodash'
import { fokoud } from '../../../api'
import { getCommunityId } from '../models/session'
import { Channels, Channel } from '../models/channel'
import { DEFAULT_LIMIT } from './constants'

let channelPromise
export const ChannelsService = {
  get: (force = false) => {
    const channels = Channels.get()
    if (channels && !force) {
      return Promise.resolve(channels)
    }
    if (!channelPromise) {
      channelPromise = fokoud
        .get('/users/channels', {
          params: {
            filter: {
              where: {
                communityId: getCommunityId(),
              },
            },
          },
        })
        .then((response) => {
          Channels.set(response)
          return response
        })
        .finally(() => {
          channelPromise = undefined
        })
    }
    return channelPromise
  },
  search: (query) =>
    fokoud.get(`/communities/${getCommunityId()}/channels`, {
      params: {
        'filter[q]': query,
      },
    }),
  isFetchingOrHasBeenFetched: () =>
    !_.isNil(Channels.get()) || !_.isNil(channelPromise),
}

let slugPromise
export const ChannelService = {
  // right now we load the channels to map a slug to id
  // ideally, backend would have a feed call for slug
  // that returns the feed with the channel data
  getFromSlug: (slug) =>
    ChannelsService.get().then((channels) => {
      const channel = _.find(channels, { slug })
      if (channel) {
        return channel
      }
      // this is a channel we don't belong to!
      if (!slugPromise || slugPromise.slug !== slug) {
        slugPromise = fokoud
          .get(`/communities/${getCommunityId()}/channels`, {
            params: {
              'filter[where][slug]': slug,
            },
          })
          .then((response) => _.head(response))
        slugPromise.slug = slug
      }
      return slugPromise
    }),
  get: (channelId) => fokoud.get(`/channels/${channelId}`),
  getChannelDeeplink: (channelId) =>
    fokoud.get(`/channels/${channelId}/deeplink`),
  FEED_LIMIT: DEFAULT_LIMIT,
  feed: (slug, olderThan) =>
    Promise.resolve().then(() =>
      ChannelService.getFromSlug(slug).then((channel) => {
        const channelId = _.get(channel, 'id')
        return fokoud.get(`/channels/${channelId}/feed`, {
          params: {
            'filter[limit]': ChannelService.FEED_LIMIT,
            'filter[olderThan]': olderThan,
          },
        })
      })
    ),
  follow: (channelId) =>
    fokoud.post(`/channels/${channelId}/follow`).then((response) => {
      const channel = response
      Channels.add(channel)
      return channel
    }),
  unfollow: (channelId) =>
    fokoud.post(`/channels/${channelId}/unfollow`).then((response) => {
      Channels.remove(channelId)
      return response
    }),
  invitations: (channelId, data) =>
    fokoud.post(`/channels/${channelId}/invitations`, data),
  checkpoint: (channelId, timestamp) =>
    fokoud
      .post(`/channels/${channelId}/checkpoint`, { timestamp })
      .then((response) => {
        Channels.onCheckpointed(channelId)
        return response
      }),
  followers: (channelId, skip) =>
    fokoud.get(`/channels/${channelId}/followers`, {
      params: {
        // 'query': '',
        'filter[limit]': DEFAULT_LIMIT,
        'filter[skip]': skip,
        'filter[order]': 'name ASC',
      },
    }),
  removeUser: (channelId, userId) =>
    fokoud
      .delete(`/channels/${channelId}/followers/${userId}`)
      .then((response) => {
        Channels.onUserRemoved(channelId, response)
        return response
      }),
  edit: (channel) =>
    fokoud
      .put(`/channels/${channel.id}`, channel)
      .then((res) => {
        Channel.afterUpdate(res)
        return res
      })
      .catch((e) => {
        if (
          _.includes(
            _.get(e, 'response.data.error.message'),
            'channel description is too long'
          )
        ) {
          return Promise.reject(ChannelService.ERRORS.DESCRIPTION_TOO_LONG)
        }
        return Promise.reject(e)
      }),

  ERRORS: {
    DESCRIPTION_TOO_LONG: 'DESCRIPTION_TOO_LONG',
  },
}
