import _ from 'lodash'
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL } from '../constants'
import { Routes } from '../routes'
import { Session } from '../modules/core/models/session'
import { appendHttpIfRequired } from './uri'
import { Config } from '../config'
import { SessionDataFactory } from './session-data-factory'

const BRANDED_ORIGIN = 'brandedOrigin'

export const Branding = {
  isBrandedApp: () => {
    if (new URLSearchParams(window.location.search).has('uniqueName')) {
      return true
    }
    return Config.isBranded()
  },
  getBrandingUniqueName: () => {
    if (new URLSearchParams(window.location.search).has('uniqueName')) {
      return new URLSearchParams(window.location.search).get('uniqueName')
    }
    return Config.brandedUniqueName()
    // return Config.appId()
  },
  getBrandedOrigin: () => localStorage.getItem(BRANDED_ORIGIN),
  handleBrandedOrigin: () => {
    // here is some fun stuff for if we are redirected from customdomain b/c we are authed there
    // if we are not authed here (app.foko.co), then just go logout on customdomain
    if (!_.includes(window.location.search, BRANDED_ORIGIN)) {
      return false
    }

    const urlParams = new URLSearchParams(window.location.search)
    const brandedOrigin = urlParams.get(BRANDED_ORIGIN)
    localStorage.setItem(BRANDED_ORIGIN, brandedOrigin)

    // Build the session based of the data from the URL
    const sessionData =
      SessionDataFactory.createFromRedirectURLParams(urlParams)
    Session.create(sessionData)

    // redirect to cleanup the query string
    window.location.href = window.location.pathname
    return true
  },

  // redirect to the new host path and send the session data in the URL
  navigateToMainApp: () => {
    const sessionData = Session.getSessionData()
    const urlParams = SessionDataFactory.generateRedirectURLParams(sessionData)
    window.location.href = `${Config.authenticatedHost()}?${urlParams.toString()}&${BRANDED_ORIGIN}=${encodeURIComponent(
      window.location.origin
    )}`
  },
  theme: (branding, theme) => _.get(branding, `themes.${theme}`),
  i18n: (branding) => _.get(branding, 'themes.i18n'),
  isTermsEnforced: (branding) => _.get(branding, 'isTermsEnforced'),
  communityName: (branding) =>
    _.get(branding, 'brandingName') || _.get(branding, 'communityName'),
  logoutRedirectUrl: () => {
    const brandedOrigin = localStorage.getItem(BRANDED_ORIGIN)
    if (brandedOrigin) {
      return `${localStorage.getItem(BRANDED_ORIGIN)}${Routes.LOGOUT}`
    }
    return '/'
  },
  logoUrl: (branding) => _.get(branding, 'logoUrl'),
  termsUrl: (branding) =>
    appendHttpIfRequired(
      _.get(branding, 'termsUrl') || TERMS_AND_CONDITIONS_URL
    ),
  privacyUrl: (branding) =>
    appendHttpIfRequired(_.get(branding, 'privacyUrl') || PRIVACY_POLICY_URL),
  uniqueName: (branding) => _.get(branding, 'orgUniqueName'),
}
