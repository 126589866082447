import _ from 'lodash'
import { getCommunityId } from '../../models/session'
import { Messages } from '../../models/message'
import { Channels } from '../../models/channel'
import { Inbox } from '../../../inbox/models/inbox'

const processStartWS = (data) => {
  const community = _.find(_.get(data, 'communities'), { id: getCommunityId() })

  const channels = _.get(community, 'channels')
  Channels.set(channels)

  const messages = _.get(community, 'dms')
  Messages.set(messages)

  Inbox.setHasUnreadInboxItems(_.get(community, 'hasUnreadInboxItems'))
}

export default processStartWS
