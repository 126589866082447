import _ from 'lodash'
import { FileService } from '../api/file'
import { Community } from './community'

export const LOCAL_URL = '_url'
export const UPLOADING_PROMISE_METHOD = 'uploadingPromise'
// meta post data attached to a file (i.e wysiswyg/post creation)
export const META_DATA = '_meta'
export const META_NAME = '_name'

const isImage = (file) => _.startsWith(file.type, 'image/')

const isExtensionAllowed = (fileTypeRestriction, extension) => {
  if (!fileTypeRestriction) {
    return true
  }
  if (_.isEmpty(_.get(fileTypeRestriction, 'extensions'))) {
    // dumb fokoud
    return true
  }
  const extensions = new RegExp(fileTypeRestriction.extensions.join('|'), 'i')
  const isExtensionMatch = extensions.test(`.${extension}`)
  return fileTypeRestriction.strategy === 'blacklist'
    ? !isExtensionMatch
    : isExtensionMatch
}

const canUpload = (file, fileTypeRestriction) => {
  const communitySettings = _.get(Community.getCurrent(), 'settings')
  if (file.size > _.get(communitySettings, 'maxFileSize')) {
    return false
  }

  const extension = _.last(_.split(file.name, '.'))
  const fileTypeRestrictionCommunityLevel = _.get(
    communitySettings,
    'fileTypeRestriction'
  )

  return (
    isExtensionAllowed(fileTypeRestrictionCommunityLevel, extension) &&
    isExtensionAllowed(fileTypeRestriction, extension)
  )
}

// file is a file object
// blob is what we actually want to upload (will only really be set for image markup!)
const upload = (file, onFileAdded, onFileProgress, blob) => {
  if (!blob) {
    blob = file
  }

  // cancel previous upload call if needed
  _.result(file.uploadingPromise, 'cancel')

  delete file.id
  delete file.error
  file.progress = 0
  const fileReader = new FileReader()
  fileReader.onload = () => {
    if (isImage(blob)) {
      file[LOCAL_URL] = fileReader.result
    }
    if (onFileAdded) {
      onFileAdded(file)
    }
  }
  fileReader.readAsDataURL(blob)

  const usage = file.usage || 'post'

  file.uploadingPromise = FileService.upload(blob, usage, (e) => {
    file.progress = (e.loaded / e.total) * 100
    if (onFileProgress) {
      onFileProgress(file)
    }
  })
    .then((uploadedFile) => {
      _.merge(file, uploadedFile)
      if (onFileProgress) {
        onFileProgress(file)
      }

      return file
    })
    .catch((e) => {
      file.error = e
      if (onFileProgress) {
        onFileProgress(file)
      }
    })

  return file.uploadingPromise
}

export const File = {
  upload,
  isImage,
  canUpload,
}
